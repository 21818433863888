import React, { FC, useEffect } from 'react';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import DictionaryItemList from './DictionaryItemList';
import Linker from '../../../components/Linker';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { createDictionaryItemLink, createLink } from '../../../components/Linker/PathCreators';
import { useAppDispatch } from '../../../store';
import { create, get, update } from '../../../store/Crud';
import { getCrudItem } from '../../../store/Crud/selectors';
import { IDictionary } from '../../../store/Crud/types/dictionary';
import { IDictionaryItem } from '../../../store/Crud/types/dictionaryItem';

const { TabPane } = Tabs;

interface IDictionaryEdit {
    itemId: string;
    shopId: number;
    actionAfterSubmit: (arg: IDictionary) => void;
}

const DictionaryEdit: FC<IDictionaryEdit> = ({ itemId, shopId, actionAfterSubmit }) => {
    const dispatch = useAppDispatch();
    const dictionary = useSelector(getCrudItem(resource.DICTIONARY));
    const history = useHistory();

    const fields = {
        title: '',
        description: '',
        alias: '',
        fields: [],
        type: '',
        externalId: '',
    };

    const requiredFields = ['title', 'alias', 'fields'];

    const onSave = async (item: IDictionary) => {
        if (item.id) {
            await dispatch(update(resource.DICTIONARY, String(item.id), item)());
        } else {
            const { payload } = await dispatch(create(resource.DICTIONARY, item)());
            if (payload?.data?.id) {
                actionAfterSubmit(payload?.data);
            }
        }
    };

    useEffect(() => {
        if (itemId === 'new' ? false : itemId !== String(dictionary?.id)) {
            dispatch(get(resource.DICTIONARY, itemId, { shopId })());
        }
    }, [dictionary?.id, dispatch, itemId, shopId]);

    const goToItem = (item: IDictionaryItem) => {
        history.push(createDictionaryItemLink(String(shopId), String(item.id), String(item.dictionaryId)));
    };

    const onChangeTab = (tab: any) => {
        history.push(createLink(tab, String(shopId), dictionary.id));
    };

    const item = dictionary && String(dictionary.id) === itemId ? dictionary : {};
    if (shopId === 0 || (itemId === 'new' ? false : itemId !== String(item?.id))) {
        return <Loading />;
    }

    return (
        <Tabs
            onChange={onChangeTab}
            activeKey={history.location?.pathname.indexOf('dictionary-item') > 0 ? 'dictionaryItemList' : 'dictionary'}
        >
            <TabPane tab="Информация о справочнике" key="dictionary">
                <ItemEdit
                    item={item}
                    fields={fields}
                    subjectName="dictionary"
                    onSave={onSave}
                    additionalFields={{ shopId }}
                    requiredFields={requiredFields}
                />
            </TabPane>
            {_.isEmpty(item) ? null : (
                <TabPane tab="Значения" key="dictionaryItemList">
                    <Linker type={enumTypesLinks.dictionaryItem} itemId="new" parentId={item.id}>
                        Добавить
                    </Linker>
                    <DictionaryItemList dictionaryId={Number(item.id)} onClick={goToItem} />
                </TabPane>
            )}
        </Tabs>
    );
};

export default DictionaryEdit;
