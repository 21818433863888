export enum SHOP_ADDRESS_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    pickupPoint = 'pickupPoint',
    shopId = 'shopId',
    externalId = 'externalId',
    lat = 'lat',
    lon = 'lon',
}

export interface IShopAddress {
    [SHOP_ADDRESS_FIELDS.id]: number;
    [SHOP_ADDRESS_FIELDS.title]: string;
    [SHOP_ADDRESS_FIELDS.description]: string;
    [SHOP_ADDRESS_FIELDS.pickupPoint]: boolean;
    [SHOP_ADDRESS_FIELDS.shopId]: number;
    [SHOP_ADDRESS_FIELDS.lat]: number;
    [SHOP_ADDRESS_FIELDS.lon]: number;
    [SHOP_ADDRESS_FIELDS.externalId]: string;
}
