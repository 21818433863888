import React from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { ImportedPropertyContainer } from './ImportedPropertyContainer';

const GoodShopPropertyValueMapList = (props) => {
    const goodProperties = useSelector(getCrudItems(resource.MODEL_PROPERTY));

    const columns = [
        {
            title: t('modelValue'),
            dataIndex: 'id',
            width: '20%',
            render: (id, record) => (
                <Tooltip title={`#${record.id}`}>
                    <Tag>{record.value}</Tag>
                </Tooltip>
            ),
        },
        {
            title: t('importedValues'),
            width: '80%',
            render: (_, record) => (
                <ImportedPropertyContainer
                    values={props.value}
                    internalId={record.id}
                    allValues={props.goodShopProperty.importedValues}
                    onCreate={onCreate}
                    onDelete={onDelete}
                />
            ),
        },
    ];

    const onCreate = (newMapping) => {
        triggerChange([...props.value, newMapping]);
    };

    const onDelete = (mapping) => {
        triggerChange(props.value.filter((elem) => !_.eq(elem, mapping)));
    };

    const triggerChange = (values) => {
        props.onChange(values);
    };

    const { goodShopProperty } = props;
    const currentGoodProperty = goodProperties.find((elem) => elem.id === goodShopProperty.modelPropertyId);
    const items = currentGoodProperty ? currentGoodProperty.values : [];
    const value = props.value ? props.value : [];

    if (items.length === 0) {
        return <div>Нет значений у модели</div>;
    }

    const allInternalIds = items.map((elem) => elem.id);
    const missedMapping = value.filter((elem) => !allInternalIds.includes(elem.internalId));
    return (
        <div>
            <Table size="small" pagination={false} rowKey="id" dataSource={items} columns={columns} />
            {missedMapping.length === 0 ? null : <div>Не найдены свойства модели для следующих связок:</div>}
            {missedMapping.map((elem, index) => (
                <Tooltip key={elem.internalId + elem.value + index} title={`${elem.internalId}#${elem.value}`}>
                    <Tag color="red" closable onClose={() => onDelete(elem)}>
                        {goodShopProperty.importedValues.find((dicElem) => dicElem.id === elem.value).value}
                    </Tag>
                </Tooltip>
            ))}
        </div>
    );
};

export default GoodShopPropertyValueMapList;
