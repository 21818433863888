import React, { FC, useRef } from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { baseStaticUrl } from '../../utils/http';
import Loading from '../Loading';
import { getIsWaitFileUpload } from '../../store/File/selectors';

interface IImgEdit {
    uploadImage: (arg: any) => void;
    imagePath: string;
    isFullURL?: boolean;
}

const ImgEdit: FC<IImgEdit> = ({ uploadImage, imagePath, isFullURL = false }) => {
    const inputOpenFileRef = useRef<any>();
    const isWaitFileUpload = useSelector(getIsWaitFileUpload);

    const onFileUpload = (event: React.FormEvent<HTMLInputElement>) => {
        const { files } = event.target as HTMLInputElement;
        if (files && files[0] !== undefined) {
            uploadImage(files[0]);
        }
    };

    const showOpenFileDlg = () => {
        // @ts-ignore
        inputOpenFileRef?.current?.click();
    };

    if (isWaitFileUpload) {
        return <Loading />;
    }

    const smallImagePath = isFullURL ? imagePath : `${baseStaticUrl}100x66/${imagePath}`;

    return (
        <div style={{ display: 'inline-block' }}>
            <input
                ref={inputOpenFileRef}
                onClick={(e) => e.stopPropagation()}
                type="file"
                onChange={onFileUpload}
                style={{ display: 'none' }}
            />
            {imagePath ? (
                <button
                    type="button"
                    style={{ cursor: 'pointer', padding: '0', border: 'none' }}
                    onClick={showOpenFileDlg}
                >
                    <img alt={imagePath} src={smallImagePath} />
                </button>
            ) : (
                <Button onClick={showOpenFileDlg} icon={<PictureOutlined />} />
            )}
        </div>
    );
};

export default ImgEdit;
