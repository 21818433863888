import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import AppLayout from '../../../components/AppLayout';
import AdminMenu from '../../../components/Menu/AdminMenu';
import ControlPanelMenu from '../../../components/Menu/ControlPanelMenu';

interface IAdminPanelPrivateRoute {
    component: typeof React.Component | (() => JSX.Element) | React.ComponentType<any>;
    exact?: boolean;
    path: string;
    isPublic?: boolean;
    isAdmin?: boolean;
}

const PanelPrivateRoute: FC<IAdminPanelPrivateRoute> = ({ component: Component, isAdmin = false, ...rest }) => {
    const menu = isAdmin ? <AdminMenu /> : <ControlPanelMenu />;

    return (
        <Route
            {...rest}
            render={() => (
                <AppLayout menu={menu}>
                    <Component isAdmin={isAdmin} />
                </AppLayout>
            )}
        />
    );
};

export default PanelPrivateRoute;
