import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Tabs, Row, Col } from 'antd';
import ItemEdit from '../../components/ItemEdit';
import { resource, okei } from '../../utils/constants';
import { create, get, remove, update } from '../actions/crud';
import Loading from '../../components/Loading';
import GoodImg from '../../components/GoodImg';
import GoodPricesEditor from '../../pages/GoodEditPage/components/GoodPricesEditor';
import GoodShopPropertyLoader from './good/GoodShopPropertyEditorWrapper';
import GoodImages from './GoodImages';

const { TabPane } = Tabs;

class GoodEdit extends Component {
    id = 'new';

    fields = {
        title: '',
        description: '',
        categoryId: '',
        categoryIds: [],
        externalId: '',
        stock: '',
        shopAddressId: '',
        active: '',
        filled: '',
        unitCode: '',
        nds: '',
        type: 'commodity',
        orderMinCount: 0,
        weight: 0,
    };

    editFields = {
        ...this.fields,
        seoTitle: '',
        seoDescription: '',
        seoKeywords: '',
    };

    requiredFields = ['title', 'description', 'categoryId', 'nds'];

    onRemove(item) {
        this.props.remove(resource.GOOD, item.id).then(() => {
            this.props.onRemove(item);
        });
    }

    onSave(item) {
        const unit = okei.find((elem) => elem.value === item.unitCode);

        const validItem = {
            ...item,
            unitCode: Number(item.unitCode),
            stock: Number(item.stock),
            shopAddressId: item.shopAddressId === '' ? undefined : Number(item.shopAddressId),
            unit: unit && unit.label,
            active: Boolean(item.active),
            filled: Boolean(item.filled),
        };

        if (item.id) {
            this.props.update(resource.GOOD, item.id, validItem);
        } else {
            this.props.create(resource.GOOD, validItem);
        }
    }

    findGood(props = null) {
        if (!props) {
            props = this.props;
        }
        this.props.get(resource.GOOD, props.itemId, { shopId: props.shopId });
    }

    checkProps(props) {
        if (props.itemId > 0) {
            if (!props.item || props.item.id != props.itemId) {
                this.findGood(props);
            }
        }
    }

    componentWillMount() {
        this.checkProps(this.props);
    }

    componentWillReceiveProps(props) {
        this.checkProps(props);

        if (
            (!props.awaitUpdateItem && this.props.awaitUpdateItem) ||
            (!props.awaitCreateItem && this.props.awaitCreateItem)
        ) {
            if (this.props.onSave) {
                this.props.onSave(props.item);
            }
        }
    }

    render() {
        if (
            this.props.shopId === 0 ||
            (this.props.match.params.id === 'new' ? false : this.props.match.params.id !== String(this.props.item?.id))
        ) {
            return <Loading />;
        }
        const item = this.props.item && String(this.props.item.id) === this.props.itemId ? this.props.item : {};

        return (
            <Tabs defaultActiveKey="1">
                <TabPane tab="Информация о товаре" key="1">
                    <Row align="middle" gutter={4} style={{ marginBottom: 10 }}>
                        <Col>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <GoodImg item={item} funcAfterUpload={this.findGood.bind(this)} />
                            {item.imagePath ? (
                                <Button
                                    onClick={(e) => this.props.update(resource.GOOD, item.id, { imageId: null })}
                                    style={{ display: 'inline-block', margin: '10px' }}
                                >
                                    Удалить изображение
                                </Button>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col>
                            <GoodImages good={item} update={this.props.update} />
                        </Col>
                    </Row>

                    <ItemEdit
                        subjectName='good'
                        item={item}
                        deletable
                        fields={item.id ? this.editFields : this.fields}
                        onRemove={this.onRemove.bind(this)}
                        onSave={this.onSave.bind(this)}
                        additionalFields={{ shopId: this.props.shopId }}
                        requiredFields={this.requiredFields}
                    />
                </TabPane>
                <TabPane tab="Цены" key="2">
                    <GoodPricesEditor good={item} />
                </TabPane>
                <TabPane tab="Свойства" key="3">
                    <GoodShopPropertyLoader good={item} />
                </TabPane>
            </Tabs>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            item: state.goods.item,
            awaitUpdateItem: state.goods.awaitUpdateItem,
            awaitCreateItem: state.goods.awaitCreateItem,
        }),
        {
            get,
            update,
            remove,
            create,
        },
    )(GoodEdit),
);
