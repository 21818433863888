import React, {Component} from 'react';
import {connect} from "react-redux";
import {Breadcrumb} from 'antd';
import {withRouter} from "react-router";
import GoodShopPropertyList from "../components/GoodShopPropertyList";
import {createGoodShopPropertyLink} from "../../components/Linker/PathCreators";

class GoodShopPropertyListPage extends Component {

    goToItem(item) {
        this.props.history.push(createGoodShopPropertyLink(this.props.shopId, item.id));
    }

    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>Мэппинг свойств</Breadcrumb.Item>
                </Breadcrumb>
                <div className="content">
                    {this.props.shopId ?
                        <GoodShopPropertyList
                            shopId={this.props.shopId}
                            onClick={this.goToItem.bind(this)}
                        /> : ''}
                </div>
            </div>
        )
    }
}

export default withRouter(connect(
    state => ({
        shopId: state.system.shopId
    }),
    {}
)(GoodShopPropertyListPage));
