import React, { FC, useEffect } from 'react';

interface IYandexMetrika {
    id: string;
}

const YandexMetrika: FC<IYandexMetrika> = ({ id }) => {
    useEffect(() => {
        // @ts-ignore
        window.ym(id, 'init', {
            id,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
        });
    }, [id]);

    if (!id) {
        return null;
    }

    return (
        <noscript>
            <div>
                <img src={`https://mc.yandex.ru/watch/${id}`} style={{ position: 'absolute', left: -9999 }} alt="" />
            </div>
        </noscript>
    );
};

export default YandexMetrika;
