import React, { FC, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { findCrudItemsRequest } from '../../../store/Crud/http';
import { resource } from '../../../utils/constants';
import { IShop } from '../../../store/Crud/types';
import { TFormItemProps } from './types';

type TProps = {
    formItemProps: TFormItemProps;
    required?: boolean;
    disabled?: boolean;
};

const BrandFormField: FC<TProps> = ({ required, disabled, formItemProps }) => {
    const [brandShops, setBrandShops] = useState<undefined | Array<IShop>>();

    useEffect(() => {
        findCrudItemsRequest(resource.SHOP, { type: 'brand', _pageSize: 1000 }).then(({ data }) =>
            setBrandShops(data.data),
        );
    }, []);

    return (
        <Form.Item rules={[{ required }]} {...formItemProps}>
            <Select allowClear disabled={disabled}>
                {brandShops?.map((shop) => (
                    <Select.Option key={shop.id} value={shop.id}>
                        {shop.title}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default BrandFormField;
