import React, { FC, useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { createShopMarketplaceLink } from '../../../components/Linker/PathCreators';
import {
    SHOP_MARKETPLACE_SETTING_STATUS_LABEL,
    TShopMarketplaceSetting,
} from '../../../store/Crud/types/shopMarketplaceSetting';
import { IPagination, resource } from '../../../utils/constants';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';
import { find } from '../../../store/Crud';

type TProps = {
    shopId: string;
};

const ShopMarketplaceSettingList: FC<TProps> = ({ shopId }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const marketplaceSettings = useSelector(getCrudItems(resource.SHOP_MARKETPLACE_SETTING));
    const shops = useSelector(getCrudItems(resource.SHOP));

    const [pagination, setPagination] = useState<IPagination>({
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        total: 0,
    });

    const fetchMarketplaceSetting = useCallback(async () => {
        const filters = {
            _page: pagination.current,
            _pageSize: pagination.pageSize,
            marketplaceShopId: shopId,
        };

        const { payload } = await dispatch(find(resource.SHOP_MARKETPLACE_SETTING, filters)());
        if (payload && payload?.data) {
            setPagination({ ...pagination, total: payload?.metaData?.totalCount });
        }
    }, [dispatch, pagination.current, pagination.pageSize]);

    useEffect(() => {
        fetchMarketplaceSetting();
    }, [fetchMarketplaceSetting]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.participantShopId),
            dataIndex: 'participantShopId',
            key: 'participantShopId',
            render: (itemShopId: string) => {
                const shop = shops.find((elem) => elem.id === itemShopId);
                return shop?.title || itemShopId;
            },
        },
        {
            title: t(enumFieldName.status),
            dataIndex: 'status',
            key: 'status',
            width: '80%',
            render: (status: string) =>
                status in SHOP_MARKETPLACE_SETTING_STATUS_LABEL
                    ? SHOP_MARKETPLACE_SETTING_STATUS_LABEL[status]
                    : status,
        },
    ];

    const handleTableChange = (newPagination: IPagination) => {
        setPagination(newPagination);
    };

    const goToItem = (item: TShopMarketplaceSetting) => {
        history.push(createShopMarketplaceLink(String(item.marketplaceShopId), String(item.id)));
    };

    return (
        <Table
            pagination={pagination}
            rowKey="id"
            dataSource={marketplaceSettings}
            columns={columns}
            onRow={(record) => ({
                onClick: () => goToItem(record),
            })}
            // @ts-ignore
            onChange={handleTableChange}
        />
    );
};

export default ShopMarketplaceSettingList;
