import React, { Component } from 'react';
import { connect } from 'react-redux';
import { update, find } from '../../actions/crud';
import { resource } from '../../../utils/constants';
import GoodShopPropertyEditor from './GoodShopPropertyEditor';

class GoodShopPropertyEditorWrapper extends Component {
    constructor(props) {
        super(props);
        if (props.good) {
            this.props.find(resource.GOOD_SHOP_PROPERTY, { shopId: props.good.shopId, _pageSize: 99999 });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.good.shopId !== nextProps.good.shopId) {
            this.props.find(resource.GOOD_SHOP_PROPERTY, { shopId: nextProps.good.shopId, _pageSize: 99999 });
        }
    }

    getGoodModel(category) {
        if (!category) {
            return null;
        }
        const level = category.path.split('.').length;

        for (let i = 1; i <= level; ++i) {
            if (category.modelId > 0) {
                return this.props.goodModels.find((goodModel) => goodModel.id === category.modelId);
            }
            if (category.parentId) {
                category = this.props.categories.find((el) => el.id === category.parentId);
            } else {
                return null;
            }
        }

        return null;
    }

    getCategory() {
        return this.props.categories.find((category) => category.id === this.props.good.categoryId);
    }

    onSave(properties) {
        this.props.update(resource.GOOD, this.props.good.id, { manualProperties: properties });
    }

    render() {
        const category = this.getCategory();
        const goodModel = this.getGoodModel(category);

        if (!goodModel) {
            return <h2>У категории товара не назначена модель товара</h2>;
        }
        const goodModelProperties = this.props.goodProperties.filter((elem) => elem.modelId === goodModel.id);

        return (
            <GoodShopPropertyEditor
                {...{
                    good: this.props.good,
                    onSave: this.onSave.bind(this),
                    goodModel,
                    category,
                    goodModelProperties,
                }}
            />
        );
    }
}

export default connect(
    (state) => ({
        goodProperties: state['model-property'].items,
        categories: state.category.items,
        goodModels: state.model.items,
    }),
    {
        find,
        update,
    },
)(GoodShopPropertyEditorWrapper);
