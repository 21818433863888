import React from 'react';
import md5 from 'crypto-js/md5';
import { Button, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthError from './components/AuthError';
import { authByPassword } from '../../store/Auth';
import { validation } from './validation';
import { useAppDispatch } from '../../store';
import { getCurrentUserData } from '../../store/Auth/selectors';
import { createResetPasswordLink } from '../../components/Linker/PathCreators';
import { enumFieldName, t } from '../../utils/helpers/formatFieldsLabels';
import {smallFormLayout, tailSmallFormLayout} from "../../utils/constants";

const LoginCard = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const userData = useSelector(getCurrentUserData);

    const auth = (data: { phone: string; password: string }) => {
        dispatch(
            authByPassword({
                phone: data.phone,
                password: md5(data.password).toString(),
            }),
        );
    };

    return (
        <div className="auth-form">
            <h2>Вход в систему</h2>
            <div className="row">
                <AuthError errorCode={userData.authError} />
            </div>

            <Form onFinish={auth} form={form}>
                <Form.Item
                    name="phone"
                    label={t(enumFieldName.phone)}
                    {...smallFormLayout}
                    rules={[validation.require, validation.phoneRu]}
                >
                    <Input placeholder="7 900 000 00 00" />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t(enumFieldName.password)}
                    {...smallFormLayout}
                    rules={[validation.require]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item {...tailSmallFormLayout}>
                    <Button
                        icon={userData.waitResponse ? <LoadingOutlined /> : null}
                        disabled={userData.waitResponse}
                        className="login-btn"
                        htmlType="submit"
                    >
                        Войти
                    </Button>
                </Form.Item>
            </Form>
            <div className="row">
                <Link className="forgot_password" to={createResetPasswordLink()}>
                    Забыли пароль?
                </Link>
            </div>
        </div>
    );
};

export default LoginCard;
