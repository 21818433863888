import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { IModel } from '../../../store/Crud/types';
import { getCrudItems, getCrudItemsByShopId } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { getShopId } from '../../../store/System/selectors';

interface IGoodModelList {
    onClick: (arg: IModel) => void;
    isShopModelPage?: boolean;
}

const GoodModelList: FC<IGoodModelList> = ({ onClick, isShopModelPage }) => {
    const shopId: number = useSelector(getShopId) as any;
    const goodModels = useSelector(getCrudItems(resource.MODEL));
    const goodModelsByShopId = useSelector(getCrudItemsByShopId(resource.MODEL)(shopId));

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '10%',
        },
        {
            title: t(enumFieldName.description),
            dataIndex: 'description',
            key: 'description',
            width: '10%',
        },
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
            width: '10%',
        },
        {
            title: t(enumFieldName.public),
            dataIndex: 'public',
            key: 'public',
            width: '10%',
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={isShopModelPage ? goodModelsByShopId : goodModels}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default GoodModelList;
