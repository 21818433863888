import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import OrderEdit from './components/OrderEdit';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { IOrder } from '../../store/Crud/types/order';

const OrderEditPage = () => {
    const { id: orderId } = useParams<{ id: string }>();

    const shopId: number = useSelector(getShopId) as any;
    const orderItem: IOrder = useSelector(getCrudItem(resource.ORDER)) as any;

    const getTitle = () => {
        if (orderId === 'new') {
            return 'Новый';
        }
        return orderItem ? `#${orderItem.id}` : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.orderList}>Заказы</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <OrderEdit shopId={shopId} itemId={orderId} />
            </div>
        </div>
    );
};

export default OrderEditPage;
