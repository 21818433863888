import React, { FC } from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Linker from '../../components/Linker';
import GoodModelList from './components/GoodModelList';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createGoodModelLink, createShopModelLink } from '../../components/Linker/PathCreators';
import { IModel } from '../../store/Crud/types';
import { getShopId } from '../../store/System/selectors';

interface IGoodModelListPage {
    isAdmin?: boolean;
    isShopRoute?: boolean;
}

const GoodModelListPage: FC<IGoodModelListPage> = ({ isAdmin, isShopRoute }) => {
    const history = useHistory();
    const shopId = useSelector(getShopId);

    const isShopModelPage = !isAdmin || isShopRoute;

    const goToItem = (item: IModel) => {
        history.push(
            isShopModelPage
                ? createShopModelLink(String(shopId), String(item.id))
                : createGoodModelLink(String(item.id)),
        );
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Модели товаров</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={isShopModelPage ? enumTypesLinks.shopModel : enumTypesLinks.goodModel} itemId="new">
                        Создать
                    </Linker>
                </div>
                <GoodModelList isShopModelPage={isShopModelPage} onClick={goToItem} />
            </div>
        </div>
    );
};

export default GoodModelListPage;
