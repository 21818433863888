import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import ShopClientList from './components/ShopClientList';
import { createShopClientLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IClient } from '../../store/Crud/types/client';

const ShopClientListPage = () => {
    const history = useHistory();

    const shopId = useSelector(getShopId);

    const goToItem = (item: IClient) => history.push(createShopClientLink(String(shopId), String(item.id)));

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Клиенты</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">{shopId ? <ShopClientList shopId={shopId} onClick={goToItem} /> : <Empty />}</div>
        </div>
    );
};

export default ShopClientListPage;
