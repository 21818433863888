import React, { FC } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getShopId } from '../../store/System/selectors';
import { createShopNotificationConnectionLink, createShopNotificationLink } from '../../components/Linker/PathCreators';
import ShopNotificationList from './components/ShopNotificationList';
import { IShopNotification } from '../../store/Crud/types/notification';
import ShopNotificationConnectionList from './components/ShopNotificationConnectionList';
import { IShopNotificationConnection } from '../../store/Crud/types/shopNotificationConnection';

const { TabPane } = Tabs;

enum enumTabs {
    'first' = 'first',
    'second' = 'second',
}

interface IShopNotificationListPage {
    location: {
        state: {
            isShowShopNotificationConnection: boolean;
        };
    };
}

const ShopNotificationListPage: FC<IShopNotificationListPage> = ({ location }) => {
    const history = useHistory();
    const shopId: string = useSelector(getShopId) as any;

    const goToItemShopNotification = (item: IShopNotification) => {
        history.push(createShopNotificationLink(shopId, String(item.id)));
    };

    const goToItemShopNotificationConnection = (item: IShopNotificationConnection) => {
        history.push(createShopNotificationConnectionLink(shopId, String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Настройки уведомлений</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs
                className="content"
                defaultActiveKey={location?.state?.isShowShopNotificationConnection ? enumTabs.second : enumTabs.first}
            >
                <TabPane tab="Основные настройки" key={enumTabs.first}>
                    <div className="content">
                        <div style={{ margin: '16px 0' }}>
                            <Linker type={enumTypesLinks.shopNotification} itemId="new">
                                Создать
                            </Linker>
                        </div>
                        {shopId && <ShopNotificationList shopId={shopId} onClick={goToItemShopNotification} />}
                    </div>
                </TabPane>
                <TabPane tab="Настройки каналов нотификаций" key={enumTabs.second}>
                    <ShopNotificationConnectionList goToItem={goToItemShopNotificationConnection} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default ShopNotificationListPage;
