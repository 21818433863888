export enum DICTIONARY_ITEM_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    data = 'data',
    externalId = 'externalId',
    dictionaryId = 'dictionaryId',
}

export interface IDictionaryItem {
    [DICTIONARY_ITEM_FIELDS.id]: number;
    [DICTIONARY_ITEM_FIELDS.title]: number;
    [DICTIONARY_ITEM_FIELDS.description]: number;
    [DICTIONARY_ITEM_FIELDS.data]: { [key: string]: string };
    [DICTIONARY_ITEM_FIELDS.externalId]: number;
    [DICTIONARY_ITEM_FIELDS.dictionaryId]: number;
}

export interface IDictionaryItemForm extends IDictionaryItem {
    [key: string]: string | number | { [key: string]: string };
}
