import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Rule } from 'antd/es/form';

export const validation = {
    require: {
        required: true,
        message: 'Поле обязательно для заполнения',
    },
    email: {
        pattern: /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(-[a-z0-9]+)*(\.[a-z0-9]+(-[a-z0-9]+)*)*\.[a-z]{2,6}$/,
        message: 'Неверный формат email',
    },
    phoneRu: {
        validator: (rule: Rule, value: string, callback: (arg?: boolean) => void) => {
            const phoneNumber = parsePhoneNumberFromString(`+${value}`, 'RU');
            if (phoneNumber && phoneNumber.isValid()) {
                return callback();
            }

            return callback(true);
        },
        message: 'Неверный формат номера телефона',
    } as Rule,
};
