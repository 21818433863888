import React from 'react';
import { Breadcrumb } from 'antd';
import ShopList from './components/ShopList';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';

const ShopListPage = () => (
    <div>
        <Breadcrumb>
            <Breadcrumb.Item>Площадки</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
            <div style={{ margin: '16px 0' }}>
                <Linker type={enumTypesLinks.shop} itemId="new">
                    Создать
                </Linker>
            </div>
            <ShopList />
        </div>
    </div>
);

export default ShopListPage;
