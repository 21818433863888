import { resource } from '../../utils/constants';
import http, { ICommonResponse } from '../../utils/http';
import { IUser, IUserAuth } from './types';

export const getSessionRequest = async () => http.get<ICommonResponse<IUser>>(resource.AUTH);

export const postAuthByPasswordRequest = async (userAuthData: IUserAuth) =>
    http.post<ICommonResponse<IUser>>(resource.AUTH, userAuthData);

export const logoutRequest = async () => http.delete<ICommonResponse<IUser>>(resource.AUTH);

export const setPasswordRequest = async (password: string) => http.post(resource.PASSWORD, { password });
