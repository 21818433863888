import React, { useEffect, useState, ChangeEvent } from 'react';
import { Col, Input, Row, Select, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { resource } from '../../../utils/constants';
import { setCurrentShopId } from '../../../store/System';
import { useAppDispatch } from '../../../store';
import { IShop } from '../../../store/Crud/types';
import { createShopLink } from '../../../components/Linker/PathCreators';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { findCrudItemsRequest } from '../../../store/Crud/http';
import { SHOP_TYPE, SHOP_TYPE_LABEL } from '../../../store/Crud/types/shop';

const pageSize = 8;

const ShopList = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const [shops, setShops] = useState();
    const [type, setType] = useState<SHOP_TYPE>();
    const [name, setName] = useState<string>();

    useEffect(() => {
        findCrudItemsRequest(resource.SHOP, {
            _order: { id: 'desc' },
            _pageSize: pageSize,
            _page: currentPage,
            type,
            'title[_start]': name,
        }).then(({ data }) => {
            setShops(data.data);
            setTotalCount(data.metaData.totalCount);
        });
    }, [currentPage, name, type]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.alias),
            dataIndex: 'alias',
            key: 'alias',
            width: '100px',
        },
        {
            title: t(enumFieldName.type),
            dataIndex: 'type',
            key: 'type',
            render: (value: SHOP_TYPE) => SHOP_TYPE_LABEL[value],
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '70%',
        },
    ];

    const goToItem = (item: IShop) => {
        dispatch(setCurrentShopId(item.id));
        history.push(createShopLink(item.id.toString()));
    };

    const handlePaginationChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleTypeSelectChange = (valueType: SHOP_TYPE) => {
        setType(valueType);
        setCurrentPage(1);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setCurrentPage(1);
    };

    return (
        <>
            <Row style={{ marginBottom: 20 }} gutter={10}>
                <Col>
                    <Input allowClear placeholder="Наименование" value={name} onChange={handleNameChange} />
                </Col>
                <Col>
                    <Select
                        allowClear
                        style={{ width: 300 }}
                        onChange={handleTypeSelectChange}
                        placeholder="Тип"
                        value={type}
                    >
                        {Object.values(SHOP_TYPE)?.map((typeItem) => (
                            <Select.Option key={typeItem} value={typeItem}>
                                {SHOP_TYPE_LABEL[typeItem]}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>

            <Table
                rowKey="id"
                dataSource={shops}
                columns={columns}
                pagination={{
                    current: currentPage,
                    onChange: handlePaginationChange,
                    pageSize,
                    total: totalCount,
                }}
                onRow={(record) => ({
                    onClick: () => goToItem(record),
                })}
            />
        </>
    );
};

export default ShopList;
