import { createSlice } from '@reduxjs/toolkit';
import { find } from '../Crud';
import { resource } from '../../utils/constants';
import { AppDispatch } from '../index';

export const setCurrentShopId = (shopId: string | number) => (dispatch: AppDispatch) => {
    dispatch(setShopId(shopId));
    if (shopId !== 'new') {
        dispatch(find(resource.SHOP_ADDRESS, { shopId, _order: { path: 'asc' } })());
        dispatch(find(resource.CATEGORY, { shopId, _order: { path: 'asc' } })());
        dispatch(find(resource.PRICE, { shopId, _order: { path: 'asc' } }));
    }
};

export interface ISystemState {
    shopId: number | string;
}

const initialState = {
    shopId: 0,
} as ISystemState;

const system = createSlice({
    name: 'system',
    initialState,
    reducers: {
        resetSystem: (state) => {
            state.shopId = 0;
        },
        setShopId: (state, action) => {
            state.shopId = action.payload;
        },
    },
});

export const { setShopId, resetSystem } = system.actions;

export default system.reducer;
