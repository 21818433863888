import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFileRequest, uploadFileRequest } from './http';
import { resource } from '../../utils/constants';
import { ICommonResponse } from '../../utils/http';
import { IFile } from '../Crud/types/file';

export interface IFileState {
    waitFileUpload: boolean;
}

export const uploadShopFile = createAsyncThunk(
    'fileGeneral/uploadShopFile',
    async (uploadData: { uploadFile: any; shopId: number; type: string }) => {
        const resourceFull = `${resource.SHOP}/${uploadData.shopId}/file/${uploadData.type}`;
        const body = new FormData();
        body.append(uploadData.shopId.toString(), uploadData.uploadFile, uploadData.uploadFile.name);
        return uploadFileRequest(resourceFull, body);
    },
);

export const uploadGoodImage = createAsyncThunk(
    'file/uploadGoodImage',
    async (uploadData: { uploadFile: any; goodId: number }) => {
        const body = new FormData();
        body.append(uploadData.goodId.toString(), uploadData.uploadFile, uploadData.uploadFile.name);
        return uploadFileRequest(resource.IMAGE, body);
    },
);

export const getFile = createAsyncThunk<ICommonResponse<Array<IFile>>, number | Array<number>>(
    'fileGeneral/get',
    async (id: number | Array<number>) => {
        const { data } = await getFileRequest(id);
        return data;
    },
);

const initialState = {
    waitFileUpload: false,
} as IFileState;

const fileGeneral = createSlice({
    name: 'fileGeneral',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // uploadShopFile
        builder.addCase(uploadShopFile.pending, (state) => {
            state.waitFileUpload = true;
        });
        builder.addCase(uploadShopFile.rejected, (state) => {
            state.waitFileUpload = false;
        });
        builder.addCase(uploadShopFile.fulfilled, (state) => {
            state.waitFileUpload = false;
        });
        // uploadGoodImage
        builder.addCase(uploadGoodImage.pending, (state) => {
            state.waitFileUpload = true;
        });
        builder.addCase(uploadGoodImage.rejected, (state) => {
            state.waitFileUpload = false;
        });
        builder.addCase(uploadGoodImage.fulfilled, (state) => {
            state.waitFileUpload = false;
        });
    },
});

export default fileGeneral.reducer;
