import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { IShop } from '../../../store/Crud/types';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { TFormItemProps } from './types';

type TProps = {
    formItemProps: TFormItemProps;
    required?: boolean;
    disabled?: boolean;
};

const ShopFormField: FC<TProps> = ({ formItemProps, disabled, required }) => {
    const shops: IShop[] = useSelector(getCrudItems(resource.SHOP)) as any;

    return (
        <Form.Item rules={[{ required }]} {...formItemProps}>
            <Select allowClear disabled={disabled}>
                {shops.map((shop) => (
                    <Select.Option key={shop.id} value={shop.id}>
                        {shop.title}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default ShopFormField;
