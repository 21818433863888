import _ from 'lodash';
import { crudActions } from '../constants/reducerActions';

const crudReducer = (
    state = {
        items: [],
        dictionary: [],
        item: null,
        awaitLoadItem: false,
        awaitLoadList: false,
        awaitCreateItem: false,
        awaitDeleteItem: false,
        awaitUpdateItem: false,
        error: {},
    },
    action,
    reducerName,
) => {
    switch (action.type) {
        case `${crudActions.ITEM_ERROR}_${reducerName}`:
            return { ...state, error: action.error };

        case `${crudActions.WAIT_DELETE}_${reducerName}`:
            return { ...state, awaitDeleteItem: action.value };

        case `${crudActions.WAIT_CREATE}_${reducerName}`:
            return { ...state, awaitCreateItem: action.value };

        case `${crudActions.WAIT_UPDATE}_${reducerName}`:
            return { ...state, awaitUpdateItem: action.value };

        case `${crudActions.LOADING_ITEM}_${reducerName}`:

            return { ...state, awaitLoadItem: action.value };

        case `${crudActions.LOADING_LIST}_${reducerName}`:

            return { ...state, awaitLoadList: action.value };

        case `${crudActions.SET_LIST}_${reducerName}`:
            return { ...state, items: action.items };

        case `${crudActions.SET_DICTIONARY}_${reducerName}`:
            return { ...state, dictionary: action.items };

        case `${crudActions.SET_ITEM}_${reducerName}`:
            const item = { ...action?.item } || {};
            let items = [...state.items];
            let isNew = true;

            if (!_.isEmpty(item)) {
                items = items?.map((obj) => {
                    if (obj.id === item.id) {
                        isNew = false;
                        return item;
                    } else {
                        return obj;
                    }
                });
                if (isNew) {
                    items.unshift(item);
                }
            }

            return { ...state, items, item, error: {} };

        default:
            return state;
    }
};

const createCrudReducer = (reducerName) => (state, action) => crudReducer(state, action, reducerName);

export default createCrudReducer;
