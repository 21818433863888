import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { IPrice } from '../../../store/Crud/types';

interface IGoodPriceList {
    ids: number[];
}

const GoodPriceList: FC<IGoodPriceList> = ({ ids }) => {
    const prices: IPrice[] = useSelector(getCrudItems(resource.PRICE)) as any;

    const items = prices.filter((elem) => ids.indexOf(elem.id) !== -1);

    if (!items) {
        return <span> Цены не заданы</span>;
    }

    return (
        <span>
            {items.map((elem) => (
                <span>
                    {elem.title}
                    <br />
                </span>
            ))}
        </span>
    );
};

export default GoodPriceList;
