import React, { FC } from 'react';
import { Form } from 'antd';
import GoodPropertyValue from '../../legacy/components/good/GoodPropertyValue';
import { IGoodProperty } from '../../store/Crud/types';

interface IProperty {
    integrationProperties?: { [key: string]: any };
    manualProperties?: { [key: string]: any };
    record: IGoodProperty;
    groupName?: string;
}

const Property: FC<IProperty> = ({ record, integrationProperties, manualProperties, groupName }) => {
    const integrationValue = integrationProperties?.[record.externalId];
    const manualValue = manualProperties?.[record.externalId];

    const getLabel = () => {
        if (groupName !== record.groupName) {
            groupName = record.groupName;
            return groupName;
        } else {
            return '';
        }
    };

    return (
        <>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <b>{getLabel()}</b>
            </div>
            <Form.Item key={record.externalId} label={`#${record.id} ${record.title}`} name={record.externalId}>
                <GoodPropertyValue
                    key={record?.id}
                    fieldName={record.externalId}
                    type={record.type}
                    integrationValue={integrationValue}
                    manualValue={manualValue}
                />
            </Form.Item>
        </>
    );
};

export default Property;
