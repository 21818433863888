import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { IShopAddress } from '../../../store/Crud/types';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';

interface IShopAddressList {
    shopId: number;
    onClick: (shopAddress: IShopAddress) => void;
}

const ShopAddressList: FC<IShopAddressList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const shopAddresses = useSelector(getCrudItems(resource.SHOP_ADDRESS));

    useEffect(() => {
        dispatch(find(resource.SHOP_ADDRESS, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.phone),
            dataIndex: 'phone',
            key: 'phone',
            width: '25%',
        },
        {
            title: t(enumFieldName.pickupPoint),
            dataIndex: 'pickupPoint',
            key: 'pickupPoint',
            width: '10%',
            render: (item: boolean) => (item ? 'Да' : 'Нет'),
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={shopAddresses}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ShopAddressList;
