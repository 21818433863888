import React, { FC } from 'react';
import _ from 'lodash';
import { enumFieldName, t } from '../../utils/helpers/formatFieldsLabels';

export interface IObject {
    [key: string]: string;
}

interface IObjectView {
    object: IObject;
}

export const ObjectView: FC<IObjectView> = ({ object }) => {
    if (!object) {
        return null;
    }

    return (
        <div>
            {Object.keys(object).map((key) => {
                const obj = object[key];

                if (_.isObject(obj)) {
                    return null;
                }

                return (
                    <div key={key}>
                        <b>{t(key as enumFieldName)}</b>: {obj}
                    </div>
                );
            })}
        </div>
    );
};
