import React, { FC } from 'react';
import ImgEdit from '../ImgEdit';
import { uploadGoodImage } from '../../store/File';
import { useAppDispatch } from '../../store';

interface IGoodImg {
    funcAfterUpload?: () => void;
    item: any;
}

const GoodImg: FC<IGoodImg> = ({ funcAfterUpload, item }) => {
    const dispatch = useAppDispatch();

    if (!item) {
        return null;
    }

    const uploadImage = async (image: HTMLImageElement) => {
        await dispatch(uploadGoodImage({ uploadFile: image, goodId: item.id }));
        if (funcAfterUpload) {
            funcAfterUpload();
        }
    };

    return <ImgEdit imagePath={item.imagePath} uploadImage={uploadImage} />;
};

export default GoodImg;
