export const powerOfAttorneyId = 31;
export const packingListId = 30;

// по этому енаму определяются типы для stateGenerator и его селекторов
export enum enumResources {
    CODE = 'code',
    SHOP = 'shop',
    ORDER = 'order',
    CATEGORY = 'category',
    PROFILE = 'profile',
    NOTIFICATION_INTEGRATION = 'notification-integration',
    SHOP_NOTIFICATION = 'shop-notification',
    DELIVERY_INTEGRATION = 'delivery-integration',
    SHOP_DELIVERY = 'shop-delivery',
    PAYMENT_INTEGRATION = 'payment-integration',
    SHOP_PAYMENT = 'shop-payment',
    MANAGER = 'manager',
    SHOP_ADDRESS = 'shop-address',
    CLAIM_INTEGRATION = 'claim-integration',
    SHOP_CLAIM = 'shop-claim',
    API_USER = 'api-user',
    DICTIONARY = 'dictionary',
    MODEL = 'model',
    MODEL_PROPERTY = 'model-property',
    PRICE = 'price',
    LEGAL_PRICE = 'legal-price',
    DICTIONARY_ITEM = 'dictionary-item',
    CART = 'cart',
    FILE = 'file',
    SHOP_NOTIFICATION_CONNECTION = 'shop-notification-connection',
    SHOP_MARKETPLACE_SETTING = 'marketplace-setting',
}

export const resource = {
    CODE: enumResources.CODE,
    AUTH: 'auth',
    PASSWORD: 'password',
    SESSION: 'session',
    PROFILE: enumResources.PROFILE,
    DELIVERY_INTEGRATION: enumResources.DELIVERY_INTEGRATION,
    PAYMENT_INTEGRATION: enumResources.PAYMENT_INTEGRATION,
    NOTIFICATION_INTEGRATION: enumResources.NOTIFICATION_INTEGRATION,
    SHOP_NOTIFICATION_CONNECTION: enumResources.SHOP_NOTIFICATION_CONNECTION,
    MODEL: enumResources.MODEL,
    MODEL_PROPERTY: enumResources.MODEL_PROPERTY,
    GOOD_SHOP_PROPERTY: 'good-shop-property',
    SHOP_DELIVERY: enumResources.SHOP_DELIVERY,
    SHOP_PAYMENT: enumResources.SHOP_PAYMENT,
    SHOP_CLIENT: 'shop-client',
    SHOP: enumResources.SHOP,
    ORDER: enumResources.ORDER,
    ADDRESS: 'delivery-address',
    CATEGORY: enumResources.CATEGORY,
    DICTIONARY: enumResources.DICTIONARY,
    DICTIONARY_ITEM: enumResources.DICTIONARY_ITEM,
    GOOD: 'good',
    MODEL_PROPERTY_COPY: 'model-property-copy',
    LEGAL_PRICE: enumResources.LEGAL_PRICE,
    PRICE: enumResources.PRICE,
    MANAGER: enumResources.MANAGER,
    SHOP_ADDRESS: enumResources.SHOP_ADDRESS,
    API_USER: enumResources.API_USER,
    IMAGE: 'image',
    FILE: enumResources.FILE,
    CART: enumResources.CART,
    SHOP_NOTIFICATION: enumResources.SHOP_NOTIFICATION,
    CLAIM_INTEGRATION: enumResources.CLAIM_INTEGRATION,
    SHOP_CLAIM: enumResources.SHOP_CLAIM,
    SHOP_MARKETPLACE_SETTING: enumResources.SHOP_MARKETPLACE_SETTING,
};

export const okei = [
    { value: 47, label: 'миля' },
    { value: 8, label: 'км; 10^3 м' },
    { value: 5, label: 'дм' },
    { value: 4, label: 'см' },
    { value: 39, label: 'дюйм' },
    { value: 6, label: 'м' },
    { value: 41, label: 'фут' },
    { value: 3, label: 'мм' },
    { value: 9, label: 'Мм; 10^6 м' },
    { value: 43, label: 'ярд' },
    { value: 59, label: 'га' },
    { value: 73, label: 'фут2' },
    { value: 53, label: 'дм2' },
    { value: 61, label: 'км2' },
    { value: 51, label: 'см2' },
    { value: 109, label: 'а' },
    { value: 55, label: 'м2' },
    { value: 58, label: '10^3 м^2' },
    { value: 75, label: 'ярд2' },
    { value: 50, label: 'мм2' },
    { value: 71, label: 'дюйм2' },
    { value: 126, label: 'Мл' },
    { value: 132, label: 'фут3' },
    { value: 118, label: 'дл' },
    { value: 133, label: 'ярд3' },
    { value: 112, label: 'л; дм3' },
    { value: 113, label: 'м3' },
    { value: 131, label: 'дюйм3' },
    { value: 159, label: '10^6 м3' },
    { value: 110, label: 'мм3' },
    { value: 122, label: 'гл' },
    { value: 111, label: 'см3; мл' },
    { value: 170, label: '10^3 т' },
    { value: 161, label: 'мг' },
    { value: 173, label: 'сг' },
    { value: 206, label: 'ц' },
    { value: 163, label: 'г' },
    { value: 181, label: 'БРТ' },
    { value: 160, label: 'гг' },
    { value: 168, label: 'т' },
    { value: 162, label: 'кар' },
    { value: 185, label: 'т грп' },
    { value: 166, label: 'кг' },
    { value: 331, label: 'об/мин' },
    { value: 300, label: 'атм' },
    { value: 306, label: 'г Д/И' },
    { value: 304, label: 'мКи' },
    { value: 243, label: 'Вт.ч' },
    { value: 309, label: 'бар' },
    { value: 301, label: 'ат' },
    { value: 270, label: 'Кл' },
    { value: 288, label: 'K' },
    { value: 280, label: 'град. C' },
    { value: 282, label: 'кд' },
    { value: 330, label: 'об/с' },
    { value: 297, label: 'кПа' },
    { value: 302, label: 'ГБк' },
    { value: 291, label: 'кГц' },
    { value: 230, label: 'квар' },
    { value: 281, label: 'град. F' },
    { value: 292, label: 'МГц' },
    { value: 227, label: 'кВ.А' },
    { value: 323, label: 'Бк' },
    { value: 298, label: 'МПа' },
    { value: 263, label: 'А.ч' },
    { value: 247, label: 'ГВт.ч' },
    { value: 245, label: 'кВт.ч' },
    { value: 212, label: 'Вт' },
    { value: 273, label: 'кДж' },
    { value: 305, label: 'Ки' },
    { value: 228, label: 'МВ.А' },
    { value: 314, label: 'Ф' },
    { value: 284, label: 'лм' },
    { value: 215, label: 'МВт; 10^3 кВт' },
    { value: 274, label: 'Ом' },
    { value: 271, label: 'Дж' },
    { value: 333, label: 'км/ч' },
    { value: 349, label: 'Кл/кг' },
    { value: 264, label: '10^3 А.ч' },
    { value: 222, label: 'В' },
    { value: 223, label: 'кВ' },
    { value: 335, label: 'м/с2' },
    { value: 290, label: 'Гц' },
    { value: 260, label: 'А' },
    { value: 246, label: 'МВт.ч; 10^3 кВт.ч' },
    { value: 324, label: 'Вб' },
    { value: 312, label: 'кб' },
    { value: 294, label: 'Па' },
    { value: 283, label: 'лк' },
    { value: 310, label: 'гб' },
    { value: 308, label: 'мб' },
    { value: 327, label: 'уз' },
    { value: 296, label: 'См' },
    { value: 316, label: 'кг/м3' },
    { value: 328, label: 'м/с' },
    { value: 214, label: 'кВт' },
    { value: 289, label: 'Н' },
    { value: 368, label: 'деслет' },
    { value: 361, label: 'дек' },
    { value: 364, label: 'кварт' },
    { value: 365, label: 'полгода' },
    { value: 362, label: 'мес' },
    { value: 359, label: 'сут; дн' },
    { value: 355, label: 'мин' },
    { value: 356, label: 'ч' },
    { value: 360, label: 'нед' },
    { value: 354, label: 'с' },
    { value: 366, label: 'г; лет' },
    { value: 745, label: 'элем' },
    { value: 781, label: '100 упак' },
    { value: 732, label: '10 пар' },
    { value: 599, label: '10^3 м3/сут' },
    { value: 730, label: '20' },
    { value: 733, label: 'дюжина пар' },
    { value: 799, label: '10^6 шт' },
    { value: 796, label: 'шт' },
    { value: 778, label: 'упак' },
    { value: 831, label: 'л 100% спирта' },
    { value: 657, label: 'изд' },
    { value: 865, label: 'кг Р2О5' },
    { value: 641, label: 'дюжина' },
    { value: 841, label: 'кг H2О2' },
    { value: 734, label: 'посыл' },
    { value: 704, label: 'набор' },
    { value: 847, label: 'т 90% с/в' },
    { value: 499, label: 'кг/с' },
    { value: 801, label: '10^12 шт' },
    { value: 683, label: '100 ящ.' },
    { value: 740, label: 'дюжина шт' },
    { value: 802, label: '10^18 шт' },
    { value: 821, label: 'креп. спирта по объему' },
    { value: 533, label: 'т пар/ч' },
    { value: 859, label: 'кг КОН' },
    { value: 852, label: 'кг К2О' },
    { value: 625, label: 'л.' },
    { value: 798, label: 'тыс. шт; 1000 шт' },
    { value: 630, label: 'тыс станд. усл. кирп' },
    { value: 797, label: '100 шт' },
    { value: 626, label: '100 л.' },
    { value: 736, label: 'рул' },
    { value: 780, label: 'дюжина упак' },
    { value: 800, label: '10^9 шт' },
    { value: 863, label: 'кг NaOH' },
    { value: 833, label: 'Гл 100% спирта' },
    { value: 715, label: 'пар' },
    { value: 861, label: 'кг N' },
    { value: 598, label: 'м3/ч' },
    { value: 845, label: 'кг 90% с/в' },
    { value: 867, label: 'кг U' },
    { value: 735, label: 'часть' },
    { value: 820, label: 'креп. спирта по массе' },
    { value: 737, label: 'дюжина рул' },
    { value: 616, label: 'боб' },
    { value: 596, label: 'м3/с' },
    { value: 49, label: 'км усл. труб' },
    { value: 20, label: 'усл. м' },
    { value: 48, label: '10^3 усл. м' },
    { value: 18, label: 'пог. м' },
    { value: 19, label: '10^3 пог. м' },
    { value: 57, label: '10^6 м2' },
    { value: 81, label: 'м2 общ. пл' },
    { value: 64, label: '10^6 усл. м2' },
    { value: 83, label: '10^6 м2 общ. пл' },
    { value: 62, label: 'усл. м2' },
    { value: 63, label: '10^3 усл. м2' },
    { value: 86, label: '10^6 м2 жил. пл' },
    { value: 82, label: '10^3 м2 общ. пл' },
    { value: 56, label: '10^6 дм2' },
    { value: 54, label: '10^3 дм2' },
    { value: 89, label: '10^6 м2 2 мм исч' },
    { value: 60, label: '10^3 га' },
    { value: 88, label: '10^3 м2 уч. лаб. здан' },
    { value: 87, label: 'м2 уч. лаб. здан' },
    { value: 85, label: '10^3 м2 жил. пл' },
    { value: 84, label: 'м2 жил. пл' },
    { value: 121, label: 'плотн. м3' },
    { value: 124, label: '10^3 усл. м3' },
    { value: 130, label: '10^3 л; 1000 л' },
    { value: 120, label: '10^6 дкл' },
    { value: 129, label: '10^6 пол. л' },
    { value: 128, label: '10^3 пол. л' },
    { value: 123, label: 'усл. м3' },
    { value: 127, label: '10^3 плотн. м3' },
    { value: 116, label: 'дкл' },
    { value: 114, label: '10^3 м3' },
    { value: 115, label: '10^9 м3' },
    { value: 119, label: '10^3 дкл' },
    { value: 125, label: '10^6 м3 перераб. газа' },
    { value: 167, label: '10^6 кар' },
    { value: 178, label: '10^3 т перераб' },
    { value: 176, label: '10^6 т усл. топл' },
    { value: 179, label: 'усл. т' },
    { value: 207, label: '10^3 ц' },
    { value: 171, label: '10^6 т' },
    { value: 177, label: '10^3 т единовр. хран' },
    { value: 169, label: '10^3 т' },
    { value: 165, label: '10^3 кар' },
    { value: 175, label: '10^3 т усл. топл' },
    { value: 172, label: 'т усл. топл' },
    { value: 226, label: 'В.А' },
    { value: 339, label: 'см вод. ст' },
    { value: 236, label: 'кал/ч' },
    { value: 255, label: 'бай' },
    { value: 287, label: 'Гн' },
    { value: 250, label: '10^3 кВ.А Р' },
    { value: 235, label: '10^6 Гкал' },
    { value: 313, label: 'Тл' },
    { value: 256, label: 'кбайт' },
    { value: 234, label: '10^3 Гкал' },
    { value: 237, label: 'ккал/ч' },
    { value: 239, label: '10^3 Гкал/ч' },
    { value: 317, label: 'кг/см^2' },
    { value: 252, label: '10^3 л. с' },
    { value: 238, label: 'Гкал/ч' },
    { value: 338, label: 'мм рт. ст' },
    { value: 337, label: 'мм вод. ст' },
    { value: 251, label: 'л. с' },
    { value: 258, label: 'бод' },
    { value: 242, label: '10^6 кВ.А' },
    { value: 232, label: 'ккал' },
    { value: 257, label: 'Мбайт' },
    { value: 249, label: '10^9 кВт.ч' },
    { value: 241, label: '10^6 А.ч' },
    { value: 233, label: 'Гкал' },
    { value: 253, label: '10^6 л. с' },
    { value: 231, label: 'м/ч' },
    { value: 254, label: 'бит' },
    { value: 248, label: 'кВ.А Р' },
    { value: 352, label: 'мкс' },
    { value: 353, label: 'млс' },
    { value: 534, label: 'т/ч' },
    { value: 513, label: 'авто т' },
    { value: 876, label: 'усл. ед' },
    { value: 918, label: 'л. авт' },
    { value: 873, label: '10^3 флак' },
    { value: 903, label: '10^3 учен. мест' },
    { value: 870, label: 'ампул' },
    { value: 421, label: 'пасс. мест' },
    { value: 540, label: 'чел.дн' },
    { value: 427, label: 'пасс.поток' },
    { value: 896, label: 'семей' },
    { value: 751, label: '10^3 рул' },
    { value: 951, label: '10^3 ваг (маш).ч' },
    { value: 963, label: 'привед.ч' },
    { value: 978, label: 'канал. конц' },
    { value: 975, label: 'суго. сут.' },
    { value: 967, label: '10^6 т. миль' },
    { value: 792, label: 'чел' },
    { value: 547, label: 'пар/смен' },
    { value: 839, label: 'компл' },
    { value: 881, label: 'усл. банк' },
    { value: 562, label: '10^3 пряд.верет' },
    { value: 909, label: 'кварт' },
    { value: 644, label: '10^6 ед' },
    { value: 922, label: 'знак' },
    { value: 877, label: '10^3 усл. ед' },
    { value: 960, label: '10^3 автомоб.т.дн' },
    { value: 954, label: 'ваг.сут' },
    { value: 761, label: '10^3 стан' },
    { value: 511, label: 'кг/Гкал' },
    { value: 912, label: '10^3 коек' },
    { value: 980, label: '10^3 доллар' },
    { value: 387, label: '10^12 руб' },
    { value: 908, label: 'ном' },
    { value: 968, label: '10^6 пасс. миль' },
    { value: 962, label: '10^3 автомоб.мест. дн' },
    { value: 916, label: 'усл. рем/год' },
    { value: 414, label: 'пасс.км' },
    { value: 895, label: '10^6 усл. кирп' },
    { value: 888, label: '10^3 усл. ящ' },
    { value: 699, label: '10^3 мест' },
    { value: 522, label: 'чел/км2' },
    { value: 869, label: '10^3 бут' },
    { value: 958, label: '10^3 пасс.миль' },
    { value: 510, label: 'г/кВт.ч' },
    { value: 983, label: 'суд.сут' },
    { value: 535, label: 'т/сут' },
    { value: 424, label: '10^6 пасс. км' },
    { value: 907, label: '10^3 посад. мест' },
    { value: 965, label: '10^3 км' },
    { value: 538, label: '10^3 т/год' },
    { value: 546, label: '10^3 посещ/смен' },
    { value: 775, label: '10^3 тюбик' },
    { value: 961, label: '10^3 автомоб.ч' },
    { value: 537, label: '10^3 т/сез' },
    { value: 449, label: 'т.км' },
    { value: 556, label: '10^3 гол/год' },
    { value: 383, label: 'руб' },
    { value: 970, label: '10^6 пасс. мест. миль' },
    { value: 921, label: 'л. уч.-изд' },
    { value: 894, label: '10^3 усл. кирп' },
    { value: 514, label: 'т.тяги' },
    { value: 388, label: '10^15 руб' },
    { value: 541, label: '10^3 чел.дн' },
    { value: 971, label: 'корм. дн' },
    { value: 953, label: '10 ^3мест.км' },
    { value: 871, label: '10^3 ампул' },
    { value: 385, label: '10^6 руб' },
    { value: 966, label: '10^3 тоннаж. рейс' },
    { value: 911, label: 'коек' },
    { value: 868, label: 'бут' },
    { value: 892, label: '10^3 усл. плит' },
    { value: 544, label: '10^6 ед/год' },
    { value: 793, label: '10^3 чел' },
    { value: 949, label: '10^6 лист.оттиск' },
    { value: 886, label: '10^6 усл. кус' },
    { value: 698, label: 'мест' },
    { value: 536, label: 'т/смен' },
    { value: 548, label: '10^3 пар/смен' },
    { value: 915, label: 'усл. рем' },
    { value: 812, label: 'ящ' },
    { value: 956, label: '10^3 поезд.км' },
    { value: 553, label: '10^3 т перераб/ сут' },
    { value: 450, label: '10^3 т.км' },
    { value: 950, label: 'ваг (маш).дн' },
    { value: 552, label: 'т перераб/сут' },
    { value: 423, label: '10^3 пасс.км' },
    { value: 924, label: 'символ' },
    { value: 782, label: '10^3 упак' },
    { value: 838, label: '10^6 пар' },
    { value: 905, label: '10^3 раб. мест' },
    { value: 887, label: 'усл. ящ' },
    { value: 744, label: '%' },
    { value: 639, label: 'доз' },
    { value: 891, label: 'усл. плит' },
    { value: 545, label: 'посещ/смен' },
    { value: 543, label: '10^3 усл. банк/ смен' },
    { value: 893, label: 'усл. кирп' },
    { value: 957, label: '10^3 т.миль' },
    { value: 977, label: 'канал. км' },
    { value: 901, label: '10^6 домхоз' },
    { value: 976, label: 'штук в 20-футовом эквиваленте' },
    { value: 762, label: 'станц' },
    { value: 897, label: '10^3 семей' },
    { value: 880, label: '10^3 усл. шт' },
    { value: 923, label: 'слово' },
    { value: 955, label: '10^3 поезд.ч' },
    { value: 539, label: 'чел.ч' },
    { value: 661, label: 'канал' },
    { value: 874, label: '10^3 туб' },
    { value: 558, label: '10^3 птицемест' },
    { value: 913, label: 'том книжн. фонд' },
    { value: 673, label: '10^3 компл' },
    { value: 640, label: '10^3 доз' },
    { value: 643, label: '10^3 ед' },
    { value: 878, label: '10^6 усл. ед' },
    { value: 914, label: '10^3 том. книжн. фонд' },
    { value: 883, label: '10^6 усл. банк' },
    { value: 384, label: '10^3 руб' },
    { value: 889, label: 'усл. кат' },
    { value: 925, label: 'усл. труб' },
    { value: 900, label: '10^3 домхоз' },
    { value: 898, label: '10^6 семей' },
    { value: 964, label: 'самолет.км' },
    { value: 979, label: '10^3 экз' },
    { value: 746, label: 'промилле' },
    { value: 890, label: '10^3 усл. кат' },
    { value: 724, label: '10^3 га порц' },
    { value: 542, label: '10^3 чел.ч' },
    { value: 642, label: 'ед' },
    { value: 560, label: 'мин. заработн. плат' },
    { value: 557, label: '10^6 гол/год' },
    { value: 917, label: 'смен' },
    { value: 902, label: 'учен. мест' },
    { value: 521, label: 'чел/м2' },
    { value: 479, label: '10^3 набор' },
    { value: 899, label: 'домхоз' },
    { value: 906, label: 'посад. мест' },
    { value: 982, label: '10^6 корм ед' },
    { value: 515, label: 'дедвейт.т' },
    { value: 959, label: 'автомоб.дн' },
    { value: 972, label: 'ц корм ед' },
    { value: 882, label: '10^3 усл. банк' },
    { value: 969, label: '10^6 тоннаж. миль' },
    { value: 837, label: '10^3 пар' },
    { value: 810, label: 'яч' },
    { value: 516, label: 'т.танид' },
    { value: 794, label: '10^6 чел' },
    { value: 451, label: '10^6 т. км' },
    { value: 836, label: 'гол' },
    { value: 872, label: 'флак' },
    { value: 808, label: '10^6 экз' },
    { value: 561, label: '10^3 т пар/ч' },
    { value: 973, label: '10^3 автомоб. км' },
    { value: 981, label: '10^3 корм ед' },
    { value: 386, label: '10^9 руб' },
    { value: 554, label: 'ц перераб/сут' },
    { value: 885, label: '10^3 усл. кус' },
    { value: 937, label: '10^6 доз' },
    { value: 920, label: 'л. печ' },
    { value: 779, label: '10^6 упак' },
    { value: 709, label: '10^3 ном' },
    { value: 512, label: 'т.ном' },
    { value: 952, label: '10^3 ваг (маш).км' },
    { value: 879, label: 'усл. шт' },
    { value: 904, label: 'раб. мест' },
    { value: 559, label: '10^3 кур. несуш' },
    { value: 840, label: 'секц' },
    { value: 974, label: '10^3 тоннаж. сут' },
    { value: 729, label: '10^3 пач' },
    { value: 910, label: '10^3 кварт' },
    { value: 550, label: '10^6 т/год' },
    { value: 563, label: '10^3 пряд.мест' },
    { value: 875, label: '10^3 кор' },
    { value: 776, label: '10^3 усл.туб' },
    { value: 884, label: 'усл. кус' },
    { value: 930, label: '10^3 пласт' },
    { value: 555, label: '10^3 ц перераб/ сут' },
];

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 6,
        },
    },
};
export const smallFormLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export const tailSmallFormLayout = {
    wrapperCol: {
        xs: {
            span: 16,
            offset: 8,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export const filterType = {
    EQ: 'eq',
    NE: 'ne',
    OVERLAP: 'overlap',
    IN: 'in',
};

export const filterTypeText = {
    [filterType.EQ]: 'Равно',
    [filterType.NE]: 'Не равно',
    [filterType.OVERLAP]: 'Пересечение',
    [filterType.IN]: 'Вхождение',
};

export const goodPropertyType = {
    NUMBER: 'number',
    ENUM: 'enum',
    DICTIONARY: 'dictionary',
    STRING: 'string',
};

export const goodType = {
    SERVICE: 'service',
    COMMODITY: 'commodity',
};

export const goodPropertyTypeText = {
    [goodPropertyType.NUMBER]: 'Число',
    [goodPropertyType.ENUM]: 'Перечень',
    [goodPropertyType.DICTIONARY]: 'Словарь',
    [goodPropertyType.STRING]: 'Строка',
};

export const priceType = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    MIN_COUNT: 'min_count',
};

export const priceTypeText = {
    [priceType.PUBLIC]: 'Публичный',
    [priceType.PRIVATE]: 'Персональный',
    [priceType.MIN_COUNT]: 'От мин. кол-ва',
};

export const goodTypeText = {
    [goodType.COMMODITY]: 'Товар',
    [goodType.SERVICE]: 'Услуга',
};

export interface IPagination {
    current: number;
    pageSize: number;
    total: number;
    hideOnSinglePage: boolean;
}

export interface ISorter {
    order: string;
    field: string;
    columnKey: string;
}
