import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import LegalPriceEdit from './components/LegalPriceEdit';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createLegalPriceLink } from '../../components/Linker/PathCreators';
import { getCrudItem } from '../../store/Crud/selectors';
import { getShopId } from '../../store/System/selectors';
import { resource } from '../../utils/constants';
import { ILegalPrice } from '../../store/Crud/types/legalPrice';

const LegalPriceEditPage = () => {
    const history = useHistory();

    const legalPrice = useSelector(getCrudItem(resource.LEGAL_PRICE));
    const shopId: number = useSelector(getShopId) as any;

    const { id: legalPriceId } = useParams<{ id: string }>();

    const onSave = (item: ILegalPrice) => {
        if (item) {
            history.push(createLegalPriceLink(String(shopId), String(item.id)));
        }
    };

    const getTitle = () => {
        if (legalPriceId === 'new') {
            return 'Новая';
        }
        return legalPrice ? legalPrice?.inn : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.legalPriceList}>Персональные цены</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <LegalPriceEdit shopId={shopId} itemId={legalPriceId} onSave={onSave} />
            </div>
        </div>
    );
};

export default LegalPriceEditPage;
