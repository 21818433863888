import React from 'react';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import ShopListPage from './Main';
import ProfileListPage from './ProfileListPage';
import CategoryListPage from './CategoryListPage';
import CategoryEditPage from './CategoryEditPage';
import GoodListPage from '../legacy/pages/GoodListPage';
import GoodEditPage from '../legacy/pages/GoodEditPage';
import ManagerListPage from './ManagerListPage';
import ManagerEditPage from './ManagerEditPage';
import ShopAddressListPage from './ShopAddressListPage/ShopAddressListPage';
import ShopAddressEditPage from './ShopAddressEditPage';
import OrderListPage from './OrderListPage';
import OrderEditPage from './OrderEditPage';
import ProfileEditPage from './ProfileEditPage';
import PanelPrivateRoute from './Auth/components/PanelPrivateRoute';
import ShopPage from './ShopPage';
import PriceListPage from './PriceListPage';
import PriceEditPage from './PriceEditPage';
import LegalPriceListPage from './LegalPriceListPage';
import LegalPriceEditPage from './LegalPriceEditPage';
import DeliveryIntegrationListPage from './DeliveryIntegrationListPage';
import PaymentIntegrationListPage from './PaymentIntegrationListPage';
import GoodModelListPage from './GoodModelListPage';
import GoodModelEditPage from './GoodModelEditPage';
import GoodShopPropertyListPage from '../legacy/pages/GoodShopPropertyListPage';
import ShopClientListPage from './ShopClientListPage';
import ShopClientEditPage from './ShopClientEditPage';
import ShopSettingPage from './ShopPage/components/ShopSettingPage';
import { createLink } from '../components/Linker/PathCreators';
import { enumTypesLinks } from '../components/Linker/typesLinks';
import Logout from './Logout';
import SettingNotificationPage from './SettingNotificationPage';
import ShopNotificationListPage from './ShopNotificationListPage';
import ShopNotificationEditPage from './ShopNotificationEditPage';
import ClaimIntegrationListPage from './ClaimIntegrationListPage';
import ShopClaimListPage from './ShopClaimListPage';
import ShopClaimEditPage from './ShopClaimEditPage';
import ShopNotificationConnectionEditPage from './ShopNotificationConnectionEditPage';
import ShopMarketplaceSettingEditPage from './ShopMarketplaceSettingEditPage';
import ShopMarketplaceSettingListPage from './ShopMarketplaceSettingListPage';

export const AdminRoute = () => (
    <BrowserRouter>
        <Switch>
            <PanelPrivateRoute isAdmin exact path="/" component={ShopListPage} />
            <PanelPrivateRoute isAdmin exact path={createLink(enumTypesLinks.shopList)} component={ShopListPage} />
            <PanelPrivateRoute isAdmin path={createLink(enumTypesLinks.shop, ':shopId')} component={ShopPage} />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.deliveryIntegrationList)}
                component={DeliveryIntegrationListPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.paymentIntegrationList)}
                component={PaymentIntegrationListPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.claimIntegrationList)}
                component={ClaimIntegrationListPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.notificationIntegrationList)}
                component={SettingNotificationPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.goodModelList)}
                component={GoodModelListPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.goodModel, '1', ':id')}
                component={GoodModelEditPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.profileList)}
                component={ProfileListPage}
            />
            <PanelPrivateRoute
                isAdmin
                exact
                path={createLink(enumTypesLinks.profile, '1', ':id')}
                component={ProfileEditPage}
            />
            <PanelPrivateRoute isAdmin exact path={createLink(enumTypesLinks.logout)} component={Logout} />
            <Redirect to="/" />
        </Switch>
    </BrowserRouter>
);

export const ControlPanelRoute = () => (
    <BrowserRouter>
        <Switch>
            <PanelPrivateRoute exact path="/" component={ShopListPage} />
            <PanelPrivateRoute exact path={createLink(enumTypesLinks.shop, ':shopId')} component={ShopSettingPage} />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.categoryList, ':shopId')}
                component={CategoryListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.category, ':shopId', ':id')}
                component={CategoryEditPage}
            />
            <PanelPrivateRoute exact path={createLink(enumTypesLinks.goodList, ':shopId')} component={GoodListPage} />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.good, ':shopId', ':id')}
                component={GoodEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.managerList, ':shopId')}
                component={ManagerListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.manager, ':shopId', ':id')}
                component={ManagerEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopAddressList, ':shopId')}
                component={ShopAddressListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopAddress, ':shopId', ':id')}
                component={ShopAddressEditPage}
            />
            <PanelPrivateRoute exact path={createLink(enumTypesLinks.orderList, ':shopId')} component={OrderListPage} />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.order, ':shopId', ':id')}
                component={OrderEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.goodShopPropertyList, ':shopId')}
                component={GoodShopPropertyListPage}
            />
            <PanelPrivateRoute exact path={createLink(enumTypesLinks.priceList, ':shopId')} component={PriceListPage} />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.price, ':shopId', ':id')}
                component={PriceEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.legalPriceList, ':shopId')}
                component={LegalPriceListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.legalPrice, ':shopId', ':id')}
                component={LegalPriceEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopClaimList, ':shopId')}
                component={ShopClaimListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopClaim, ':shopId', ':id')}
                component={ShopClaimEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopNotificationList, ':shopId')}
                component={ShopNotificationListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopNotification, ':shopId', ':id')}
                component={ShopNotificationEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopNotificationConnection, ':shopId', ':id')}
                component={ShopNotificationConnectionEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopClientList, ':shopId')}
                component={ShopClientListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopClient, ':shopId', ':id')}
                component={ShopClientEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopModelList, ':shopId')}
                component={GoodModelListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopModel, ':shopId', ':id')}
                component={GoodModelEditPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopMarketplaceSettingList, ':shopId')}
                component={ShopMarketplaceSettingListPage}
            />
            <PanelPrivateRoute
                exact
                path={createLink(enumTypesLinks.shopMarketplaceSetting, ':shopId', ':id')}
                component={ShopMarketplaceSettingEditPage}
            />
            <PanelPrivateRoute exact isPublic path={createLink(enumTypesLinks.logout)} component={Logout} />
            <Redirect to="/" />
        </Switch>
    </BrowserRouter>
);
