import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import Linker from '../../components/Linker';
import LegalPriceList from './components/LegalPriceList';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createLegalPriceLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { ILegalPrice } from '../../store/Crud/types/legalPrice';

const LegalPriceListPage = () => {
    const history = useHistory();

    const shopId: number = useSelector(getShopId) as any;

    const goToItem = (item: ILegalPrice) => {
        history.push(createLegalPriceLink(String(shopId), String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Персональные цены</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.legalPrice} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && <LegalPriceList shopId={shopId} onClick={goToItem} />}
            </div>
        </div>
    );
};

export default LegalPriceListPage;
