import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import ManagerList from './components/ManagerList';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createManagerLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IManager } from '../../store/Crud/types/manager';

const ManagerListPage = () => {
    const history = useHistory();

    const shopId: number = useSelector(getShopId) as any;

    const goToItem = (item: IManager) => {
        history.push(createManagerLink(String(shopId), String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Менеджеры</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.manager} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && <ManagerList shopId={shopId} onClick={goToItem} />}
            </div>
        </div>
    );
};

export default ManagerListPage;
