import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import CategoryEdit from './components/CategoryEdit';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { ICategory } from '../../store/Crud/types';
import { CATEGORY_FIELDS } from '../../store/Crud/types/category';
import { enumTypesLinks } from '../../components/Linker/typesLinks';

const CategoryEditPage = () => {
    const { id: categoryId } = useParams<{ id: string }>();

    const categoryItem: ICategory = useSelector(getCrudItem(resource.CATEGORY)) as any;
    const shopId: string = useSelector(getShopId) as any;

    const getTitle = () => {
        if (categoryId === 'new') {
            return 'Новая';
        }
        return categoryItem ? categoryItem[CATEGORY_FIELDS.title] : '';
    };

    if (Number(shopId) === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.categoryList}>Категории</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <CategoryEdit shopId={shopId} itemId={categoryId} />
            </div>
        </div>
    );
};

export default CategoryEditPage;
