import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import CategoryList from './components/CategoryList';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createCategoryLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { ICategory } from '../../store/Crud/types';

const CategoryListPage = () => {
    const history = useHistory();
    const shopId: string = useSelector(getShopId) as any;

    const goToItem = (item: ICategory) => {
        history.push(createCategoryLink(shopId, String(item.id)));
    };
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Категории</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.category} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && <CategoryList shopId={shopId} onClick={goToItem} />}
            </div>
        </div>
    );
};

export default CategoryListPage;
