import React, { FC } from 'react';
import { Form } from 'antd';
import { TFormItemProps } from './types';
import TagsFormInput from './TagsFormInput';

type TProps = {
    formItemProps: TFormItemProps;
    required?: boolean;
    disabled?: boolean;
};

const TagsFormField: FC<TProps> = ({ formItemProps, required, disabled }) => (
    <Form.Item rules={[{ required }]} {...formItemProps} style={{ position: 'relative' }}>
        <TagsFormInput disabled={disabled} />
    </Form.Item>
);

export default TagsFormField;
