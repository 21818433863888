import React, { FC, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Space, Table } from 'antd';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import ShopAddress from './ShopAddress';
import Api from '../../../legacy/actions/Api';
import OrderDetail from './OrderDetail';
import ShopClientDetail from './ShopClientDetail';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { get } from '../../../store/Crud';
import { IOrder } from '../../../store/Crud/types/order';
import { TCart } from '../../../store/Crud/types/cart';

interface IOrderEdit {
    shopId: number;
    itemId: string;
}

const OrderEdit: FC<IOrderEdit> = ({ shopId, itemId }) => {
    const dispatch = useAppDispatch();

    const [order, setOrder] = useState<IOrder>();
    const [cart, setCart] = useState<TCart>();

    const fetchOrder = useCallback(async () => {
        const { payload } = await dispatch(
            get(resource.ORDER, itemId, {
                shopId,
                _extend: [resource.CART, resource.SHOP_CLIENT],
            })(),
        );
        if (payload?.data) {
            setOrder(payload.data[0]);
            setCart(Api.extractExtendDataFirstItem(payload.extendedData, resource.CART));
        }
    }, [dispatch, itemId, shopId]);

    useEffect(() => {
        fetchOrder();
    }, [fetchOrder]);

    const columns = [
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
            width: '100px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '20%',
        },
        {
            title: 'Склад',
            dataIndex: 'shopAddressId',
            key: 'shopAddressId',
            width: '20%',
            render: (shopAddressId: number) => <ShopAddress shopAddressId={shopAddressId} />,
        },
        {
            title: t(enumFieldName.price),
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: t(enumFieldName.count),
            dataIndex: 'count',
            key: 'count',
            render: (count: number, item: { unit: string }) => (
                <div>
                    {count} {item.unit}.
                </div>
            ),
        },
        {
            title: t(enumFieldName.price),
            dataIndex: 'price',
            key: 'total_price',
            render: (price: number, item: any) => <div>{price * item.count}</div>,
        },
    ];

    if (_.isEmpty(order)) {
        return <Loading />;
    }

    const getGoodCount = (goodId: number) => cart?.items?.find((cartItem) => cartItem.goodId === goodId)?.count;

    const cartGoods =
        cart?.goods.map((good, index) => ({
            ...good,
            count: getGoodCount(good.id),
            keyTable: index,
        })) || [];

    const cartAdditionalItems =
        cart?.additionalItems?.map((additionalItem, index) => ({
            ...additionalItem,
            title: additionalItem.description,
            unit: 'шт',
            keyTable: `${index}/`,
        })) || [];

    const dataSource = [...cartGoods, ...cartAdditionalItems];

    if (order) {
        return (
            <div>
                <Space style={{ alignItems: 'flex-start' }}>
                    <OrderDetail fetchOrder={fetchOrder} order={order} />
                    <ShopClientDetail id={order.shopClientId} />
                </Space>
                <Table dataSource={dataSource} rowKey="keyTable" columns={columns} />
            </div>
        );
    }

    return <Loading />;
};

export default OrderEdit;
