import React, { FC } from 'react';

interface IAuthError {
    errorCode: string | null;
}

const AuthError: FC<IAuthError> = ({ errorCode }) => {
    const getTextError = () => {
        switch (errorCode) {
            case 'wrong_phone_number_error':
                return 'Неверный формат номера телефона';
            case 'code_attempt_error':
                return 'Ошибка запроса кода подтверждения, попробуйте позже';
            case 'profile_already_exist_error':
                return 'Вы уже зарегистрированы в системе';
            case 'profile_not_found':
                return 'Вы еще не зарегистрированы в системе';
            case 'already_authorized_error':
                return 'Вы уже авторизованы в системе';
            case 'bad_code':
            case 'code_not_found':
                return 'Неверный код';
            case 'code_expired':
                return 'Код устарел, запросите новый';
            case 'wrong_password_error':
                return 'Неверный логин или пароль';
            default:
                if (errorCode) {
                    return 'Неизвестная ошибка';
                }
        }
    };

    const errorText = getTextError();

    return <span className="error">{errorText}</span>;
};

export default AuthError;
