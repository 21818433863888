import withQuery from 'with-query';
import { baseApiUrl } from '../../utils/http';
import { headers } from './Api';
import { showError } from '../../utils/helpers';

export const find = (resource, params) => {
    const url = baseApiUrl + resource;
    return fetch(withQuery(url, params), {
        method: 'GET',
        credentials: 'include',
        headers,
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                showError(json.error);
                throw new Error(json.error);
            }
            return [json.data, json.metaData, json.extendedData ?? []];
        })
        .catch((err) => {
            showError(err);
            throw err;
        });
};
