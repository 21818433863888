import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createLink } from './PathCreators';
import { getShopId } from '../../store/System/selectors';
import { enumTypesLinks } from './typesLinks';

interface ILinker {
    type: enumTypesLinks;
    itemId?: string;
    parentId?: string;
    children?: React.ReactNode;
}

const Linker: FC<ILinker> = ({ children, type, itemId, parentId }) => {
    const shopId: string = useSelector(getShopId) as any;

    return <Link to={createLink(type, shopId, itemId, parentId)}>{children}</Link>;
};

export default Linker;
