export const crudActions = {
    LOADING_LIST: 'LOADING_LIST',
    LOADING_ITEM: 'LOADING_ITEM',
    WAIT_UPDATE: 'WAIT_UPDATE',
    WAIT_CREATE: 'WAIT_CREATE',
    ITEM_ERROR: 'ITEM_ERROR',
    SET_LIST: 'SET_LIST',
    SET_DICTIONARY: 'SET_DICTIONARY',
    SET_ITEM: 'SET_ITEM',
};
