import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
import { getCrudItem } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { get } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import FormShopMarketplaceSetting from './FormShopMarketplaceSetting';

type TProps = {
    shopId: string;
    itemId: string;
};

const ShopMarketplaceSettingEdit: FC<TProps> = ({ shopId, itemId }) => {
    const dispatch = useAppDispatch();
    const shopMarketplaceSetting = useSelector(getCrudItem(resource.SHOP_MARKETPLACE_SETTING));

    useEffect(() => {
        if (itemId !== 'new') {
            dispatch(get(resource.SHOP_MARKETPLACE_SETTING, itemId)());
        }
    }, [dispatch, itemId]);

    const defaultValues = {
        marketplaceShopId: Number(shopId),
        participantShopAlias: '',
        status: '',
    };

    if (Number(shopId) === 0 || (itemId === 'new' ? false : String(shopMarketplaceSetting?.id) !== itemId)) {
        return <Loading />;
    }

    const isDetailPage = String(shopMarketplaceSetting?.id) === itemId;
    const initialValues = isDetailPage ? shopMarketplaceSetting : defaultValues;

    return (
        <FormShopMarketplaceSetting
            shopId={shopId}
            itemId={itemId}
            isDetailPage={isDetailPage}
            initialValues={initialValues}
        />
    );
};

export default ShopMarketplaceSettingEdit;
