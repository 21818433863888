import { Select, Tag, Tooltip } from 'antd';
import React from 'react';

export const ImportedPropertyContainer = ({ values, internalId, allValues, onDelete, onCreate }) => {
    if (!allValues) {
        return 'Нет значений в выгрузке';
    }
    if (!values) {
        values = [];
    }

    const currentValues = values.filter((elem) => elem.internalId === internalId);

    const onChange = (value) => {
        if (onCreate) {
            onCreate({ internalId, value });
        }
    };

    const selectedExternalItems = values.map((elem) => elem.value);
    const allowedItems = allValues.filter((elem) => !selectedExternalItems.includes(elem.id));

    return (
        <div>
            <div>
                {currentValues.map((elem, index) => (
                    <Tooltip key={elem.internalId + elem.value + index} title={elem.value}>
                        <Tag closable onClose={() => onDelete(elem)}>
                            {allValues.find((dicElem) => dicElem.id === elem.value)?.value}
                        </Tag>
                    </Tooltip>
                ))}
            </div>
            <Select
                onSelect={onChange}
                placeholder="Добавить связку"
                value={null}
                size="small"
                style={{ width: '200px' }}
            >
                {allowedItems.map((elem) => (
                    <Select.Option key={elem.id} value={elem.id}>
                        {elem.value}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};
