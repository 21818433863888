export enum enumFieldName {
    claimIntegrationId = 'claimIntegrationId',
    participantShopId = 'participantShopId',
    title = 'title',
    alias = 'alias',
    minOrderSum = 'minOrderSum',
    notificationEmail = 'notificationEmail',
    shopUrl = 'shopUrl',
    token = 'token',
    invoiceBoxPartnerId = 'invoiceBoxPartnerId',
    invoiceBoxRegionId = 'invoiceBoxRegionId',
    invoiceBoxSecretKey = 'invoiceBoxSecretKey',
    invoiceBoxCurrency = 'invoiceBoxCurrency',
    orderLifetime = 'orderLifetime',
    invoiceBoxAuthKey = 'invoiceBoxAuthKey',
    stockMode = 'stockMode',
    priceMode = 'priceMode',
    id = 'id',
    phone = 'phone',
    fio = 'fio',
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    public = 'public',
    filterTypes = 'filterTypes',
    groupName = 'groupName',
    filled = 'filled',
    multiple = 'multiple',
    profileId = 'profileId',
    profile = 'profile',
    shopId = 'shopId',
    deleted = 'deleted',
    description = 'description',
    lat = 'lat',
    lon = 'lon',
    pickupPoint = 'pickupPoint',
    externalId = 'externalId',
    parentId = 'parentId',
    tags = 'tags',
    parentModelId = 'parentModelId',
    imageId = 'imageId',
    updatedAt = 'updatedAt',
    goodCount = 'goodCount',
    path = 'path',
    categoryId = 'categoryId',
    categoryIds = 'categoryIds',
    modelId = 'modelId',
    modelPropertyId = 'modelPropertyId',
    values = 'values',
    stock = 'stock',
    active = 'active',
    isActive = 'isActive',
    setting = 'setting',
    amount = 'amount',
    paymentIntegrationId = 'paymentIntegrationId',
    deliveryIntegrationId = 'deliveryIntegrationId',
    unit = 'unit',
    unitCode = 'unitCode',
    code = 'code',
    system = 'system',
    imagePath = 'imagePath',
    nds = 'nds',
    price = 'price',
    legalName = 'legalName',
    inn = 'inn',
    phones = 'phones',
    prices = 'prices',
    createdAt = 'createdAt',
    sum = 'sum',
    documents = 'documents',
    status = 'status',
    admin = 'admin',
    count = 'count',
    mode = 'mode',
    invoiceBoxTestMode = 'invoiceBoxTestMode',
    yandexMetrikaId = 'yandexMetrikaId',
    jivositeId = 'jivositeId',
    orderMinCount = 'orderMinCount',
    weight = 'weight',
    deliveryInfo = 'deliveryInfo',
    type = 'type',
    aliasIds = 'aliasIds',
    priceIds = 'priceIds',
    defaultPriceId = 'defaultPriceId',
    data = 'data',
    fields = 'fields',
    shopAddressId = 'shopAddressId',
    paymentTypes = 'paymentTypes',
    legalPaymentTypes = 'legalPaymentTypes',
    deliveryTypes = 'deliveryTypes',
    position = 'position',
    seoTitle = 'seoTitle',
    seoDescription = 'seoDescription',
    thresholdFreeAmount = 'thresholdFreeAmount',
    fixedAmount = 'fixedAmount',
    deliveryTypeId = 'deliveryTypeId',
    modelValue = 'modelValue',
    importedValues = 'importedValues',
    password = 'password',
    notificationIntegrationId = 'notificationIntegrationId',
    paidStatus = 'paidStatus',
    defermentAllowed = 'defermentAllowed',
    remove = 'remove',
    url = 'url',
    modelIds = 'modelIds',
    brandId = 'brandId',
    address = 'address',
    hours = 'hours',
    seoKeywords = 'seoKeywords',
}

const fieldName = {
    [enumFieldName.modelIds]: 'Модели товаров',
    [enumFieldName.url]: 'Ссылка на скачивание',
    [enumFieldName.remove]: 'Удалить',
    [enumFieldName.title]: 'Название',
    [enumFieldName.alias]: 'Алиас',
    [enumFieldName.minOrderSum]: 'Минимальная сумма заказа',
    [enumFieldName.address]: 'Адрес',
    [enumFieldName.notificationEmail]: 'Email для нотификаций',
    [enumFieldName.shopUrl]: 'Ссылка на магазин',
    [enumFieldName.token]: 'Интеграционный токен',
    [enumFieldName.invoiceBoxPartnerId]: '',
    [enumFieldName.invoiceBoxRegionId]: '',
    [enumFieldName.invoiceBoxSecretKey]: '',
    [enumFieldName.invoiceBoxCurrency]: '',
    [enumFieldName.orderLifetime]: 'Время жизни заказа',
    [enumFieldName.invoiceBoxAuthKey]: '',
    [enumFieldName.stockMode]: 'Режим наличия товаров',
    [enumFieldName.priceMode]: 'Режим отображения цен',
    [enumFieldName.id]: 'ID',
    [enumFieldName.phone]: 'Телефон',
    [enumFieldName.fio]: 'ФИО',
    [enumFieldName.firstName]: 'Имя',
    [enumFieldName.lastName]: 'Фамилия',
    [enumFieldName.email]: 'Email',
    [enumFieldName.public]: 'Отображать на сайте',
    [enumFieldName.filterTypes]: 'Допустимые фильтры',
    [enumFieldName.groupName]: 'Группа',
    [enumFieldName.filled]: 'Заполнен',
    [enumFieldName.multiple]: 'Множественное',
    [enumFieldName.profileId]: 'ID пользователя',
    [enumFieldName.profile]: 'Профиль',
    [enumFieldName.shopId]: 'ID магазина',
    [enumFieldName.deleted]: 'Удален',
    [enumFieldName.description]: 'Описание',
    [enumFieldName.lat]: 'Широта',
    [enumFieldName.lon]: 'Долгота',
    [enumFieldName.pickupPoint]: 'Точка самовывоза',
    [enumFieldName.externalId]: 'Арт.',
    [enumFieldName.parentId]: 'Родительская категория',
    [enumFieldName.parentModelId]: 'Родительская модель',
    [enumFieldName.imageId]: 'ID изображения',
    [enumFieldName.updatedAt]: 'Дата изменения',
    [enumFieldName.goodCount]: 'Кол-во товаров',
    [enumFieldName.path]: 'Путь',
    [enumFieldName.categoryId]: 'Главная категория',
    [enumFieldName.categoryIds]: 'Категории',
    [enumFieldName.modelId]: 'Модель товара',
    [enumFieldName.modelPropertyId]: 'Свойство модели',
    [enumFieldName.values]: 'Значения',
    [enumFieldName.stock]: 'Наличие',
    [enumFieldName.active]: 'Показывать на сайте',
    [enumFieldName.isActive]: 'Активно',
    [enumFieldName.tags]: 'Теги',
    [enumFieldName.setting]: 'Настройки',
    [enumFieldName.amount]: 'Стоимость',
    [enumFieldName.paymentIntegrationId]: 'Тип',
    [enumFieldName.deliveryIntegrationId]: 'Тип',
    [enumFieldName.notificationIntegrationId]: 'Тип',
    [enumFieldName.unit]: 'Ед.изм.',
    [enumFieldName.unitCode]: 'Ед. измерения',
    [enumFieldName.code]: 'Код',
    [enumFieldName.system]: 'Системный',
    [enumFieldName.imagePath]: 'Фото',
    [enumFieldName.nds]: 'НДС',
    [enumFieldName.price]: 'Цена',
    [enumFieldName.legalName]: 'Юр. лицо',
    [enumFieldName.inn]: 'ИНН',
    [enumFieldName.phones]: 'Номера телефонов',
    [enumFieldName.prices]: 'Цены',
    [enumFieldName.createdAt]: 'Дата создания',
    [enumFieldName.sum]: 'Сумма',
    [enumFieldName.documents]: 'Документы',
    [enumFieldName.status]: 'Статус',
    [enumFieldName.paidStatus]: 'Статус оплаты',
    [enumFieldName.admin]: 'Админ',
    [enumFieldName.count]: 'Кол-во',
    [enumFieldName.mode]: 'Заказы',
    [enumFieldName.invoiceBoxTestMode]: 'Тестовый режим InvoiceBox',
    [enumFieldName.yandexMetrikaId]: 'Яндекс Метрика',
    [enumFieldName.jivositeId]: 'Jivosite',
    [enumFieldName.orderMinCount]: 'Мин. кол-во в заказе',
    [enumFieldName.weight]: 'Вес товара в кг',
    [enumFieldName.deliveryInfo]: 'Условие доставки',
    [enumFieldName.type]: 'Тип',
    [enumFieldName.aliasIds]: 'Категории псевдонимы',
    [enumFieldName.priceIds]: 'Прайс-листы',
    [enumFieldName.defaultPriceId]: 'Основной прайс-лист',
    [enumFieldName.data]: 'Данные',
    [enumFieldName.fields]: 'Поля',
    [enumFieldName.shopAddressId]: 'Торговая точка',
    [enumFieldName.paymentTypes]: 'Способы оплаты',
    [enumFieldName.legalPaymentTypes]: 'Способы оплаты для юр. лиц',
    [enumFieldName.deliveryTypes]: 'Способы доставки',
    [enumFieldName.position]: 'Сортировка',
    [enumFieldName.seoTitle]: 'SEO заголовок',
    [enumFieldName.seoDescription]: 'SEO описание',
    [enumFieldName.seoKeywords]: 'SEO ключевые слова',
    [enumFieldName.thresholdFreeAmount]: 'Сумма заказа для бесплатной доставки',
    [enumFieldName.fixedAmount]: 'Стоимость доставки',
    [enumFieldName.deliveryTypeId]: 'Тип доставки',
    [enumFieldName.modelValue]: 'Значение модели',
    [enumFieldName.importedValues]: 'Значение из выгрузки',
    [enumFieldName.password]: 'Пароль',
    [enumFieldName.defermentAllowed]: 'Разрешена отсрочка',
    [enumFieldName.claimIntegrationId]: 'Заявка',
    [enumFieldName.brandId]: 'Бренд',
    [enumFieldName.participantShopId]: 'Магазин-участник',
    [enumFieldName.hours]: 'Часы работы',
};
export const t = (field: enumFieldName) => fieldName?.[field] || field;
