import React, { FC, useState } from 'react';
import { Layout } from 'antd';
import 'antd/dist/antd.css';

const { Content, Footer, Sider } = Layout;

interface IAppLayout {
    menu: React.ReactNode;
    children?: React.ReactNode;
}

const AppLayout: FC<IAppLayout> = ({ children, menu }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const onCollapse = (collapsed: boolean) => {
        setIsCollapsed(collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={240} collapsible collapsed={isCollapsed} onCollapse={onCollapse}>
                {menu}
            </Sider>
            <Layout>
                <Content style={{ margin: '0' }}>{children}</Content>
                <Footer style={{ textAlign: 'center' }}>ExpressClient ©{new Date().getFullYear()}</Footer>
            </Layout>
        </Layout>
    );
};

export default AppLayout;
