import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import createCrudReducer from '../legacy/reducers/crud';
import auth from './Auth/index';
import system from './System/index';
import fileGeneral from './File/index';
import { enumResources, resource } from '../utils/constants';
import stateGenerator from './Crud';

const store = configureStore({
    // stateGenerator is redux-toolkit creator
    // createCrudReducer is classic redux creator
    reducer: {
        [enumResources.CODE]: stateGenerator(resource.CODE),
        [enumResources.SHOP]: stateGenerator(resource.SHOP),
        [enumResources.NOTIFICATION_INTEGRATION]: stateGenerator(resource.NOTIFICATION_INTEGRATION),
        [enumResources.SHOP_NOTIFICATION]: stateGenerator(resource.SHOP_NOTIFICATION),
        [enumResources.CATEGORY]: stateGenerator(resource.CATEGORY),
        [enumResources.CLAIM_INTEGRATION]: stateGenerator(resource.CLAIM_INTEGRATION),
        goods: createCrudReducer(resource.GOOD),
        [enumResources.MANAGER]: stateGenerator(resource.MANAGER),
        [enumResources.ORDER]: stateGenerator(resource.ORDER),
        [enumResources.PROFILE]: stateGenerator(resource.PROFILE),
        [enumResources.SHOP_ADDRESS]: stateGenerator(resource.SHOP_ADDRESS),
        [enumResources.API_USER]: stateGenerator(resource.API_USER),
        [enumResources.LEGAL_PRICE]: stateGenerator(resource.LEGAL_PRICE),
        [enumResources.PRICE]: stateGenerator(resource.PRICE),
        [enumResources.DICTIONARY]: stateGenerator(resource.DICTIONARY),
        [enumResources.DICTIONARY_ITEM]: stateGenerator(resource.DICTIONARY_ITEM),
        [enumResources.DELIVERY_INTEGRATION]: stateGenerator(resource.DELIVERY_INTEGRATION),
        [enumResources.PAYMENT_INTEGRATION]: stateGenerator(resource.PAYMENT_INTEGRATION),
        [enumResources.MODEL]: stateGenerator(resource.MODEL),
        goodShopProperty: createCrudReducer(resource.GOOD_SHOP_PROPERTY),
        [enumResources.MODEL_PROPERTY]: stateGenerator(resource.MODEL_PROPERTY),
        [enumResources.SHOP_DELIVERY]: stateGenerator(resource.SHOP_DELIVERY),
        [enumResources.SHOP_PAYMENT]: stateGenerator(resource.SHOP_PAYMENT),
        [enumResources.SHOP_CLAIM]: stateGenerator(resource.SHOP_CLAIM),
        [enumResources.FILE]: stateGenerator(resource.FILE),
        [enumResources.CART]: stateGenerator(resource.CART),
        [enumResources.SHOP_NOTIFICATION_CONNECTION]: stateGenerator(resource.SHOP_NOTIFICATION_CONNECTION),
        [enumResources.SHOP_MARKETPLACE_SETTING]: stateGenerator(resource.SHOP_MARKETPLACE_SETTING),
        auth,
        system,
        fileGeneral,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
