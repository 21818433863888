import React, { FC } from 'react';
import ItemEdit from '../../../components/ItemEdit';
import { goodPropertyType, resource } from '../../../utils/constants';
import { IGoodProperty } from '../../../store/Crud/types/modelProperty';
import { update, create } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';

interface IGoodPropertyEdit {
    item: IGoodProperty;
    actionAfterSubmit: (arg: IGoodProperty) => void;
}

const GoodPropertyEdit: FC<IGoodPropertyEdit> = ({ item, actionAfterSubmit }) => {
    const dispatch = useAppDispatch();

    const fields = {
        title: '',
        groupName: '',
        type: '',
        multiple: false,
        public: false,
        filterTypes: '',
        externalId: '',
    };

    const requiredFields = ['title', 'public', 'multiple', 'externalId', 'type'];

    const hiddenFields = item.type !== goodPropertyType.DICTIONARY ? ['values'] : [];

    const onSave = async (savedItem: IGoodProperty) => {
        if (savedItem.id) {
            await dispatch(update(resource.MODEL_PROPERTY, String(savedItem.id), savedItem)());
            actionAfterSubmit(savedItem);
        } else {
            const { payload } = await dispatch(create(resource.MODEL_PROPERTY, savedItem)());
            if (payload?.data?.id) {
                actionAfterSubmit(payload?.data);
            }
        }
    };

    const additionalFields = () => {
        if (!item.id || item.type === goodPropertyType.DICTIONARY) {
            return { values: [] };
        }

        return {};
    };

    return (
        <ItemEdit
            subjectName="goodModel"
            item={item}
            fields={{ ...fields, ...additionalFields() }}
            onSave={onSave}
            requiredFields={requiredFields}
            hiddenFields={hiddenFields}
        />
    );
};

export default GoodPropertyEdit;
