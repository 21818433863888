import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import OrderList from './components/OrderList';
import { createOrderLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IOrder } from '../../store/Crud/types/order';

const OrderListPage = () => {
    const history = useHistory();
    const shopId: string = useSelector(getShopId) as any;

    const goToItem = (item: IOrder) => {
        history.push(createOrderLink(shopId, String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Заказы</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">{shopId && <OrderList shopId={shopId} onClick={goToItem} />}</div>
        </div>
    );
};

export default OrderListPage;
