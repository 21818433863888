import { RootState } from '../index';
import { enumResources } from '../../utils/constants';

export const getCrudItem = (resource: enumResources) => (state: RootState) => state[resource].item;

export const getCrudItems = (resource: enumResources) => (state: RootState) => state[resource].items;

export const getCrudItemById = (resource: enumResources) => (id?: number) => (state: RootState) =>
    id && getCrudItems(resource)(state).find((item) => item?.id === id);

export const getCrudItemTitle = (resource: enumResources) => (state: RootState) => getCrudItem(resource)(state)?.title;

export const getCrudError = (resource: enumResources) => (state: RootState) => state[resource].error;

export const getDictionary = (resource: enumResources) => (state: RootState) => state[resource].dictionary;

export const isCrudWait = (resource: enumResources) => (state: RootState) =>
    state[resource].awaitCreateItem || state[resource].awaitUpdateItem;

export const getCrudItemsByShopId = (resource: enumResources) => (shopId: number) => (state: RootState) =>
    getCrudItems(resource)(state)?.filter((crudItem) => crudItem?.shopId === shopId);
