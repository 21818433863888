import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import Linker from '../../components/Linker';
import GoodModelEdit from './components/GoodModelEdit';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';

interface IGoodModelEditPage {
    isAdmin?: boolean;
    isShopRoute?: boolean;
}

const GoodModelEditPage: FC<IGoodModelEditPage> = ({ isAdmin, isShopRoute }) => {
    const goodModel = useSelector(getCrudItem(resource.MODEL));
    const { id: modelId } = useParams<{ id: string }>();
    const isShopModelEditPage = !isAdmin || isShopRoute;

    const getTitle = () => {
        if (modelId === 'new') {
            return 'Новая';
        }
        return goodModel ? goodModel?.title : '';
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={isShopModelEditPage ? enumTypesLinks.shopModelList : enumTypesLinks.goodModelList}>
                        Модели товаров
                    </Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <GoodModelEdit itemId={modelId} isShopModelEditPage={isShopModelEditPage} />
            </div>
        </div>
    );
};

export default GoodModelEditPage;
