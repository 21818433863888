import { ICustomer } from './client';

export enum MANAGER_FIELDS {
    id = 'id',
    fio = 'fio',
    email = 'email',
    phone = 'phone',
    public = 'public',
    customerId = 'customerId',
    customer = 'customer',
    shopId = 'shopId',
    deleted = 'deleted',
}

export interface IManager {
    [MANAGER_FIELDS.id]: number;
    [MANAGER_FIELDS.fio]: string;
    [MANAGER_FIELDS.email]: string;
    [MANAGER_FIELDS.phone]: string;
    [MANAGER_FIELDS.public]: boolean;
    [MANAGER_FIELDS.customerId]: number;
    [MANAGER_FIELDS.customer]: ICustomer;
    [MANAGER_FIELDS.shopId]: number;
    [MANAGER_FIELDS.deleted]: boolean;
}
