import React from 'react';
import { Button, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import md5 from 'crypto-js/md5';
import AuthError from './AuthError';
import { validation } from '../validation';
import { useAppDispatch } from '../../../store';
import { getCurrentUserData } from '../../../store/Auth/selectors';
import { setPassword } from '../../../store/Auth';
import { smallFormLayout } from '../../../utils/constants';

const PasswordSetCard = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const user = useSelector(getCurrentUserData);
    const waitResponse = user?.waitResponse;

    const submit = async ({ password }: { password: string }) => {
        dispatch(setPassword(md5(password).toString()));
    };

    const compare = (rule: any, value: string, callback: (arg?: string) => void) => {
        if (value !== form.getFieldValue('password')) {
            callback('Пароли не совпадают');
        }
        callback();
    };

    return (
        <div className="auth-form">
            <h2>Создание пароля</h2>
            <div className="row">
                <AuthError errorCode={user.authError} />
            </div>

            <Form onFinish={submit} form={form}>
                <Form.Item name="password" label="Новый пароль" {...smallFormLayout} rules={[validation.require]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="password2"
                    label="Еще разок"
                    {...smallFormLayout}
                    rules={[validation.require, { validator: compare }]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    icon={waitResponse ? <LoadingOutlined /> : null}
                    disabled={waitResponse}
                    className="login-btn"
                    htmlType="submit"
                >
                    Сохранить
                </Button>
            </Form>
        </div>
    );
};

export default PasswordSetCard;
