import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IManager } from '../../../store/Crud/types/manager';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';

interface IManagerList {
    shopId: number;
    onClick: (manager: IManager) => void;
}

const ManagerList: FC<IManagerList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const managers = useSelector(getCrudItems(resource.MANAGER));

    useEffect(() => {
        dispatch(find(resource.MANAGER, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.fio),
            dataIndex: 'fio',
            key: 'fio',
            width: '25%',
        },
        {
            title: t(enumFieldName.phone),
            dataIndex: 'phone',
            key: 'phone',
            width: '25%',
        },
        {
            title: t(enumFieldName.email),
            dataIndex: 'email',
            key: 'email',
            width: '30%',
        },
        {
            title: t(enumFieldName.public),
            dataIndex: 'public',
            key: 'public',
            width: '10%',
            render: (item: boolean) => (item ? 'Да' : 'Нет'),
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={managers}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ManagerList;
