import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserData } from '../store/Auth/selectors';
import { checkSession } from '../store/Auth';
import { useAppDispatch } from '../store';
import Loading from '../components/Loading';
import { NotAuthRoutes } from './NotAuthRoutes';
import { AdminRoute, ControlPanelRoute } from './AuthRoutes';
import { isCPAppType } from '../utils/http';
import PasswordSetCard from './Auth/components/PasswordSetCard';

const Pages = () => {
    const user = useSelector(getCurrentUserData);
    const dispatch = useAppDispatch();

    const getAuthRoutes = () => {
        if (user.neededPasswordUpdate) {
            return <PasswordSetCard />;
        }
        if (isCPAppType) {
            return <ControlPanelRoute />;
        }
        return <AdminRoute />;
    };

    const getContentToUser = () => {
        switch (user.userId) {
            case null: {
                dispatch(checkSession());
                return <Loading />;
            }
            case 0: {
                return <NotAuthRoutes />;
            }
            default: {
                return getAuthRoutes();
            }
        }
    };

    return <>{getContentToUser()}</>;
};

export default Pages;
