import { orderPaid, enumOrderStatus } from './payment';

export enum ORDER_FIELDS {
    amount = 'amount',
    cartId = 'cartId',
    contactEmail = 'contactEmail',
    contactFio = 'contactFio',
    contactPhone = 'contactPhone',
    createdAt = 'createdAt',
    customer = 'customer',
    customerId = 'customerId',
    description = 'description',
    id = 'id',
    shopClientId = 'shopClientId',
    shopId = 'shopId',
    shopPaymentId = 'shopPaymentId',
    status = 'status',
    type = 'type',
    uuid = 'uuid',
    deliveryAddress = 'deliveryAddress',
    shopAddress = 'shopAddress',
    deliveryZone = 'deliveryZone',
    deliveryDate = 'deliveryDate',
    defermentAllowed = 'defermentAllowed',
    paidStatus = 'paidStatus',
}

interface ILocation {
    title: string;
}

export interface IOrder {
    [ORDER_FIELDS.amount]: number;
    [ORDER_FIELDS.cartId]: number;
    [ORDER_FIELDS.contactEmail]: string;
    [ORDER_FIELDS.contactFio]: string;
    [ORDER_FIELDS.contactPhone]: string;
    [ORDER_FIELDS.createdAt]: string;
    [ORDER_FIELDS.customer]: number;
    [ORDER_FIELDS.customerId]: number;
    [ORDER_FIELDS.description]: string;
    [ORDER_FIELDS.id]: number;
    [ORDER_FIELDS.shopClientId]: number;
    [ORDER_FIELDS.shopId]: number;
    [ORDER_FIELDS.shopPaymentId]: number;
    [ORDER_FIELDS.status]: enumOrderStatus | number;
    [ORDER_FIELDS.type]: string;
    [ORDER_FIELDS.uuid]: string;
    [ORDER_FIELDS.deliveryAddress]: ILocation;
    [ORDER_FIELDS.shopAddress]: ILocation;
    [ORDER_FIELDS.deliveryZone]: ILocation;
    [ORDER_FIELDS.deliveryDate]: string;
    [ORDER_FIELDS.defermentAllowed]: boolean;
    [ORDER_FIELDS.paidStatus]: orderPaid;
}
