import dayjs, { Dayjs } from 'dayjs';
import { DAY_WEEK_LABEL, TPointWorkTime, TWeekDay, TWorkHours } from '../store/Crud/types/shop';

class WorkHoursLogic {
    private compareArray(arrayOne: [string, string], arrayTwo: [string, string]) {
        return (
            arrayOne.length === arrayTwo.length && arrayOne.every((arrayItem, index) => arrayItem === arrayTwo[index])
        );
    }

    putTogetherTimetable(workHours?: TWorkHours) {
        if (!workHours) {
            return [];
        }

        let startPointDay: TWeekDay | null = null;
        let endPointDay: TWeekDay | null = null;
        let time: null | [string, string] = null;

        const array = Object.entries(workHours).map((key) => key) as [TWeekDay, [string, string][]][];
        const resultArray: TPointWorkTime[] = [];

        for (let i = 0; i < array.length; i++) {
            const [currentDay] = array[i];
            const [, times] = array[i];
            const [currentTime] = times;
            const isLastItem = i === array.length - 1;
            if (currentTime && currentTime.length > 0) {
                if (startPointDay === null) {
                    startPointDay = currentDay;
                    endPointDay = currentDay;
                    time = currentTime;
                    if (isLastItem) {
                        resultArray.push({ startPointDay, endPointDay, time });
                    }
                } else if (time && this.compareArray(currentTime, time)) {
                    endPointDay = currentDay;
                    if (isLastItem) {
                        resultArray.push({ startPointDay, endPointDay, time });
                    }
                } else if (endPointDay && time) {
                    resultArray.push({ startPointDay, endPointDay, time });
                    startPointDay = currentDay;
                    endPointDay = currentDay;
                    time = currentTime;
                    if (isLastItem) {
                        resultArray.push({ startPointDay, endPointDay, time });
                    }
                }
            } else if (startPointDay != null && endPointDay && time) {
                resultArray.push({ startPointDay, endPointDay, time });
                startPointDay = null;
                endPointDay = null;
                time = null;
            }
        }

        return resultArray;
    }

    getFromDayJsHoursAndMinutesString(date: Dayjs) {
        const hour = date.get('hour').toString();
        const minutes = date.get('minute').toString();

        return `${hour.length === 1 ? `0${hour}` : hour}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
    }

    getHoursAndMinutesFromString(time: string) {
        const splitTime = time.split(':');
        return [Number(splitTime[0]), Number(splitTime[1])];
    }

    getInitialFormInputValue(value?: TWorkHours) {
        return value
            ? Object.fromEntries(
                  Object.entries(value).map((i) => {
                      if (!i[1]?.[0]?.[0] || !i[1]?.[0]?.[1]) {
                          return [[i[0], undefined]];
                      }

                      const [hourStart, minuteStart] = workHoursLogic.getHoursAndMinutesFromString(i[1][0][0]);
                      const [hourEnd, minuteEnd] = workHoursLogic.getHoursAndMinutesFromString(i[1][0][1]);

                      return [
                          i[0],
                          [
                              dayjs().set('hour', hourStart).set('minute', minuteStart),
                              dayjs().set('hour', hourEnd).set('minute', minuteEnd),
                          ],
                      ];
                  }),
              )
            : undefined;
    }

    getShortDayAndTimeInterval(dayWeekInterval: TPointWorkTime) {
        const dayName =
            dayWeekInterval.startPointDay === dayWeekInterval.endPointDay
                ? DAY_WEEK_LABEL[dayWeekInterval.startPointDay].short
                : `${DAY_WEEK_LABEL[dayWeekInterval.startPointDay].short}-${
                      DAY_WEEK_LABEL[dayWeekInterval.endPointDay].short
                  }`;

        const times = `${dayWeekInterval.time[0]} - ${dayWeekInterval.time[1]}`;

        return `${dayName} ${times}`;
    }

    intervalRemove(value: TWorkHours, dayWeekInterval: TPointWorkTime) {
        let currentStatus: 'findStartPoint' | 'findEndPoint' | 'finished' = 'findStartPoint';
        const { startPointDay, endPointDay } = dayWeekInterval;

        const arrayAfterRemove = Object.entries(value).map((day) => {
            switch (currentStatus) {
                case 'findStartPoint': {
                    if (day[0] === startPointDay) {
                        currentStatus = 'findEndPoint';
                        if (startPointDay === endPointDay) {
                            currentStatus = 'finished';
                        }
                        return [day[0], []];
                    }
                    break;
                }
                case 'findEndPoint': {
                    if (day[0] === endPointDay) {
                        currentStatus = 'finished';
                    }
                    return [day[0], []];
                }
                default: {
                    return [day[0], day[1]];
                }
            }
            return [day[0], day[1]];
        });

        return Object.fromEntries(arrayAfterRemove);
    }
}

export const workHoursLogic = new WorkHoursLogic();
