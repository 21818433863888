import axios from 'axios';
import { detectApiUrl, detectStaticUrl } from './helpers';

export const isCPAppType = process.env.REACT_APP_TYPE === 'cp';

export const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : detectApiUrl();
export const baseApiUrl = baseUrl + (process.env.REACT_APP_TYPE === 'admin' ? '/v3/marketplace/backoffice/' : '/v3/marketplace/api/');
export const baseStaticUrl = `${
    process.env.REACT_APP_API_STATIC_URL ? process.env.REACT_APP_API_STATIC_URL : detectStaticUrl()
}/static/`;

export type ICommonListRequest<D> = Partial<D> & {
    _page?: number;
    _pageSize?: number;
};

export interface ICommonResponse<D, E = any> {
    data: D;
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
    extendedData: Array<E>;
}

const http = axios.create({
    baseURL: baseApiUrl,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Language': 'ru',
    },
});

export default http;
