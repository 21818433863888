import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { enumResources, resource } from '../../../utils/constants';
import { create, get, setDictionary, update } from '../../../store/Crud';
import GoodPropertyEditor from './GoodPropertyEditor';
import Loading from '../../../components/Loading';
import { useAppDispatch } from '../../../store';
import { getCrudItem } from '../../../store/Crud/selectors';
import { IModel } from '../../../store/Crud/types';
import { createGoodModelLink, createShopModelLink } from '../../../components/Linker/PathCreators';
import { MODEL_FIELDS } from '../../../store/Crud/types/model';
import ModelImg from './ModelImg';
import { getShopId } from '../../../store/System/selectors';

const { TabPane } = Tabs;

interface IGoodModelEdit {
    itemId: string;
    isShopModelEditPage?: boolean;
}

const GoodModelEdit: FC<IGoodModelEdit> = ({ itemId, isShopModelEditPage }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isNewModel = itemId === 'new';
    const goodModel = useSelector(getCrudItem(resource.MODEL));
    const shopId: number = useSelector(getShopId) as any;

    const fields = {
        title: '',
        description: '',
        externalId: '',
        public: '',
        position: '',
        parentModelId: '',
        tags: [],
    };

    const fieldsNewModel = { ...fields, shopId: '' };
    const fieldsEdit = { ...fields, seoTitle: '', seoDescription: '', seoKeywords: '' };

    const hiddenFields = ['parentId'];
    const requiredFields = ['title', 'description', 'externalId'];

    const getFields = () => {
        if (isNewModel) {
            return fieldsNewModel;
        }
        if (isShopModelEditPage) {
            return fieldsEdit;
        }
        return fieldsEdit;
    };

    const onSave = async (item: IModel) => {
        const resultItem = {
            ...item,
            public: Boolean(item[MODEL_FIELDS.public]),
            position: item[MODEL_FIELDS.position] ? Number(item[MODEL_FIELDS.position]) : undefined,
            parentId: item[MODEL_FIELDS.parentModelId] || null,
            shopId: isShopModelEditPage ? shopId : item[MODEL_FIELDS.shopId] || null,
        };

        if (item.id) {
            await dispatch(update(resource.MODEL, String(item.id), resultItem)());
        } else {
            const { payload } = await dispatch(create(resource.MODEL, resultItem)());
            if (payload?.data?.id) {
                history.push(
                    isShopModelEditPage
                        ? createShopModelLink(String(shopId), String(payload?.data?.id))
                        : createGoodModelLink(String(payload?.data?.id)),
                );
            }
        }
    };

    const getCurrentModel = useCallback(() => {
        dispatch(get(resource.MODEL, itemId, { _extend: resource.IMAGE })());
    }, [dispatch, itemId]);

    useEffect(() => {
        if (!isNewModel && String(goodModel?.id) !== itemId) {
            getCurrentModel();
        }
    }, [getCurrentModel, goodModel?.id, isNewModel, itemId]);

    useEffect(() => {
        if (isNewModel) {
            dispatch(setDictionary(enumResources.MODEL)([]));
        }
    }, [dispatch, isNewModel]);

    const item =
        goodModel && String(goodModel?.id) === itemId
            ? {
                  ...goodModel,
                  [MODEL_FIELDS.parentModelId]: goodModel?.parentId,
              }
            : null;

    if (isNewModel ? false : String(goodModel?.id) !== itemId) {
        return <Loading />;
    }

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Информация" key="1">
                {goodModel?.id && !isNewModel && (
                    <ModelImg isShopModelEditPage={isShopModelEditPage} getCurrentModel={getCurrentModel} item={item} />
                )}
                <ItemEdit
                    item={item}
                    fields={getFields()}
                    onSave={onSave}
                    requiredFields={requiredFields}
                    hiddenFields={hiddenFields}
                />
            </TabPane>
            <TabPane tab="Свойства" key="2">
                <GoodPropertyEditor model={item} />
            </TabPane>
        </Tabs>
    );
};

export default GoodModelEdit;
