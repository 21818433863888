export enum enumEventShopNotification {
    ORDER_CREATED = 'order_created',
    ORDER_PAID = 'order_paid',
    CLAIM_CREATED = 'claim_created',
    CODE_CREATED = 'code_created',
    MANAGER_CREATED = 'manager_created',
    AUTH_SUCCESS = 'auth-success',
}

export const localeEventShopNotification = {
    [enumEventShopNotification.ORDER_CREATED]: 'Заказ создан',
    [enumEventShopNotification.ORDER_PAID]: 'Заказ оплачен',
    [enumEventShopNotification.CLAIM_CREATED]: 'Заявка создана',
    [enumEventShopNotification.CODE_CREATED]: 'Код создан',
    [enumEventShopNotification.MANAGER_CREATED]: 'Менеджер создан',
    [enumEventShopNotification.AUTH_SUCCESS]: 'Успешная авторизация',
};

export enum enumHandlerNameShopNotification {
    EMAIL_CLAIM_CREATED_SELLER = 'email_claim_created_seller',
    EMAIL_ORDER_CREATE_BUYER = 'email_order_created_buyer',
    EMAIL_ORDER_CREATED_SELLER = 'email_order_created_seller',
    EMAIL_ORDER_PAID_BUYER = 'email_order_paid_buyer',
    EMAIL_ORDER_PAID_SELLER = 'email_order_paid_seller',
    SMS_CODE_CREATED = 'sms_code_created',
    SMS_MANAGER_CREATED = 'sms_manager_created',
}

export const localeHandlerName = {
    [enumHandlerNameShopNotification.EMAIL_CLAIM_CREATED_SELLER]: 'Заявка создана продавцом',
    [enumHandlerNameShopNotification.EMAIL_ORDER_CREATE_BUYER]: 'Заказ создан покупателем',
    [enumHandlerNameShopNotification.EMAIL_ORDER_CREATED_SELLER]: 'Заказ создан покупателем',
    [enumHandlerNameShopNotification.EMAIL_ORDER_PAID_BUYER]: 'Заказ оплачен покупателем',
    [enumHandlerNameShopNotification.EMAIL_ORDER_PAID_SELLER]: 'Заказ оплачен продавцом',
    [enumHandlerNameShopNotification.SMS_CODE_CREATED]: 'СМС код создан',
    [enumHandlerNameShopNotification.SMS_MANAGER_CREATED]: 'СМС менеджер создан',
};

export enum SHOP_NOTIFICATION_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    shopId = 'shopId',
    notificationIntegrationId = 'notificationIntegrationId',
    event = 'event',
    handlerName = 'handlerName',
}

export interface IShopNotification {
    [SHOP_NOTIFICATION_FIELDS.id]: number;
    [SHOP_NOTIFICATION_FIELDS.title]: string;
    [SHOP_NOTIFICATION_FIELDS.description]: string;
    [SHOP_NOTIFICATION_FIELDS.shopId]: number;
    [SHOP_NOTIFICATION_FIELDS.notificationIntegrationId]: string;
    [SHOP_NOTIFICATION_FIELDS.event]: enumEventShopNotification;
    [SHOP_NOTIFICATION_FIELDS.handlerName]: string;
}

export enum SHOP_CLAIM_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    shopId = 'shopId',
    claimIntegrationId = 'claimIntegrationId',
    active = 'active',
}

export interface IShopClaim {
    [SHOP_CLAIM_FIELDS.id]: number;
    [SHOP_CLAIM_FIELDS.title]: string;
    [SHOP_CLAIM_FIELDS.description]: string;
    [SHOP_CLAIM_FIELDS.shopId]: number;
    [SHOP_CLAIM_FIELDS.claimIntegrationId]: string;
    [SHOP_CLAIM_FIELDS.active]: boolean;
}
