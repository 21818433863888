import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { getDictionary } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { uploadShopFile } from '../../../store/File';
import { enumTypeImage, IRequestUploadImageShop } from '../../../store/File/types';
import { update } from '../../../store/Crud';
import ImgEdit from '../../../components/ImgEdit';
import { IModel } from '../../../store/Crud/types';
import { MODEL_FIELDS } from '../../../store/Crud/types/model';
import { getShopId } from '../../../store/System/selectors';

interface IModelImg {
    item: IModel;
    getCurrentModel: () => void;
    isShopModelEditPage?: boolean;
}

const ModelImg: FC<IModelImg> = ({ item, getCurrentModel, isShopModelEditPage }) => {
    const dispatch = useAppDispatch();

    const dictionary = useSelector(getDictionary(resource.MODEL));
    const shopId: number = useSelector(getShopId) as any;

    // @ts-ignore
    const imagePath = dictionary?.data?.[0]?.url?.replace('/static', '/static/100x66');

    if (!item) {
        return null;
    }

    const uploadImage = async (image: any) => {
        const { payload }: any = await dispatch(
            uploadShopFile({ uploadFile: image, shopId: isShopModelEditPage ? shopId : 0, type: enumTypeImage.IMAGE }),
        );

        if (payload?.data?.data) {
            const infoAboutNewImage: IRequestUploadImageShop = payload?.data.data as any;
            await dispatch(
                update(resource.MODEL, String(item[MODEL_FIELDS.id]), {
                    ...item,
                    imageId: infoAboutNewImage.id,
                })(),
            );
            await getCurrentModel();
        }
    };

    return <ImgEdit isFullURL imagePath={imagePath} uploadImage={uploadImage} />;
};

export default ModelImg;
