import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getCrudItem } from '../../../store/Crud/selectors';
import { useAppDispatch } from '../../../store';
import { IPrice } from '../../../store/Crud/types';
import { create, get, update } from '../../../store/Crud';
import { createPriceLink } from '../../../components/Linker/PathCreators';

interface IPriceEdit {
    shopId: number;
    itemId: string;
}

const PriceEdit: FC<IPriceEdit> = ({ shopId, itemId }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const price = useSelector(getCrudItem(resource.PRICE));

    const fields = {
        title: '',
        description: '',
        externalId: '',
    };

    const requiredFields = ['title', 'description'];

    const onSave = async (submitItem: IPrice) => {
        if (submitItem.id) {
            await dispatch(update(resource.PRICE, String(submitItem.id), submitItem)());
        } else {
            const { payload } = await dispatch(create(resource.PRICE, submitItem)());
            if (payload?.data?.id) {
                history.push(createPriceLink(String(shopId), String(payload?.data?.id)));
            }
        }
    };

    useEffect(() => {
        if (itemId !== 'new') {
            dispatch(get(resource.PRICE, itemId, { shopId })());
        }
    }, [dispatch, itemId, shopId]);

    const item = price && String(price?.id) === itemId ? price : {};
    if (shopId === 0 || (itemId === 'new' ? false : String(item?.id) !== itemId)) {
        return <Loading />;
    }

    return (
        <div>
            <ItemEdit
                item={item}
                subjectName="price"
                fields={fields}
                onSave={onSave}
                additionalFields={{ shopId }}
                requiredFields={requiredFields}
            />
        </div>
    );
};

export default PriceEdit;
