import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { TPrice } from '../../../store/Crud/types/good';
import { IPrice } from '../../../store/Crud/types';

interface IGoodPrices {
    items: TPrice[];
}

const GoodPrices: FC<IGoodPrices> = ({ items }) => {
    const prices: IPrice[] = useSelector(getCrudItems(resource.PRICE)) as any;

    const renderMinValue = (minValue?: number) => (minValue ? ` (от ${minValue})` : '');

    if (!items) {
        return <span>Цены не заданы</span>;
    }

    return (
        <span>
            {items.map((elem) => {
                const price = prices.find((curPrice) => curPrice.id === elem.id);
                return (
                    <span key={elem.id}>
                        {price ? price.title + renderMinValue(elem.minValue) : ''}: {elem.price}
                        <br />
                    </span>
                );
            })}
        </span>
    );
};

export default GoodPrices;
