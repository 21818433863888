import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { packingListId, powerOfAttorneyId, resource } from '../../../utils/constants';
import { dateFormat } from '../../../utils/formatters';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { createDocumentLink } from '../../../utils/helpers';
import { orderPaid, orderPaidLabel, enumOrderStatus, orderStatusLabel } from '../../../store/Crud/types/payment';
import { IOrder } from '../../../store/Crud/types/order';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';

interface IOrderList {
    shopId: string;
    onClick: (order: IOrder) => void;
}

const OrderList: FC<IOrderList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const orders = useSelector(getCrudItems(resource.ORDER));

    useEffect(() => {
        dispatch(find(resource.ORDER, { shopId, _order: { id: 'desc' } })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t(enumFieldName.createdAt),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => dateFormat(createdAt),
        },
        {
            title: t(enumFieldName.status),
            dataIndex: 'status',
            key: 'status',
            render: (status: enumOrderStatus) => orderStatusLabel[status],
        },
        {
            title: t(enumFieldName.paidStatus),
            dataIndex: 'paidStatus',
            key: 'paidStatus',
            render: (paid: orderPaid) => orderPaidLabel[paid],
        },
        {
            title: t(enumFieldName.sum),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: string) => `${amount} руб`,
        },
        {
            title: t(enumFieldName.documents),
            key: 'messages',
            render: (text: string, order: IOrder) =>
                order.status === 1 && (
                    <div>
                        <a
                            className="link"
                            target="_blank"
                            href={createDocumentLink(String(order.id), String(powerOfAttorneyId))}
                        >
                            Доверенность
                        </a>{' '}
                        &nbsp;&nbsp;
                        <a
                            className="link"
                            target="_blank"
                            href={createDocumentLink(String(order.id), String(packingListId))}
                        >
                            Товарная накладная
                        </a>
                    </div>
                ),
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={orders}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default OrderList;
