export enum INTEGRATION_FIELDS {
    id = 'id',
    title = 'title',
    settingFields = 'settingFields',
    settings = 'settings',
    type = 'type',
    additionalProperties = 'additionalProperties',
    required = 'required',
    properties = 'properties',
    amount = 'amount',
    thresholdFreeAmount = 'thresholdFreeAmount',
    deliveryInfoFields = 'deliveryInfoFields',
    handlerNames = 'handlerNames',
}

export interface ISettingFields {
    [INTEGRATION_FIELDS.type]: string;
    [INTEGRATION_FIELDS.additionalProperties]: boolean;
    [INTEGRATION_FIELDS.required]: string[];
    [INTEGRATION_FIELDS.properties]: any;
}

export interface IDeliveryIntegration {
    [INTEGRATION_FIELDS.id]: string;
    [INTEGRATION_FIELDS.title]: string;
    [INTEGRATION_FIELDS.settingFields]: ISettingFields;
    [INTEGRATION_FIELDS.deliveryInfoFields]: ISettingFields;
}

export interface IClaimIntegration {
    [INTEGRATION_FIELDS.id]: string;
    [INTEGRATION_FIELDS.title]: string;
    [INTEGRATION_FIELDS.settings]: ISettingFields;
}

export interface INotificationIntegration {
    [INTEGRATION_FIELDS.id]: string;
    [INTEGRATION_FIELDS.title]: string;
    [INTEGRATION_FIELDS.settings]: ISettingFields;
    [INTEGRATION_FIELDS.handlerNames]: string[];
}
