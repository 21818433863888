import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Form, Modal } from 'antd';
import Select from '../../../components/ItemEdit/components/Select';
import { enumOrderStatus, orderStatusLabel } from '../../../store/Crud/types/payment';
import { IOrder, ORDER_FIELDS } from '../../../store/Crud/types/order';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { resource } from '../../../utils/constants';
import { useAppDispatch } from '../../../store';
import { update } from '../../../store/Crud';

interface IModalEditStatus {
    isShow: boolean;
    hide: () => void;
    order: IOrder;
    fetchOrder: () => void;
}

const ModalEditStatus: FC<IModalEditStatus> = ({ isShow, hide, order, fetchOrder }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const orderStatuses = Object.entries(orderStatusLabel).map((status) => ({
        id: status[0],
        title: status[1],
    }));

    const [orderStatus, setOrderStatus] = useState<enumOrderStatus | number>();

    const isNeedDefermentAllowed =
        (order[ORDER_FIELDS.status] === enumOrderStatus.CREATED && orderStatus === enumOrderStatus.CONFIRMED) ||
        (order[ORDER_FIELDS.status] === enumOrderStatus.CONFIRMED && orderStatus === enumOrderStatus.CONFIRMED);

    const onSave = async () => {
        const formValues = {
            ...form.getFieldsValue(),
            [ORDER_FIELDS.defermentAllowed]: isNeedDefermentAllowed
                ? form.getFieldsValue()[ORDER_FIELDS.defermentAllowed]
                : undefined,
        };
        const { payload } = await dispatch(update(resource.ORDER, String(order[ORDER_FIELDS.id]), formValues, true)());
        if (payload?.data) {
            await fetchOrder();
            hide();
        }
    };

    const initialValues = {
        [ORDER_FIELDS.status]: order[ORDER_FIELDS.status],
        [ORDER_FIELDS.defermentAllowed]: order[ORDER_FIELDS.defermentAllowed],
    };

    useEffect(() => {
        setOrderStatus(order[ORDER_FIELDS.status]);
    }, [order]);

    return (
        <Modal onOk={form.submit} title="Изменение статуса заказа" visible={isShow} onCancel={hide}>
            <Form onFinish={onSave} form={form} initialValues={initialValues}>
                <Form.Item shouldUpdate rules={[{ required: true }]} name={ORDER_FIELDS.status} label="Статус заказа">
                    <Select onChange={setOrderStatus} items={orderStatuses} />
                </Form.Item>
                {isNeedDefermentAllowed && (
                    <Form.Item
                        shouldUpdate
                        name={[ORDER_FIELDS.defermentAllowed]}
                        valuePropName="checked"
                        label={t(enumFieldName.defermentAllowed)}
                    >
                        <Checkbox />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default ModalEditStatus;
