import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import Loading from '../../components/Loading';
import { IShopNotificationConnection } from '../../store/Crud/types/shopNotificationConnection';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import ShopNotificationConnectionEdit from './components/ShopNotificationConnectionEdit';
import { createShopNotificationLink, createShopNotificationListLink } from '../../components/Linker/PathCreators';

const ShopNotificationConnectionEditPage = () => {
    const shopId: number = useSelector(getShopId) as any;

    const { id: notificationConnectionId } = useParams<{ id: string }>();

    const notificationConnection: IShopNotificationConnection = useSelector(
        getCrudItem(resource.SHOP_NOTIFICATION_CONNECTION),
    ) as any;

    const getTitle = () => {
        if (notificationConnectionId === 'new') {
            return 'Новая';
        }
        return notificationConnection ? `#${notificationConnection.id} ${notificationConnection.title}` : '';
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link
                        to={{
                            pathname: createShopNotificationListLink(String(shopId)),
                            state: {
                                isShowShopNotificationConnection: true,
                            },
                        }}
                    >
                        Настройки каналов нотификаций
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ShopNotificationConnectionEdit itemId={notificationConnectionId} />
            </div>
        </div>
    );
};

export default ShopNotificationConnectionEditPage;
