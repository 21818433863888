import React, { useEffect } from 'react';
import { Breadcrumb, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getShopId } from '../../store/System/selectors';
import { IShopClaim } from '../../store/Crud/types/notification';
import { createShopClaimLink } from '../../components/Linker/PathCreators';
import { getCrudItems } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { find } from '../../store/Crud';
import { enumFieldName, t } from '../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../store';

const ShopClaimListPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const shopId: string = useSelector(getShopId) as any;

    const shopClaim: IShopClaim[] = useSelector(getCrudItems(resource.SHOP_CLAIM));

    useEffect(() => {
        dispatch(find(resource.SHOP_CLAIM, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.description),
            dataIndex: 'description',
            key: 'description',
            width: '30%',
        },
        {
            title: t(enumFieldName.claimIntegrationId),
            dataIndex: 'claimIntegrationId',
            key: 'claimIntegrationId',
            width: '5%',
            render: (claimIntegrationId: string) => `#${claimIntegrationId}`,
        },
    ];

    const goToItem = (item: IShopClaim) => {
        history.push(createShopClaimLink(shopId, String(item.id)));
    };

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Настройки заявок</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.shopClaim} itemId="new">
                        Создать
                    </Linker>
                </div>
                {shopId && (
                    <Table
                        rowKey="id"
                        dataSource={shopClaim}
                        columns={columns}
                        onRow={(record: IShopClaim) => ({
                            onClick: () => goToItem(record),
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default ShopClaimListPage;
