import React, { FC, useCallback, useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Button, Space } from 'antd';
import { useParams } from 'react-router-dom';
import Api from '../../../legacy/actions/Api';
import { resource } from '../../../utils/constants';
import ItemEdit from '../../../components/ItemEdit';
import { useAppDispatch } from '../../../store';
import { create, find, update } from '../../../store/Crud';
import { CUSTOMER_FIELDS, IClient, ICustomer } from '../../../store/Crud/types/client';
import Loading from '../../../components/Loading';

interface IShopClientEdit {
    itemId: string;
    shopId: number | string;
    onSave: (client: IClient) => void;
    onFormLoad: (title: string) => void;
}

const ShopClientEdit: FC<IShopClientEdit> = ({ itemId, shopId, onFormLoad, onSave }) => {
    const dispatch = useAppDispatch();
    const { id: shopClientId } = useParams<{ id: string }>();

    const [shopClient, setShopClient] = useState<IClient | null>(null);
    const [customer, setCustomer] = useState<ICustomer | null>(null);

    const fields = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        description: '',
    };

    const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'firstName', 'description'];

    const updateClientData = useCallback(
        (data: IClient | null) => {
            setShopClient(data);
            if (data) {
                onFormLoad(`#${data.id} ${data.firstName} ${data.lastName}`);
            } else {
                onFormLoad('Новый');
            }
        },
        [onFormLoad],
    );

    const auth = async () => {
        const { payload } = await dispatch(
            update(resource.SHOP_CLIENT, `${shopClient?.id}/auth`, { shopClientId: Number(shopClient?.id) })(),
        );
        if (payload && payload.data) {
            window.open(payload.data.redirectUrl, '_blank');
        }
    };

    const findClient = useCallback(async () => {
        if (itemId !== 'new') {
            const { payload } = await dispatch(
                find(resource.SHOP_CLIENT, { id: itemId, _extend: [resource.PROFILE] })(),
            );
            if (payload && payload?.data) {
                if (payload.data.length > 0) {
                    updateClientData(payload.data[0]);
                    setCustomer(Api.extractExtendData(payload.extendedData, resource.PROFILE)[0]);
                } else {
                    updateClientData(null);
                }
            }
        }
    }, [itemId, dispatch, updateClientData]);

    useEffect(() => {
        findClient();
    }, [findClient]);

    const handleSubmit = async (data: IClient) => {
        if (data && data.id) {
            const { payload } = await dispatch(update(resource.SHOP_CLIENT, String(data.id), data)());
            if (payload && payload?.data) {
                updateClientData(payload?.data);
            }
        } else {
            const { payload } = await dispatch(
                create(resource.SHOP_CLIENT, {
                    ...data,
                    phone: data.phone.replace('+', ''),
                })(),
            );
            if (payload && payload?.data) {
                updateClientData(payload?.data);
                onSave(payload?.data);
            }
        }
    };

    const disabledFields = customer ? { [CUSTOMER_FIELDS.phone]: true } : {};

    if (
        shopClientId === 'new'
            ? false
            : shopClientId !== String(shopClient?.id) || customer?.id !== shopClient?.customerId
    ) {
        return <Loading />;
    }

    return (
        <>
            <Space>{shopClient !== null && <Button onClick={auth}>Создать заказ</Button>}</Space>
            <ItemEdit
                onSave={handleSubmit}
                fields={fields}
                additionalFields={{ shopId }}
                item={{ ...shopClient, phone: customer?.phone }}
                disabledFields={disabledFields}
                requiredFields={requiredFields}
            />
        </>
    );
};

export default ShopClientEdit;
