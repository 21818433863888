import { crudActions } from '../constants/reducerActions';
import Api from './Api';

const processExtendData = (extendData, dispatch) => {
    if (extendData) {
        extendData.map((extendedContainer) => {
            dispatch({
                type: `${crudActions.SET_DICTIONARY}_${extendedContainer.entity}`,
                items: extendedContainer.data,
            });
        });
    }
};

export const find = (resourceName, query) => (dispatch) => {
    dispatch(setLoadingList(resourceName, true));
    return Api.find(resourceName, query).then(([data, extendData, error]) => {
        dispatch({
            type: `${crudActions.SET_LIST}_${resourceName}`,
            items: data,
        });
        processExtendData(extendData, dispatch);
        dispatch(setLoadingList(resourceName, false));

        return [data, extendData, error];
    });
};

export const clear = (resourceName) => (dispatch) => {
    dispatch({
        type: `${crudActions.SET_LIST}_${resourceName}`,
        items: [],
    });
    dispatch({
        type: `${crudActions.SET_ITEM}_${resourceName}`,
        item: [],
    });
};

export const get = (resourceName, id, query) => (dispatch) => {
    dispatch(setLoadingItem(resourceName, true));
    return Api.find(resourceName, { id, ...query }).then(([data, extendData, error]) => {
        dispatch({
            type: `${crudActions.SET_ITEM}_${resourceName}`,
            item: data[0],
        });

        processExtendData(extendData, dispatch);

        dispatch(setLoadingItem(resourceName, false));
    });
};

export const update = (resourceName, id, payload) => (dispatch) => {
    dispatch(setWaitUpdate(resourceName, true));
    return Api.update(resourceName, id, payload).then(([data, extendData, error]) => {
        if (error) {
            dispatch({
                type: `${crudActions.ITEM_ERROR}_${resourceName}`,
                error,
            });
        } else {
            dispatch({
                type: `${crudActions.SET_ITEM}_${resourceName}`,
                item: data,
            });
        }
        processExtendData(extendData, dispatch);
        dispatch(setWaitUpdate(resourceName, false));

        return [data, extendData, error];
    });
};

export const create = (resourceName, payload) => (dispatch) => {
    dispatch(setWaitCreate(resourceName, true));
    return Api.create(resourceName, payload).then(([data, extendData, error]) => {
        if (error) {
            dispatch({
                type: `${crudActions.ITEM_ERROR}_${resourceName}`,
                error,
            });
        } else {
            dispatch({
                type: `${crudActions.SET_ITEM}_${resourceName}`,
                item: data,
            });
        }

        processExtendData(extendData, dispatch);
        dispatch(setWaitCreate(resourceName, false));

        return [data, extendData, error];
    });
};

export const remove = (resourceName, id) => (dispatch) => {
    dispatch(setWaitDelete(resourceName, true));
    return Api.delete(resourceName, id).then(([data, extendData, error]) => {
        if (error) {
            dispatch({
                type: `${crudActions.ITEM_ERROR}_${resourceName}`,
                error,
            });
        }

        processExtendData(extendData, dispatch);
        dispatch(setWaitDelete(resourceName, false));

        return [data, extendData, error];
    });
};

export const setLoadingList = (resourceName, value) => (dispatch) => {
    dispatch({
        type: `${crudActions.LOADING_LIST}_${resourceName}`,
        value,
    });
};

export const setLoadingItem = (resourceName, value) => (dispatch) => {
    dispatch({
        type: `${crudActions.LOADING_ITEM}_${resourceName}`,
        value,
    });
};

export const setWaitUpdate = (resourceName, value) => (dispatch) => {
    dispatch({
        type: `${crudActions.WAIT_UPDATE}_${resourceName}`,
        value,
    });
};

export const setWaitCreate = (resourceName, value) => (dispatch) => {
    dispatch({
        type: `${crudActions.WAIT_CREATE}_${resourceName}`,
        value,
    });
};

export const setWaitDelete = (resourceName, value) => (dispatch) => {
    dispatch({
        type: `${crudActions.WAIT_DELETE}_${resourceName}`,
        value,
    });
};
