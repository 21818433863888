import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSessionRequest, logoutRequest, postAuthByPasswordRequest, setPasswordRequest } from './http';
import { clear } from '../../legacy/actions/crud';
import { resource } from '../../utils/constants';
import { IUserAuth } from './types';
import { thunkHandler } from '../../utils/helpers';
import { clear as newClear, find } from '../Crud/index';
import { resetSystem } from '../System';
import { AppDispatch } from '../index';

const loadInitialData = (dispatch: AppDispatch) => {
    dispatch(find(resource.MODEL, { _order: { id: 'desc' }, _pageSize: 999999 })());
    dispatch(find(resource.MODEL_PROPERTY, { _order: { id: 'desc' }, _pageSize: 999999 })());
    dispatch(find(resource.SHOP, { _order: { id: 'desc' } })());
};

export const checkSession = createAsyncThunk('auth/current', async (args, thunkAPI) => {
    const { data } = await getSessionRequest().then((res) => res.data);
    const { dispatch } = thunkAPI;
    if (data.userId > 0) {
        loadInitialData(dispatch as AppDispatch);
        dispatch(find(resource.MODEL_PROPERTY, { _order: { id: 'desc' }, _pageSize: 999999 })());
    }
    return data;
});

export const authByPassword = createAsyncThunk('auth/authUser', async (authUserData: IUserAuth, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const data = await thunkHandler(postAuthByPasswordRequest(authUserData), thunkAPI);
    if (data?.userId > 0) {
        loadInitialData(dispatch as AppDispatch);
    }
    return data;
});

export const logout = createAsyncThunk('auth/logout', async (args, thunkAPI) => {
    const data = await thunkHandler(logoutRequest(), thunkAPI);
    if (data?.userId === 0) {
        thunkAPI.dispatch(resetSystem());
        thunkAPI.dispatch(newClear(resource.SHOP)());
        // todo поправить по мере создания соответствующих редьюсеров в redux-toolkit
        thunkAPI.dispatch(newClear(resource.ORDER)());
        thunkAPI.dispatch(newClear(resource.CATEGORY)());
        thunkAPI.dispatch(clear(resource.GOOD));
        thunkAPI.dispatch(newClear(resource.MANAGER));
        thunkAPI.dispatch(clear(resource.ADDRESS));
        thunkAPI.dispatch(newClear(resource.SHOP_ADDRESS)());
    }
    return data;
});

export const setPassword = createAsyncThunk('auth/setPassword', async (password: string, thunkAPI) => {
    const { data } = await setPasswordRequest(password);
    if (data) {
        thunkAPI.dispatch(checkSession());
    }
    return data;
});

export interface IAuthState {
    userId: number | null;
    authError: string | null;
    neededPasswordUpdate: boolean;
    waitResponse: boolean;
}

const initialState = {
    userId: null,
    authError: null,
    neededPasswordUpdate: false,
    waitResponse: false,
} as IAuthState;

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // check session
        builder.addCase(checkSession.fulfilled, (state, { payload }) => {
            state.userId = payload.userId;
            state.neededPasswordUpdate = payload.neededPasswordUpdate ?? false;
            state.waitResponse = false;
            state.authError = null;
        });
        // auth
        builder.addCase(authByPassword.pending, (state) => {
            state.waitResponse = true;
        });
        builder.addCase(authByPassword.fulfilled, (state, { payload }) => {
            state.userId = payload.userId;
            state.neededPasswordUpdate = payload.neededPasswordUpdate;
            state.waitResponse = false;
        });
        builder.addCase(authByPassword.rejected, (state, error) => {
            const authError: { error: { code: string; message: string } } = error?.payload as any;
            state.authError = authError.error.code;
            state.waitResponse = false;
        });
        // logout
        builder.addCase(logout.pending, (state) => {
            state.waitResponse = true;
        });
        builder.addCase(logout.fulfilled, () => initialState);
        // setPassword
        builder.addCase(setPassword.pending, (state) => {
            state.waitResponse = true;
        });
        builder.addCase(setPassword.fulfilled, (state) => {
            state.waitResponse = false;
        });
    },
});

export default auth.reducer;
