import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { ICategory } from '../../../store/Crud/types';

interface ICategoryList {
    shopId: string;
    onClick: (item: ICategory) => void;
}

const CategoryList: FC<ICategoryList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const categories: ICategory[] = useSelector(getCrudItems(resource.CATEGORY)) as any;

    useEffect(() => {
        dispatch(find(resource.CATEGORY, { shopId, _order: { path: 'asc' } })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '18%',
            render: (title: string, item: { path: string }) =>
                `${'\u00A0\u00A0'.repeat(item.path.split('.').length - 1)} ${title}`,
        },
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
            width: '100px',
        },
        {
            title: t(enumFieldName.description),
            dataIndex: 'description',
            key: 'description',
            width: '100px',
        },
        {
            title: t(enumFieldName.parentId),
            dataIndex: 'parentId',
            key: 'parentId',
            width: '18%',
            render: (parentId: number) => {
                const category = categories.find((elem) => elem.id === parentId);
                return category && category.title;
            },
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={categories}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default CategoryList;
