export enum CATEGORY_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    imageId = 'imageId',
    updatedAt = 'updatedAt',
    externalId = 'externalId',
    shopId = 'shopId',
    goodCount = 'goodCount',
    path = 'path',
    position = 'position',
    public = 'public',
    aliasIds = 'aliasIds',
    modelId = 'modelId',
    parentId = 'parentId',
}

export interface ICategory {
    [CATEGORY_FIELDS.id]: number;
    [CATEGORY_FIELDS.title]: string;
    [CATEGORY_FIELDS.description]: string;
    [CATEGORY_FIELDS.imageId]: number;
    [CATEGORY_FIELDS.updatedAt]: string;
    [CATEGORY_FIELDS.externalId]: string;
    [CATEGORY_FIELDS.shopId]: number;
    [CATEGORY_FIELDS.goodCount]: number;
    [CATEGORY_FIELDS.path]: string;
    [CATEGORY_FIELDS.position]: number;
    [CATEGORY_FIELDS.public]: boolean;
    [CATEGORY_FIELDS.aliasIds]: string;
    [CATEGORY_FIELDS.modelId]: string | null;
    [CATEGORY_FIELDS.parentId]: number | null;
}
