import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Table } from 'antd';
import { Key } from 'antd/es/table/interface';
import GoodPropertyEdit from './GoodPropertyEdit';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { filterTypeText, goodPropertyTypeText, resource } from '../../../utils/constants';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IModel, IGoodProperty } from '../../../store/Crud/types';

interface IGoodPropertyEditor {
    model: IModel;
}

const GoodPropertyEditor: FC<IGoodPropertyEditor> = ({ model }) => {
    const goodProperties: IGoodProperty[] = useSelector(getCrudItems(resource.MODEL_PROPERTY)) as any;

    const [current, setCurrent] = useState<number | null>();

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '200px',
        },
        {
            title: t(enumFieldName.groupName),
            dataIndex: 'groupName',
            key: 'groupName',
            width: '200px',
        },
        {
            title: t(enumFieldName.filterTypes),
            dataIndex: 'filterTypes',
            key: 'filterTypes',
            width: '300px',
            render: (filterTypes: string[]) =>
                filterTypes ? filterTypes.map((filterValue) => filterTypeText[filterValue]).join(', ') : '',
        },
        {
            title: t(enumFieldName.multiple),
            dataIndex: 'multiple',
            key: 'multiple',
            width: '200px',
            render: (multiple: boolean) => (multiple ? 'Да' : 'Нет'),
        },
        {
            title: t(enumFieldName.public),
            dataIndex: 'public',
            key: 'public',
            width: '10%',
            render: (multiple: boolean) => (multiple ? 'Да' : 'Нет'),
        },
        {
            title: t(enumFieldName.type),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => goodPropertyTypeText[type],
        },
        {
            title: t(enumFieldName.externalId),
            dataIndex: 'externalId',
            key: 'externalId',
        },
    ];

    const changeCurrent = (record: IGoodProperty | null) => {
        setCurrent(record ? record.id : null);
    };

    if (!model) {
        return null;
    }

    return (
        <div>
            <Table
                rowKey="id"
                dataSource={[
                    {
                        id: '',
                        modelId: model.id,
                    },
                    ...goodProperties.filter((elem) => elem.modelId === model.id),
                ]}
                columns={columns}
                expandedRowKeys={[current as Key]}
                expandedRowRender={(record) => (
                    <GoodPropertyEdit
                        item={record as IGoodProperty}
                        actionAfterSubmit={(item: IGoodProperty) => changeCurrent(item)}
                    />
                )}
                onExpand={(ex, record) => changeCurrent(ex ? (record as IGoodProperty) : null)}
                expandIcon={({ expanded, onExpand, record }) => {
                    let Icon = CloseOutlined;
                    if (!expanded) {
                        Icon = record.id === '' ? PlusOutlined : EditOutlined;
                    }

                    return <Icon onClick={(e) => onExpand(record, e)} />;
                }}
            />
        </div>
    );
};

export default GoodPropertyEditor;
