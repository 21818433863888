import React, { FC } from 'react';
import { Select as AntSelect } from 'antd';

interface ISelect {
    value?: string;
    items?: {
        id: string;
        title: string;
    }[];
    onChange?: (item: any) => void;
}

const Select: FC<ISelect> = ({ onChange, items, value }) => (
    <AntSelect onChange={onChange} value={value} disabled={items && items?.length < 1}>
        {items?.map((item) => (
            <AntSelect.Option key={item.id} value={item.id}>
                {item.title}
            </AntSelect.Option>
        ))}
    </AntSelect>
);

export default Select;
