import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form } from 'antd';
import { getCrudItemById, getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { IShop } from '../../../store/Crud/types';
import Property from '../../../components/Property';
import { useAppDispatch } from '../../../store';
import { update } from '../../../store/Crud';

interface IShopProperty {
    shop: IShop;
}

const ShopProperty: FC<IShopProperty> = ({ shop }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const modelId = shop?.modelId;

    const model = useSelector(getCrudItemById(resource.MODEL)(modelId));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const properties = useSelector(getCrudItems(resource.MODEL_PROPERTY))?.filter(
        (property) => property.modelId === modelId,
    );

    if (!modelId) {
        return <h2>У магазина не выбрана модель</h2>;
    }

    const handleSubmit = async (values: { [key: string]: any }) => {
        setIsSubmitting(true);
        await dispatch(update(resource.SHOP, String(shop?.id), { manualProperties: values })());
        setIsSubmitting(false);
    };

    const groupName = '';
    return (
        <div>
            <h3>Модель: {model?.title}</h3>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                form={form}
                initialValues={shop?.manualProperties}
                onFinish={handleSubmit}
            >
                {properties.map((record) => (
                    <Property
                        key={record.id}
                        manualProperties={shop?.manualProperties}
                        record={record}
                        groupName={groupName}
                    />
                ))}
                <Form.Item>
                    <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ShopProperty;
