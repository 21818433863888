import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { resource } from '../../utils/constants';
import { useAppDispatch } from '../../store';
import { setCurrentShopId } from '../../store/System';
import { find } from '../../store/Crud';
import { getShopId } from '../../store/System/selectors';
import { getCrudItems } from '../../store/Crud/selectors';
import { createShopLink } from '../Linker/PathCreators';
import { IShop, SHOP_FIELDS } from '../../store/Crud/types';

const { Option } = Select;

const SelectShop = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { shopId: shopIdFromParams } = useParams<{ shopId: string }>();

    const shopId = useSelector(getShopId);
    const shops: IShop[] = useSelector(getCrudItems(resource.SHOP)) as any;

    useEffect(() => {
        if (shopId !== 'new') {
            dispatch(find(resource.CATEGORY, { shopId, _order: { path: 'asc' } })());
            dispatch(find(resource.PRICE, { shopId, _order: { id: 'asc' } })());
        }
    }, [dispatch, shopId]);

    useEffect(() => {
        let shop;

        if (shopId === 0 && shops && shops.length > 0) {
            if (shopIdFromParams) {
                shop = shops.find((elem) => elem.id === Number(shopIdFromParams));
            } else {
                const [shopInArr] = shops;
                shop = shopInArr;
            }

            if (shop) {
                dispatch(setCurrentShopId(shop.id));
            }
        }
    }, [dispatch, shopId, shopIdFromParams, shops]);

    const handleChange = (value: string) => {
        history.push(createShopLink(value));
        dispatch(setCurrentShopId(value));
    };

    const titleShop = shops?.find((curShop) => curShop[SHOP_FIELDS.id] === shopId)?.title;

    return (
        <Select value={titleShop || 'Новый магазин'} onChange={handleChange}>
            {shops.map((shop) => (
                <Option key={shop.id} value={shop.id}>
                    {shop.title}
                </Option>
            ))}
            <Option key="new" value="new">
                Новый магазин
            </Option>
        </Select>
    );
};

export default SelectShop;
