import React from 'react';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import ShopMarketplaceSettingList from './components/ShopMarketplaceSettingList';

const ShopMarketplaceSettingListPage = () => {
    const { shopId } = useParams<{ shopId: string }>();

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Маркетплейс</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <div style={{ margin: '16px 0' }}>
                    <Linker type={enumTypesLinks.shopMarketplaceSetting} itemId="new">
                        Создать
                    </Linker>
                </div>
                <ShopMarketplaceSettingList shopId={shopId} />
            </div>
        </div>
    );
};

export default ShopMarketplaceSettingListPage;
