import React, { FC, useCallback, useState } from 'react';
import { Form, Input, Modal, Tag, TimePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { DAY_WEEK_LABEL, TPointWorkTime, TWorkHours, WEEK_DAYS } from '../../../store/Crud/types/shop';
import { formItemLayout } from '../../../utils/constants';
import { workHoursLogic } from '../../../logics/workHoursLogic';

type TProps = {
    onChange?: (value: TWorkHours) => void;
    value?: TWorkHours;
    disabled?: boolean;
};

const HoursFormInput: FC<TProps> = ({ onChange, disabled, value }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const formattedWeekDays = workHoursLogic.putTogetherTimetable(value);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        if (!onChange) return;

        form.validateFields().then((values: { [key: string]: [Dayjs, Dayjs] | undefined }) => {
            const formattedValues: TWorkHours = Object.fromEntries(
                Object.entries(values).map((day) => [
                    day[0],
                    day?.[1]?.[0] && day?.[1]?.[1]
                        ? [
                              [
                                  workHoursLogic.getFromDayJsHoursAndMinutesString(day[1][0]),
                                  workHoursLogic.getFromDayJsHoursAndMinutesString(day[1][1]),
                              ],
                          ]
                        : [],
                ]),
            ) as any;

            onChange(formattedValues);
            closeModal();
        });
    };

    const handleIntervalRemove = useCallback(
        (dayWeekInterval: TPointWorkTime) => {
            if (!value || !onChange) return;

            const formattedValue = workHoursLogic.intervalRemove(value, dayWeekInterval);
            onChange(formattedValue);
        },
        [onChange, value],
    );

    return (
        <>
            <Input style={{ position: 'absolute', height: '100%' }} onClick={showModal} disabled={disabled} />
            <div style={{ position: 'relative', left: 4, padding: '4px', pointerEvents: 'none' }}>
                {formattedWeekDays?.map((dayWeekInterval) => {
                    const label = workHoursLogic.getShortDayAndTimeInterval(dayWeekInterval);
                    return (
                        <Tag
                            style={{ pointerEvents: 'auto' }}
                            key={label}
                            closable={!disabled}
                            onClose={() => handleIntervalRemove(dayWeekInterval)}
                        >
                            {label}
                        </Tag>
                    );
                })}
            </div>
            {isModalOpen && (
                <Modal title="Часы работы" visible={isModalOpen} onOk={handleSubmit} onCancel={closeModal}>
                    <Form form={form} initialValues={workHoursLogic.getInitialFormInputValue(value)}>
                        {Object.keys(DAY_WEEK_LABEL).map((day) => (
                            <Form.Item
                                key={day}
                                name={day}
                                label={DAY_WEEK_LABEL[day as WEEK_DAYS].full}
                                {...formItemLayout}
                            >
                                <TimePicker.RangePicker placeholder={['с', 'до']} format="HH:mm" />
                            </Form.Item>
                        ))}
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default HoursFormInput;
