import { ISettingFields } from './integrations';

export enum PAYMENT_INTEGRATION_FIELDS {
    id = 'id',
    title = 'title',
    settingFields = 'settingFields',
}

export interface IPaymentIntegration {
    [PAYMENT_INTEGRATION_FIELDS.id]: string;
    [PAYMENT_INTEGRATION_FIELDS.title]: string;
    [PAYMENT_INTEGRATION_FIELDS.settingFields]: ISettingFields;
}

export enum enumOrderStatus {
    CREATED = 'created',
    CONFIRMED = 'confirmed',
    READY_TO_SHIPMENT = 'ready-to-shipment',
    COMPLETED = 'completed',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
}

export const orderStatusLabel = {
    [enumOrderStatus.CREATED]: 'Создан',
    [enumOrderStatus.CONFIRMED]: 'Подтвержден',
    [enumOrderStatus.READY_TO_SHIPMENT]: 'Готов к отгрузке',
    [enumOrderStatus.COMPLETED]: 'Завершен',
    [enumOrderStatus.EXPIRED]: 'Просрочен',
    [enumOrderStatus.CANCELED]: 'Отменен',
};

export enum orderPaid {
    PAID = 'paid',
    UNPAID = 'unpaid',
}

export const orderPaidLabel = {
    [orderPaid.PAID]: 'Оплачен',
    [orderPaid.UNPAID]: 'Не оплачен',
};
