import React, { FC, useState } from 'react';
import { Button, Form, Input, InputNumber, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { formItemLayout, resource, tailFormItemLayout } from '../../../utils/constants';
import Select from '../../../components/ItemEdit/components/Select';
import {
    shopMarketplaceSettingStatusCreate,
    TShopMarketplaceSetting,
} from '../../../store/Crud/types/shopMarketplaceSetting';
import ErrorMessage from '../../../components/ItemEdit/components/ErrorMessage';
import { create, remove, update } from '../../../store/Crud';
import { createShopMarketplaceLink, createShopMarketplaceListLink } from '../../../components/Linker/PathCreators';
import { useAppDispatch } from '../../../store';

type TProps = {
    shopId: string;
    itemId: string;
    isDetailPage: boolean;
    initialValues: TShopMarketplaceSetting;
};

const FormShopMarketplaceSetting: FC<TProps> = ({ shopId, itemId, isDetailPage, initialValues }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [form] = Form.useForm();
    const [error, setError] = useState<{ code: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (item: TShopMarketplaceSetting) => {
        setIsSubmitting(true);

        if (item?.id) {
            const { payload } = await dispatch(update(resource.SHOP_MARKETPLACE_SETTING, String(item.id), item)());
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
            if (payload?.data && payload?.data?.id) {
                form.resetFields();
            }
        } else {
            const { payload } = await dispatch(create(resource.SHOP_MARKETPLACE_SETTING, item)());
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
            if (payload?.data && payload?.data?.id) {
                history.push(createShopMarketplaceLink(shopId, String(payload?.data?.id)));
                form.resetFields();
            }
        }
        setIsSubmitting(false);
    };

    const handleRemove = () => {
        dispatch(remove(resource.SHOP_MARKETPLACE_SETTING, itemId)()).then(() =>
            history.push(createShopMarketplaceListLink(shopId)),
        );
    };

    return (
        <Form onFinish={handleSubmit} form={form} initialValues={initialValues}>
            {isDetailPage && (
                <>
                    <Button onClick={handleRemove}>Удалить</Button>
                    <Form.Item name="id" label={t(enumFieldName.id)} {...formItemLayout} hidden>
                        <Input />
                    </Form.Item>
                </>
            )}
            <Form.Item
                name="participantShopId"
                label="ID магазина-участника"
                rules={[{ required: true }]}
                {...formItemLayout}
            >
                <InputNumber min={1} />
            </Form.Item>
            <Form.Item {...formItemLayout} rules={[{ required: true }]} name="status" label="Статус">
                <Select items={shopMarketplaceSettingStatusCreate} />
            </Form.Item>
            <Form.Item name="marketplaceShopId" hidden>
                <InputNumber type="hidden" />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Space>
                    <Button loading={isSubmitting} disabled={isSubmitting} type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                    {error && <ErrorMessage error={error} />}
                </Space>
            </Form.Item>
        </Form>
    );
};

export default FormShopMarketplaceSetting;
