export type TShopMarketplaceSetting = {
    id: number;
    marketplaceShopId: number;
    participantShopId: number;
    status: string;
};

export const SHOP_MARKETPLACE_SETTING_STATUS_LABEL: Record<string, string> = {
    active: 'Активный',
    deactivated: 'Не активный',
};

export const shopMarketplaceSettingStatusCreate = [
    {
        title: SHOP_MARKETPLACE_SETTING_STATUS_LABEL['active'],
        id: 'active',
    },
    {
        title: SHOP_MARKETPLACE_SETTING_STATUS_LABEL['deactivated'],
        id: 'deactivated',
    },
];
