import React, { useEffect } from 'react';
import {
    ApiOutlined,
    DollarOutlined,
    EnvironmentOutlined,
    FileOutlined,
    IdcardOutlined,
    LogoutOutlined,
    OrderedListOutlined,
    SettingOutlined,
    ShareAltOutlined,
    ShopOutlined,
    SkinOutlined,
    SnippetsOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuInfo } from 'rc-menu/lib/interface';
import SelectShop from './SelectShop';
import { createLink } from '../Linker/PathCreators';
import { enumTypesLinks } from '../Linker/typesLinks';
import { getShopId } from '../../store/System/selectors';
import YandexMetrika from '../YandexMetrika';
import { getCrudItem } from '../../store/Crud/selectors';
import { resource } from '../../utils/constants';
import { IShop } from '../../store/Crud/types';
import { useAppDispatch } from '../../store';
import { get } from '../../store/Crud';
import { SHOP_TYPE } from '../../store/Crud/types/shop';

const MenuItemGroup = Menu.ItemGroup;

const ControlPanelMenu = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const shopId: string = useSelector(getShopId) as any;
    const shop: IShop | undefined = useSelector(getCrudItem(resource.SHOP)) as any;
    const { shopId: shopIdFromParams } = useParams<{ shopId: string }>();

    const isNewShop = shopIdFromParams === 'new';

    useEffect(() => {
        if (!isNewShop && !shop) {
            dispatch(get(resource.SHOP, shopId)());
        }
    }, [dispatch, isNewShop, shop, shopId]);

    const onClickTONavLink = (item: MenuInfo) => {
        const pathKey: enumTypesLinks = item.key as any;
        history.push(createLink(pathKey, shopId));
    };

    return (
        <div>
            <div className="logo">
                <SelectShop />
            </div>
            <Menu
                theme="dark"
                defaultSelectedKeys={['1']}
                mode="inline"
                className="left_menu"
                onClick={onClickTONavLink}
            >
                <MenuItemGroup>
                    <Menu.Item key={enumTypesLinks.shop}>
                        <SettingOutlined />
                        <span>Магазин</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.orderList}>
                        <DollarOutlined />
                        <span>Заказы</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.categoryList}>
                        <SnippetsOutlined />
                        <span>Категории</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.goodList}>
                        <FileOutlined />
                        <span>Товары</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.goodShopPropertyList}>
                        <ApiOutlined />
                        <span>Мэппинг свойств</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.managerList}>
                        <IdcardOutlined />
                        <span>Менеджеры</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopAddressList}>
                        <EnvironmentOutlined />
                        <span>Торговые точки</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.priceList}>
                        <OrderedListOutlined />
                        <span>Прайс-листы</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.legalPriceList}>
                        <ShareAltOutlined />
                        <span>Персональные цены</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopClientList}>
                        <TeamOutlined />
                        <span>Клиенты</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopClaimList}>
                        <SnippetsOutlined />
                        <span>Настройки заявок</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopNotificationList}>
                        <SettingOutlined />
                        <span>Настройки уведомлений</span>
                    </Menu.Item>
                    <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopModelList}>
                        <SkinOutlined />
                        <span>Модели</span>
                    </Menu.Item>
                    {shop?.type === SHOP_TYPE.MARKETPLACE && (
                        <Menu.Item disabled={isNewShop} key={enumTypesLinks.shopMarketplaceSettingList}>
                            <ShopOutlined />
                            <span>Маркетплейс</span>
                        </Menu.Item>
                    )}
                    <Menu.Item key={enumTypesLinks.logout}>
                        <LogoutOutlined />
                        <span>Выход</span>
                    </Menu.Item>
                </MenuItemGroup>
            </Menu>
            <YandexMetrika id="54449302" />
        </div>
    );
};

export default ControlPanelMenu;
