export enum API_USER_FIELDS {
    id = 'id',
    shopId = 'shopId',
    title = 'title',
    appKey = 'appKey',
    appSecret = 'appSecret',
}

export interface IApiUser {
    [API_USER_FIELDS.id]: number;
    [API_USER_FIELDS.shopId]: number;
    [API_USER_FIELDS.title]: string;
    [API_USER_FIELDS.appKey]: string;
    [API_USER_FIELDS.appSecret]: string;
}
