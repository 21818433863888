import React, {Component} from 'react';
import GoodEdit from "../components/GoodEdit";
import {connect} from "react-redux";
import {Breadcrumb} from 'antd';
import {withRouter} from "react-router";
import Loading from "../../components/Loading";
import Linker from "../../components/Linker";
import {enumTypesLinks} from "../../components/Linker/typesLinks";
import {createGoodLink, createGoodListLink} from "../../components/Linker/PathCreators";

class GoodEditPage extends Component {

    onSave(item) {
        if (item) {
            this.props.history.push(createGoodLink(this.props.shopId, item.id));
        }
    }

    onRemove(item) {
        this.props.history.push(createGoodListLink(this.props.shopId));
    }

    getTitle() {
        if (this.props.match.params.id == 'new') {
            return 'Новая';
        }
        return this.props.item ? this.props.item.title : ''
    }

    render() {
        if (this.props.shopId === 0) {
            return <Loading />;
        }

        return <div>
            <Breadcrumb>
                <Breadcrumb.Item><Linker type={enumTypesLinks.goodList} >Товарные позиции</Linker></Breadcrumb.Item>
                <Breadcrumb.Item>{this.getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <GoodEdit
                    shopId={this.props.shopId}
                    itemId={this.props.match.params.id}
                    onSave={this.onSave.bind(this)}
                    onRemove={this.onRemove.bind(this)}
                />
            </div>
        </div>
    }
}

export default withRouter(connect(
    state => ({
        item: state.goods.item,
        shopId: state.system.shopId,
    })
)(GoodEditPage));
