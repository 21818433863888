import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, Space, Table } from 'antd';
import { IPagination, ISorter, resource } from '../../../utils/constants';
import Linker from '../../../components/Linker';
import Api from '../../../legacy/actions/Api';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { IClient, ICustomer } from '../../../store/Crud/types/client';

interface IShopClientList {
    shopId: number | string;
    onClick: (item: IClient) => void;
}

const ShopClientList: FC<IShopClientList> = ({ shopId, onClick }) => {
    const dispatch = useAppDispatch();

    const [query, setQuery] = useState('');
    const [queryRaw, setQueryRaw] = useState('');
    const [timer, setTimer] = useState<any>();

    const [pagination, setPagination] = useState<IPagination>({
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        total: 0,
    });
    const [items, setItems] = useState([]);
    const [profiles, setProfiles] = useState<ICustomer[]>();
    const [order, setOrder] = useState(['title', 'ascend']);

    const prepareSortOrder = (sortOrder: string[]) => ({
        [sortOrder[0]]: sortOrder[1] === 'ascend' ? 'asc' : 'desc',
    });

    const fetchShopClients = useCallback(async () => {
        const filters = {
            shopId,
            _page: pagination.current,
            _pageSize: pagination.pageSize,
            _query: query,
            _order: prepareSortOrder(order),
            _extend: [resource.PROFILE],
        };
        const { payload } = await dispatch(find(resource.SHOP_CLIENT, filters)());
        if (payload && payload?.data) {
            setItems(payload?.data);
            setPagination({ ...pagination, total: payload?.metaData?.totalCount });
            setProfiles(Api.extractExtendData(payload?.extendedData, resource.PROFILE));
        }
    }, [dispatch, order, pagination.current, pagination.pageSize, query, shopId]);

    useEffect(() => {
        fetchShopClients();
    }, [fetchShopClients]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: t(enumFieldName.firstName),
            dataIndex: 'firstName',
            key: 'firstName',
            width: '20%',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: t(enumFieldName.lastName),
            dataIndex: 'lastName',
            key: 'lastName',
            width: '20%',
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: t(enumFieldName.email),
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: t(enumFieldName.phone),
            dataIndex: 'customerId',
            key: 'customerId',
            width: '20%',
            render: (customerId: number) => {
                const profile = profiles?.find((el) => el.id === customerId);
                return profile?.phone || customerId;
            },
        },
        {
            title: t(enumFieldName.description),
            dataIndex: 'description',
            key: 'description',
            width: '20%',
        },
    ];

    const onQueryChange = (changedQuery: string) => {
        setQueryRaw(changedQuery);
        clearTimeout(timer);
        setTimer(setTimeout(() => setQuery(changedQuery), 1000));
    };

    if (!pagination.total) {
        return (
            <Linker type={enumTypesLinks.shopClient} itemId="new">
                Добавить клиента
            </Linker>
        );
    }

    const handleTableChange = (newPagination: IPagination, filters: string, sorter: ISorter) => {
        setPagination(newPagination);
        if (sorter.order) {
            setOrder([sorter.field, sorter.order]);
        } else {
            setOrder(['title', 'ascend']);
        }
    };

    return (
        <div>
            <Space style={{ paddingBottom: '20px' }}>
                <Linker type={enumTypesLinks.shopClient} itemId="new">
                    Создать
                </Linker>
                <Input
                    className="shopClient-search-input"
                    placeholder="Поиск"
                    value={queryRaw}
                    onChange={(e) => onQueryChange(e.target.value)}
                />
            </Space>
            <Table
                pagination={pagination}
                rowKey="id"
                dataSource={items}
                // @ts-ignore
                columns={columns}
                // @ts-ignore
                onChange={handleTableChange}
                onRow={(record) => ({
                    onClick: () => onClick(record),
                })}
            />
        </div>
    );
};

export default ShopClientList;
