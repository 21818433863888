import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input, Table } from 'antd';
import { update } from '../../../legacy/actions/crud';
import { priceType, priceTypeText, resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IGood, TPrice } from '../../../store/Crud/types/good';
import { IPrice } from '../../../store/Crud/types';
import { find } from '../../../store/Crud';
import { getShopId } from '../../../store/System/selectors';

interface IGoodPricesEditor {
    good: IGood;
}

const GoodPricesEditor: FC<IGoodPricesEditor> = ({ good }) => {
    const [prices, setPrices] = useState<any>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();
    const shopId = useSelector(getShopId);

    const priceLists = useSelector(getCrudItems(resource.PRICE));

    useEffect(() => {
        dispatch(find(resource.PRICE, { shopId, _order: { id: 'asc' } })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '200px',
        },
        {
            title: t(enumFieldName.price),
            dataIndex: 'id',
            key: 'price',
            width: '200px',
            render: (priceId: number) => {
                const priceItem = { ...prices?.[priceId] };
                const price = priceItem ? priceItem.price : '';
                return <Input onChange={(e) => onChange('price', priceId, e.target.value)} value={price} />;
            },
        },
        {
            title: t(enumFieldName.type),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => priceTypeText[type],
        },
        {
            title: 'Условия',
            dataIndex: 'id',
            key: 'conditions',
            render: (priceId: number, item: IPrice) => {
                if (item.type === priceType.MIN_COUNT) {
                    const priceItem: TPrice = prices?.[priceId] as any;
                    const minValue = priceItem ? priceItem?.minValue : '';

                    return <Input onChange={(e) => onChange('minValue', priceId, e.target.value)} value={minValue} />;
                }
                return '';
            },
        },
    ];

    const onChange = (field: string, priceId: number, value: string) => {
        const fields = { ...prices };
        if (!fields?.[priceId]) {
            fields[priceId] = { type: 'default', id: priceId };
        }
        if (field === 'price' || field === 'minValue') {
            if (!Number(value)) {
                return;
            }
            fields[priceId][field] = Number(value);
        } else {
            fields[priceId][field] = Number(value);
        }
        setPrices(fields);
    };

    const savePricesToState = useCallback(
        (currentGood: IGood) => {
            const fields = typeof prices === 'object' ? { ...prices } : {};
            if (currentGood) {
                currentGood.prices.forEach((priceItem, index) => {
                    const price = currentGood.prices[index];
                    if (!fields?.[price?.id]) {
                        fields[price?.id] = {};
                    }
                    fields[price?.id] = { ...price };
                });
            }
            return fields;
        },
        [prices],
    );

    const onSave = async () => {
        setIsSubmitting(true);
        const { id } = good;
        if (id) {
            await dispatch(update(resource.GOOD, id, { ...good, prices }));
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        setPrices(savePricesToState({ ...good }));
    }, []);

    if (priceLists.length === 0) {
        return <span> У магазина еще нет ни одного прайс-листа</span>;
    }

    if (!good) {
        return null;
    }

    return (
        <div>
            <Table rowKey="id" dataSource={priceLists} columns={columns} />
            <Button disabled={isSubmitting} loading={isSubmitting} type="primary" onClick={onSave}>
                Сохранить
            </Button>
        </div>
    );
};

export default GoodPricesEditor;
