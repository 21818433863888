import { enumTypesLinks } from './typesLinks';

export const createLoginLink = () => '/login';

export const createResetPasswordLink = () => '/resetPassword';

export const createShopLink = (shopId: string) => `/shop/${shopId}`;

export const createShopListLink = () => '/shop';

export const createDeliveryIntegrationListLink = () => '/deliveryIntegration';

export const createPaymentIntegrationListLink = () => '/paymentIntegration';

export const createNotificationIntegrationListLink = () => '/notificationIntegration';

export const createClaimIntegrationListLink = () => '/claimIntegration';

export const createGoodModelListLink = () => '/goodModel';

export const createGoodModelLink = (goodModelId: string) => `/goodModel/${goodModelId}`;

export const createGoodShopPropertyListLink = (shopId: string) => `/shop/${shopId}/goodShopProperty`;

export const createGoodShopPropertyLink = (shopId: string, goodShopPropertyId: string) =>
    `/shop/${shopId}/goodShopProperty/${goodShopPropertyId}`;

export const createDeliveryTypeLink = (deliveryTypeId: string) => `/deliveryType/${deliveryTypeId}`;

export const createShopDeliveryListLink = (shopId: string) => `/shop/${shopId}/shopDelivery`;

export const createShopDeliveryLink = (shopId: string, shopDeliveryId: string) =>
    `/shop/${shopId}/shopDelivery/${shopDeliveryId}`;

export const createShopMarketplaceListLink = (shopId: string) => `/shop/${shopId}/marketplaceSetting`;

export const createShopMarketplaceLink = (shopId: string, marketplaceSettingId: string) =>
    `/shop/${shopId}/marketplaceSetting/${marketplaceSettingId}`;

export const createGoodLink = (shopId: string, goodId: string) => `/shop/${shopId}/good/${goodId}`;

export const createGoodListLink = (shopId: string) => `/shop/${shopId}/good`;

export const createCategoryLink = (shopId: string, categoryId: string) => `/shop/${shopId}/category/${categoryId}`;

export const createDictionaryListLink = (shopId: string) => `/shop/${shopId}/dictionary`;

export const createDictionaryLink = (shopId: string, dictionaryId: string) =>
    `/shop/${shopId}/dictionary/${dictionaryId}`;

export const createDictionaryItemListLink = (shopId: string, dictionaryId: string) =>
    `/shop/${shopId}/dictionary/${dictionaryId}/dictionary-item`;

export const createDictionaryItemLink = (shopId: string, dictionaryItemId: string, dictionaryId: string) =>
    `/shop/${shopId}/dictionary/${dictionaryId}/dictionary-item/${dictionaryItemId}`;

export const createCategoryListLink = (shopId: string) => `/shop/${shopId}/category`;

export const createManagerLink = (shopId: string, managerId: string) => `/shop/${shopId}/manager/${managerId}`;

export const createManagerListLink = (shopId: string) => `/shop/${shopId}/manager`;

export const createShopPaymentLink = (shopId: string, shopPaymentId: string) =>
    `/shop/${shopId}/shopPayment/${shopPaymentId}`;

export const createShopNotificationLink = (shopId: string, shopNotificationId: string) =>
    `/shop/${shopId}/shopNotification/${shopNotificationId}`;

export const createShopNotificationConnectionLink = (shopId: string, shopNotificationConnectionId: string) =>
    `/shop/${shopId}/shopNotificationConnection/${shopNotificationConnectionId}`;

export const createShopClaimLink = (shopId: string, shopClaimId: string) => `/shop/${shopId}/shopClaim/${shopClaimId}`;

export const createShopPaymentListLink = (shopId: string) => `/shop/${shopId}/shopPayment`;

export const createShopNotificationListLink = (shopId: string) => `/shop/${shopId}/shopNotification`;

export const createShopClaimListLink = (shopId: string) => `/shop/${shopId}/shopClaim`;

export const createPriceLink = (shopId: string, priceListId: string) => `/shop/${shopId}/priceList/${priceListId}`;

export const createPriceListLink = (shopId: string) => `/shop/${shopId}/priceList`;

export const createLegalPriceLink = (shopId: string, legalPriceListId: string) =>
    `/shop/${shopId}/legalPrice/${legalPriceListId}`;

export const createLegalPriceListLink = (shopId: string) => `/shop/${shopId}/legalPrice`;

export const createShopClientListLink = (shopId: string) => `/shop/${shopId}/shopClient`;

export const createShopClientLink = (shopId: string, shopClientId: string) =>
    `/shop/${shopId}/shopClient/${shopClientId}`;

export const createOrderLink = (shopId: string, orderId: string) => `/shop/${shopId}/order/${orderId}`;

export const createOrderListLink = (shopId: string) => `/shop/${shopId}/order`;

export const createProfileLink = (profileId: string) => `/profile/${profileId}`;

export const createProfileListLink = () => '/profile';

export const createShopAddressLink = (shopId: string, shopAddressId: string) =>
    `/shop/${shopId}/shopAddress/${shopAddressId}`;

export const createShopAddressListLink = (shopId: string) => `/shop/${shopId}/shopAddress`;

export const createApiUserLink = (shopId: string, apiUserId: string) => `/shop/${shopId}/apiUser/${apiUserId}`;

export const createApiUserListLink = (shopId: string) => `/shop/${shopId}/apiUser`;

export const createShopModelLink = (shopId: string, modelId: string) => `/shop/${shopId}/shopModel/${modelId}`;

export const createShopModelListLink = (shopId: string) => `/shop/${shopId}/shopModel`;

export const createFileLink = (shopId: string, fileId: string) => `/shop/${shopId}/file/${fileId}`;

export const createFileListLink = (shopId: string) => `/shop/${shopId}/file`;

export const createLogoutLink = () => '/logout';

export const createLink = (type: enumTypesLinks, shopId: string = '', id: string = '', parentId: string = '') => {
    switch (type) {
        case enumTypesLinks.shop:
            return createShopLink(id || shopId);
        case enumTypesLinks.shopList:
            return createShopListLink();
        case enumTypesLinks.deliveryType:
            return createDeliveryTypeLink(id);
        case enumTypesLinks.deliveryIntegrationList:
            return createDeliveryIntegrationListLink();
        case enumTypesLinks.paymentIntegrationList:
            return createPaymentIntegrationListLink();
        case enumTypesLinks.notificationIntegrationList:
            return createNotificationIntegrationListLink();
        case enumTypesLinks.claimIntegrationList:
            return createClaimIntegrationListLink();
        case enumTypesLinks.goodModel:
            return createGoodModelLink(id);
        case enumTypesLinks.goodModelList:
            return createGoodModelListLink();
        case enumTypesLinks.goodShopProperty:
            return createGoodShopPropertyLink(shopId, id);
        case enumTypesLinks.goodShopPropertyList:
            return createGoodShopPropertyListLink(shopId);
        case enumTypesLinks.shopDelivery:
            return createShopDeliveryLink(shopId, id);
        case enumTypesLinks.shopDeliveryList:
            return createShopDeliveryListLink(shopId);
        case enumTypesLinks.shopMarketplaceSettingList:
            return createShopMarketplaceListLink(shopId);
        case enumTypesLinks.shopMarketplaceSetting:
            return createShopMarketplaceLink(shopId, id);
        case enumTypesLinks.shopPayment:
            return createShopPaymentLink(shopId, id);
        case enumTypesLinks.shopNotification:
            return createShopNotificationLink(shopId, id);
        case enumTypesLinks.shopNotificationConnection:
            return createShopNotificationConnectionLink(shopId, id);
        case enumTypesLinks.shopClaim:
            return createShopClaimLink(shopId, id);
        case enumTypesLinks.shopPaymentList:
            return createShopPaymentListLink(shopId);
        case enumTypesLinks.shopNotificationList:
            return createShopNotificationListLink(shopId);
        case enumTypesLinks.shopClaimList:
            return createShopClaimListLink(shopId);
        case enumTypesLinks.good:
            return createGoodLink(shopId, id);
        case enumTypesLinks.goodList:
            return createGoodListLink(shopId);
        case enumTypesLinks.category:
            return createCategoryLink(shopId, id);
        case enumTypesLinks.categoryList:
            return createCategoryListLink(shopId);
        case enumTypesLinks.dictionary:
            return createDictionaryLink(shopId, id);
        case enumTypesLinks.dictionaryList:
            return createDictionaryListLink(shopId);
        case enumTypesLinks.dictionaryItem:
            return createDictionaryItemLink(shopId, id, parentId);
        case enumTypesLinks.dictionaryItemList:
            return createDictionaryItemListLink(shopId, id);
        case enumTypesLinks.manager:
            return createManagerLink(shopId, id);
        case enumTypesLinks.managerList:
            return createManagerListLink(shopId);
        case enumTypesLinks.price:
            return createPriceLink(shopId, id);
        case enumTypesLinks.priceList:
            return createPriceListLink(shopId);
        case enumTypesLinks.legalPrice:
            return createLegalPriceLink(shopId, id);
        case enumTypesLinks.legalPriceList:
            return createLegalPriceListLink(shopId);
        case enumTypesLinks.shopClient:
            return createShopClientLink(shopId, id);
        case enumTypesLinks.shopClientList:
            return createShopClientListLink(shopId);
        case enumTypesLinks.order:
            return createOrderLink(shopId, id);
        case enumTypesLinks.orderList:
            return createOrderListLink(shopId);
        case enumTypesLinks.profile:
            return createProfileLink(id);
        case enumTypesLinks.profileList:
            return createProfileListLink();
        case enumTypesLinks.shopAddress:
            return createShopAddressLink(shopId, id);
        case enumTypesLinks.shopAddressList:
            return createShopAddressListLink(shopId);
        case enumTypesLinks.apiUser:
            return createApiUserLink(shopId, id);
        case enumTypesLinks.apiUserList:
            return createApiUserListLink(shopId);
        case enumTypesLinks.shopModel:
            return createShopModelLink(shopId, id);
        case enumTypesLinks.shopModelList:
            return createShopModelListLink(shopId);
        case enumTypesLinks.file:
            return createFileLink(shopId, id);
        case enumTypesLinks.fileList:
            return createFileListLink(shopId);
        case enumTypesLinks.logout:
            return createLogoutLink();
        case enumTypesLinks.login:
            return createLoginLink();
        case enumTypesLinks.createResetPassword:
            return createResetPasswordLink();
        default:
            return '/';
    }
};
