import React, { FC, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import { useAppDispatch } from '../../../store';
import { IManager, MANAGER_FIELDS } from '../../../store/Crud/types/manager';
import { create, get, remove, update } from '../../../store/Crud';
import { getCrudItem } from '../../../store/Crud/selectors';
import { createManagerLink, createManagerListLink } from '../../../components/Linker/PathCreators';
import Loading from '../../../components/Loading';

interface IManagerEdit {
    shopId: number;
    itemId: string;
}

const ManagerEdit: FC<IManagerEdit> = ({ shopId, itemId }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { id: managerId } = useParams<{ id: string }>();

    const manager = useSelector(getCrudItem(resource.MANAGER));

    const fields = {
        fio: '',
        phone: '',
        email: '',
        public: '',
    };

    const requiredFields = [MANAGER_FIELDS.phone];

    const onSave = async (item: IManager) => {
        if (item.id) {
            dispatch(update(resource.MANAGER, String(item.id), item)());
        } else {
            const { payload } = await dispatch(create(resource.MANAGER, item)());
            if (payload?.data) {
                history.push(createManagerLink(String(shopId), String(payload.data.id)));
            }
        }
    };

    const onRemove = async (item: IManager) => {
        if (item.id) {
            await dispatch(remove(resource.MANAGER, String(item.id))());
            history.push(createManagerListLink(String(shopId)));
        }
    };

    useEffect(() => {
        if (itemId !== 'new') {
            dispatch(get(resource.MANAGER, itemId, { shopId })());
        }
    }, [dispatch, itemId, shopId]);

    const item = manager && String(manager?.id) === itemId ? manager : {};

    if (managerId === 'new' ? false : managerId !== String(manager?.id)) {
        return <Loading />;
    }

    return (
        <div>
            <ItemEdit
                item={item}
                fields={_.isEmpty(item) ? { phone: '' } : fields}
                onSave={onSave}
                onRemove={onRemove}
                additionalFields={{ shopId }}
                deletable
                requiredFields={requiredFields}
            />
        </div>
    );
};

export default ManagerEdit;
