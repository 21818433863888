import React from 'react';
import {
    CarOutlined,
    CreditCardOutlined,
    LogoutOutlined,
    SettingOutlined,
    ShopOutlined,
    SkinOutlined,
    SnippetsOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import { createLink } from '../Linker/PathCreators';
import { enumTypesLinks } from '../Linker/typesLinks';
import { getUserId } from '../../store/Auth/selectors';

const AdminMenu = () => {
    const history = useHistory();
    const userId = useSelector(getUserId);

    const onClickToNavLink = (item: MenuInfo) => {
        const pathKey: enumTypesLinks = item.key as any;
        history.push(createLink(pathKey));
    };

    if (!userId) {
        return null;
    }

    return (
        <div>
            <div className="logo">Админка</div>
            <Menu
                theme="dark"
                defaultSelectedKeys={['1']}
                mode="inline"
                className="left_menu"
                onClick={onClickToNavLink}
            >
                <Menu.Item key={enumTypesLinks.shopList}>
                    <ShopOutlined />
                    <span>Площадки</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.profileList}>
                    <UserOutlined />
                    <span>Пользователи</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.deliveryIntegrationList}>
                    <CarOutlined />
                    <span>Способы доставки</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.paymentIntegrationList}>
                    <CreditCardOutlined />
                    <span>Способы оплаты</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.claimIntegrationList}>
                    <SnippetsOutlined />
                    <span>Настройки заявок</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.notificationIntegrationList}>
                    <SettingOutlined />
                    <span>Настройки уведомлений</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.goodModelList}>
                    <SkinOutlined />
                    <span>Модели товаров</span>
                </Menu.Item>
                <Menu.Item key={enumTypesLinks.logout}>
                    <LogoutOutlined />
                    <span>Выход</span>
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default AdminMenu;
