import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { enumResources, resource } from '../../../utils/constants';
import { getCrudItem } from '../../../store/Crud/selectors';
import { IShopAddress } from '../../../store/Crud/types';
import { create, update } from '../../../store/Crud';
import { createShopAddressLink } from '../../../components/Linker/PathCreators';
import { useAppDispatch } from '../../../store';
import { SHOP_ADDRESS_FIELDS } from '../../../store/Crud/types/shopAddress';

interface IShopAddressEdit {
    shopId: number;
    itemId: string;
}

const ShopAddressEdit: FC<IShopAddressEdit> = ({ shopId, itemId }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const shopAddress = useSelector(getCrudItem(enumResources.SHOP_ADDRESS));

    const fields = {
        title: '',
        description: '',
        lat: '',
        lon: '',
        pickupPoint: '',
        externalId: '',
    };

    const requiredFields = ['title', 'description'];

    const onSave = async (item: IShopAddress) => {
        const formattedShopAddress = {
            ...item,
            [SHOP_ADDRESS_FIELDS.lat]: Number(item[SHOP_ADDRESS_FIELDS.lat]),
            [SHOP_ADDRESS_FIELDS.lon]: Number(item[SHOP_ADDRESS_FIELDS.lon]),
        };

        if (item.id) {
            dispatch(update(resource.SHOP_ADDRESS, String(item.id), formattedShopAddress)());
        } else {
            const { payload } = await dispatch(create(resource.SHOP_ADDRESS, formattedShopAddress)());
            if (payload?.data?.id) {
                history.push(createShopAddressLink(String(shopId), payload?.data?.id));
            }
        }
    };

    const item =
        shopAddress && String(shopAddress.id) === itemId
            ? shopAddress
            : {
                  [SHOP_ADDRESS_FIELDS.pickupPoint]: false,
              };

    return (
        <ItemEdit
            item={item}
            fields={fields}
            onSave={onSave}
            additionalFields={{ shopId }}
            requiredFields={requiredFields}
        />
    );
};

export default ShopAddressEdit;
