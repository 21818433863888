import React, { FC } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';

interface IProperty {
    id: string;
    value: string;
}

interface IRecord {
    key: number;
    item: IProperty;
}

interface IGoodPropertyValueList {
    value?: IProperty[];
    onChange?: (event: any) => void;
    disabled?: boolean;
}

const GoodPropertyValueList: FC<IGoodPropertyValueList> = ({ value = [], onChange, disabled }) => {
    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'key',
            width: '100px',
            render: (key: number, record: IRecord) => (
                <Input defaultValue={record.item.id} disabled={disabled} onChange={(e) => onChangeRow('id', key, e)} />
            ),
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'key',
            width: '100px',
            render: (key: number, record: IRecord) => (
                <Input
                    defaultValue={record.item.value}
                    disabled={disabled}
                    onChange={(e) => onChangeRow('value', key, e)}
                />
            ),
        },
        {
            title: t(enumFieldName.remove),
            dataIndex: 'key',
            width: '100px',
            render: (key: number) => (
                <Button icon={<CloseOutlined />} disabled={disabled} onClick={() => onRemoveItem(key)} />
            ),
        },
    ];

    const onChangeRow = (fieldName: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const resultArr = value.map((itemValue, indexItem) =>
            indexItem === index ? { ...itemValue, [fieldName]: e.target.value } : itemValue,
        );
        triggerChange(resultArr);
    };

    const onRemoveItem = (index: number) => {
        triggerChange(value.filter((elem, i) => i !== index));
    };

    const triggerChange = (changedValue: IProperty[]) => {
        if (onChange) {
            onChange(changedValue);
        }
    };

    const addRow = () => {
        triggerChange([...value, { id: '', value: '' }]);
    };

    const items = value ? value.map((item, key) => ({ key, item })) : [];

    return (
        <div>
            <Table
                size="small"
                pagination={false}
                rowKey={(record) => record.key}
                dataSource={items}
                columns={columns}
            />
            <Button disabled={disabled} icon={<PlusOutlined />} onClick={() => addRow()} />
        </div>
    );
};

export default GoodPropertyValueList;
