import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getCrudItem } from '../../../store/Crud/selectors';
import { DICTIONARY_ITEM_FIELDS, IDictionaryItem, IDictionaryItemForm } from '../../../store/Crud/types/dictionaryItem';
import { useAppDispatch } from '../../../store';
import { create, get, update } from '../../../store/Crud';

interface IDictionaryItemEdit {
    shopId: number;
    dictionaryItemId: string;
    dictionaryId: string;
    onSave: (arg: IDictionaryItem) => void;
}

const DictionaryItemEdit: FC<IDictionaryItemEdit> = ({ shopId, dictionaryItemId, dictionaryId, onSave }) => {
    const dispatch = useAppDispatch();

    const dictionary = useSelector(getCrudItem(resource.DICTIONARY));
    const dictionaryItem = useSelector(getCrudItem(resource.DICTIONARY_ITEM));

    const fields = {
        title: '',
        description: '',
        data: [],
        externalId: '',
    };

    const onSubmit = async (item: IDictionaryItemForm) => {
        const data: { [x: string]: any } = {};
        dictionary.fields.forEach((field: any) => {
            data[field] = item?.[field] ? item[field] : '';
        });

        const submitDictionaryItem = { ...item, data };

        if (item.id) {
            await dispatch(update(resource.DICTIONARY_ITEM, String(item.id), submitDictionaryItem)());
        } else {
            const { payload } = await dispatch(
                create(resource.DICTIONARY_ITEM, {
                    ...submitDictionaryItem,
                    [DICTIONARY_ITEM_FIELDS.dictionaryId]: Number(dictionaryId),
                })(),
            );
            if (payload?.data?.id) {
                onSave(payload?.data);
            }
        }
    };

    useEffect(() => {
        if (dictionaryItemId !== 'new') {
            dispatch(get(resource.DICTIONARY_ITEM, dictionaryItemId, { shopId })());
        }
    }, [dictionaryItemId, dispatch, shopId]);

    const getFields = () => {
        const fieldsOne: { [x: string]: any } = {};

        dictionary.fields.forEach((field: string) => {
            fieldsOne[field] = '';
        });

        return { ...fields, ...fieldsOne };
    };

    const getItem = () => {
        if (dictionaryItem && String(dictionaryItem.id) === dictionaryItemId) {
            const item = { ...dictionaryItem };
            dictionary.fields.forEach((field: string) => {
                item[field] = item.data[field] ? item.data[field] : '';
            });

            return item;
        } else {
            return {};
        }
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <ItemEdit
            item={getItem()}
            fields={getFields()}
            subjectName="dictionary"
            onSave={onSubmit}
            additionalFields={{ shopId }}
        />
    );
};

export default DictionaryItemEdit;
