import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import ShopClientEdit from './components/ShopClientEdit';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { createShopClientLink } from '../../components/Linker/PathCreators';
import { getShopId } from '../../store/System/selectors';
import { IClient } from '../../store/Crud/types/client';

const ShopClientEditPage = () => {
    const history = useHistory();
    const { id: clientId } = useParams<{ id: string }>();

    const shopId = useSelector(getShopId);
    const [title, setTitle] = useState('Новый');

    const onSave = (item: IClient) => {
        if (item) {
            history.push(createShopClientLink(String(shopId), String(item.id)));
        }
    };

    if (shopId === 0) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.shopClientList}>Клиенты</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ShopClientEdit
                    shopId={shopId}
                    itemId={clientId}
                    onSave={onSave}
                    onFormLoad={(data: string) => setTitle(data)}
                />
            </div>
        </div>
    );
};

export default ShopClientEditPage;
