export enum CLIENT_FIELDS {
    createdAt = 'createdAt',
    customerId = 'customerId',
    description = 'description',
    email = 'email',
    firstName = 'firstName',
    id = 'id',
    lastName = 'lastName',
    shopId = 'shopId',
    priceIds = 'priceIds',
    phone = 'phone',
}

export interface IClient {
    [CLIENT_FIELDS.createdAt]: string;
    [CLIENT_FIELDS.customerId]: number;
    [CLIENT_FIELDS.description]: string;
    [CLIENT_FIELDS.email]: string;
    [CLIENT_FIELDS.firstName]: string;
    [CLIENT_FIELDS.id]: number;
    [CLIENT_FIELDS.lastName]: string;
    [CLIENT_FIELDS.priceIds]: number[];
    [CLIENT_FIELDS.shopId]: number;
    [CLIENT_FIELDS.phone]: string;
}

export enum CUSTOMER_FIELDS {
    anonymous = 'anonymous',
    deliveryAddressId = 'deliveryAddressId',
    email = 'email',
    firstName = 'firstName',
    id = 'id',
    isEmailConfirmed = 'isEmailConfirmed',
    lastName = 'lastName',
    legalName = 'legalName',
    neededPasswordUpdate = 'neededPasswordUpdate',
    phone = 'phone',
}

export interface ICustomer {
    [CUSTOMER_FIELDS.anonymous]: boolean;
    [CUSTOMER_FIELDS.deliveryAddressId]: number;
    [CUSTOMER_FIELDS.email]: string;
    [CUSTOMER_FIELDS.firstName]: string;
    [CUSTOMER_FIELDS.id]: number;
    [CUSTOMER_FIELDS.isEmailConfirmed]: boolean;
    [CUSTOMER_FIELDS.lastName]: string;
    [CUSTOMER_FIELDS.legalName]: string;
    [CUSTOMER_FIELDS.neededPasswordUpdate]: boolean;
    [CUSTOMER_FIELDS.phone]: string;
}
