import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'antd';
import { withRouter } from 'react-router';
import GoodList from '../components/GoodList';
import { createGoodLink } from '../../components/Linker/PathCreators';

const GoodListPage = (props) => {
    const goToItem = (item) => props.history.push(createGoodLink(props.shopId, item.id));

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>Товарные позиции</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                {props.shopId ? <GoodList shopId={props.shopId} onClick={goToItem.bind(this)} /> : ''}
            </div>
        </div>
    );
};

export default withRouter(
    connect(
        (state) => ({
            shopId: state.system.shopId,
        }),
        {},
    )(GoodListPage),
);
