import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { IShopAddress } from '../../../store/Crud/types';

interface IShopAddressProps {
    shopAddressId: number;
}

const ShopAddress: FC<IShopAddressProps> = ({ shopAddressId }) => {
    const shopAddresses: IShopAddress[] = useSelector(getCrudItems(resource.SHOP_ADDRESS)) as any;

    const getShopTitle = (id: number) => {
        if (shopAddresses.length === 0) {
            return '';
        }

        const shopAddress = shopAddresses.find((elem) => elem.id === id);

        return shopAddress ? shopAddress.title : '';
    };

    return <> {getShopTitle(shopAddressId)}</>;
};

export default ShopAddress;
