export enum MODEL_FIELDS {
    id = 'id',
    title = 'title',
    description = 'description',
    externalId = 'externalId',
    public = 'public',
    position = 'position',
    imageId = 'imageId',
    parentId = 'parentId',
    parentModelId = 'parentModelId',
    shopId = 'shopId',
    path = 'path',
}

export interface IModel {
    [MODEL_FIELDS.id]: number;
    [MODEL_FIELDS.title]: string;
    [MODEL_FIELDS.description]: string;
    [MODEL_FIELDS.externalId]: string;
    [MODEL_FIELDS.public]: boolean;
    [MODEL_FIELDS.path]: string;
    [MODEL_FIELDS.position]: number;
    [MODEL_FIELDS.imageId]: number;
    [MODEL_FIELDS.parentId]: number;
    [MODEL_FIELDS.parentModelId]: number;
    [MODEL_FIELDS.shopId]: number;
}
