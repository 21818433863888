import React from 'react';
import { Button, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AuthError from '../Auth/components/AuthError';
import { validation } from '../Auth/validation';
import {resource, smallFormLayout, tailSmallFormLayout} from '../../utils/constants';
import { useAppDispatch } from '../../store';
import { checkSession } from '../../store/Auth';
import { getCrudError, getCrudItem, isCrudWait } from '../../store/Crud/selectors';
import { create, update } from '../../store/Crud';
import {createLoginLink} from "../../components/Linker/PathCreators";

const RestorePasswordCard = () => {
    const dispatch = useAppDispatch();

    const wait = useSelector(isCrudWait(resource.CODE));
    const code = useSelector(getCrudItem(resource.CODE));
    const error = useSelector(getCrudError(resource.CODE));

    const [form] = Form.useForm();
    const step2 = code?.id > 0;

    const submit = async (data: { code: string; phone: string }) => {
        if (step2) {
            await dispatch(update(resource.CODE, code.id, { code: data.code })());
            await dispatch(checkSession());
        } else {
            dispatch(create(resource.CODE, { phone: data.phone, type: 'restore' })());
        }
    };

    const renderInputByStep = () => {
        if (step2) {
            return (
                <Form.Item label="Код из смс" name="code" {...smallFormLayout} rules={[validation.require]}>
                    <Input />
                </Form.Item>
            );
        }
        return (
            <Form.Item
                label="Телефон"
                name="phone"
                {...smallFormLayout}
                rules={[validation.require, validation.phoneRu]}
            >
                <Input placeholder="7 900 000 00 00" />
            </Form.Item>
        );
    };

    return (
        <div className="auth-form">
            <h2>Восстановление пароля</h2>
            <div className="row">
                <AuthError errorCode={error?.code} />
            </div>
            <Form onFinish={submit} form={form}>
                {renderInputByStep()}
                <Form.Item {...tailSmallFormLayout}>
                    <Button
                        icon={wait ? <LoadingOutlined /> : null}
                        disabled={wait}
                        className="login-btn"
                        htmlType="submit"
                    >
                        {step2 ? 'Проверить' : 'Отправить код'}
                    </Button>
                </Form.Item>
            </Form>
            <div className="row">
                <Link className="forgot_password" to={createLoginLink()}>
                    Я помню свой пароль
                </Link>
            </div>
        </div>
    );
};

export default RestorePasswordCard;
