import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../store';
import { logout } from '../../store/Auth';
import { getCurrentUserData } from '../../store/Auth/selectors';

const Logout = () => {
    const dispatch = useAppDispatch();
    const waitResponse = useSelector(getCurrentUserData)?.waitResponse;

    const onClickLogoutButton = () => {
        dispatch(logout());
    };

    return (
        <div className="auth-form">
            <Button
                icon={waitResponse ? <LoadingOutlined /> : null}
                disabled={waitResponse}
                className="login-btn"
                htmlType="submit"
                onClick={onClickLogoutButton}
            >
                Выйти из системы
            </Button>
        </div>
    );
};

export default Logout;
