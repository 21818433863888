import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, Modal} from "antd";
import Api from "../../actions/Api";
import {resource} from "../../../utils/constants";
import {LoadingOutlined} from "@ant-design/icons";

const GoodListSelector = ({shopId, sourceGoodId}) => {
    const [loader, setLoader] = useState(false)
    const [loaderSave, setLoaderSave] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [query, setQuery] = useState('');
    const [queryRaw, setQueryRaw] = useState('');
    const [timer, setTimer] = useState(null);
    const [goodList, setGoodList] = useState([])
    const [targetGoodIds, setTargetGoodIds] = useState([])

    useEffect(() => {
        if (query) {
            setGoodList([]);
            setLoader(true)
            Api.find(resource.GOOD, {_query: query, shopId})
                .then(([goods]) => {
                    setLoader(false)
                    setGoodList(goods)
                });
        } else {
            setGoodList([]);
        }


    }, [query]);

    const renderItem = (item) => {
        return (
            <div key={item.id}>
                <Checkbox value={item.id}>
                    #{item.id} {item.title}
                </Checkbox>
            </div>
        );
    }

    const onSave = () => {
        setLoaderSave(true);
        Api.create(resource.MODEL_PROPERTY_COPY, {
            sourceGoodId,
            targetGoodIds
        }).then(() => {
            setLoaderSave(false)
            setShowModal(false);
        })
    }

    const onQueryChange = (e) => {
        const changedQuery = e.target.value
        setQueryRaw(changedQuery)
        clearTimeout(timer);
        setTimer(setTimeout(() => setQuery(changedQuery), 1000));
    }

    return (
        <>
            <p>Вы можете копировать свойства этого товара в другие товары</p>
            <Button type="secondary" onClick={() => setShowModal(true)}>Выбрать товары</Button>
            <Modal title="Выберете товары, которые нужно заполнить" visible={showModal} footer={''}
                   onCancel={() => setShowModal(false)}>
                <Input prefix={loader ? <LoadingOutlined/> : null} onChange={onQueryChange} value={queryRaw}/>
                <Checkbox.Group onChange={setTargetGoodIds} style={{marginTop: '10px'}}>
                    {goodList.map(item => renderItem(item))}
                </Checkbox.Group>
                <div style={{margin: '5px auto'}}>
                    <Button icon={loaderSave ? <LoadingOutlined/> : null} disabled={targetGoodIds.length === 0} type="primary" onClick={onSave}>Заполнить</Button>
                </div>
            </Modal>
        </>
    );
}

export default GoodListSelector;