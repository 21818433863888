import React, { FC } from 'react';
import ImgEdit from '../../../components/ImgEdit';
import { enumTypeImage, IRequestUploadImageShop } from '../../../store/File/types';
import { uploadShopFile } from '../../../store/File';
import { useAppDispatch } from '../../../store';
import { IShop } from '../../../store/Crud/types';
import { shopUpdateImage } from '../../../store/Crud';
import { resource } from '../../../utils/constants';

interface IShopImg {
    isOgImage?: boolean;
    item: IShop;
}

const ShopImg: FC<IShopImg> = ({ item, isOgImage = false }) => {
    const dispatch = useAppDispatch();

    const typeImage = isOgImage ? enumTypeImage.OG_IMAGE : enumTypeImage.IMAGE;
    const path = isOgImage ? item?.ogImagePath : item?.imagePath;

    if (!item) {
        return null;
    }

    const uploadImage = async (image: any) => {
        const { payload }: any = await dispatch(
            uploadShopFile({ uploadFile: image, shopId: item.id, type: typeImage }),
        );
        if (payload?.data?.data) {
            const infoAboutNewImage: IRequestUploadImageShop = payload?.data.data as any;
            dispatch(
                shopUpdateImage(resource.SHOP)(
                    infoAboutNewImage.id,
                    infoAboutNewImage.path,
                    infoAboutNewImage.type,
                    infoAboutNewImage.shopId,
                ),
            );
        }
    };

    return <ImgEdit imagePath={path} uploadImage={uploadImage} />;
};

export default ShopImg;
