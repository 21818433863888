import React, { FC, useCallback, useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { resource } from '../../../utils/constants';
import Linker from '../../../components/Linker';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { IClient } from '../../../store/Crud/types/client';

interface IShopClientDetail {
    id: number;
}

const ShopClientDetail: FC<IShopClientDetail> = ({ id }) => {
    const dispatch = useAppDispatch();

    const [shopClient, setShopClient] = useState<IClient>();

    const fetchClient = useCallback(async () => {
        const { payload } = await dispatch(find(resource.SHOP_CLIENT, { id, _extend: [resource.PROFILE] })());
        if (payload && payload?.data) {
            if (payload.data.length > 0) {
                setShopClient(payload.data[0]);
            }
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (id) {
            fetchClient();
        }
    }, [fetchClient, id]);

    if (!shopClient) {
        return null;
    }

    return (
        <div>
            <h4>
                <Linker type={enumTypesLinks.shopClient} itemId={String(shopClient.id)}>
                    Клиент
                </Linker>
            </h4>
            <div>
                <span className="label">{t(enumFieldName.id)}: </span>
                <span className="value">{shopClient.id}</span>
            </div>
            <div>
                <span className="label">{t(enumFieldName.firstName)}: </span>
                <span className="value">{shopClient.firstName}</span>
            </div>
            <div>
                <span className="label">{t(enumFieldName.lastName)}: </span>
                <span className="value">{shopClient.lastName}</span>
            </div>
            <div>
                <span className="label">{t(enumFieldName.email)}: </span>
                <span className="value">{shopClient.email}</span>
            </div>
        </div>
    );
};

export default ShopClientDetail;
