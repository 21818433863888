import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { resource } from '../../../utils/constants';
import GoodPriceList from './GoodPriceList';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { getCrudItems } from '../../../store/Crud/selectors';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';

interface ILegalPriceList {
    onClick: (arg: any) => void;
    shopId: number;
}

const LegalPriceList: FC<ILegalPriceList> = ({ onClick, shopId }) => {
    const dispatch = useAppDispatch();

    const legalPrices = useSelector(getCrudItems(resource.LEGAL_PRICE));

    useEffect(() => {
        dispatch(find(resource.LEGAL_PRICE, { shopId, _order: { id: 'desc' } })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '100px',
        },
        {
            title: t(enumFieldName.inn),
            dataIndex: 'inn',
            key: 'inn',
            width: '110px',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title',
            width: '110px',
        },
        {
            title: t(enumFieldName.phones),
            dataIndex: 'phones',
            key: 'phones',
            width: '10%',
            render: (phones: string[]) => phones.join(', '),
        },
        {
            title: t(enumFieldName.priceIds),
            dataIndex: 'priceIds',
            key: 'priceIds',
            width: '10%',
            render: (priceIds: number[]) => <GoodPriceList ids={priceIds} />,
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={legalPrices}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default LegalPriceList;
