import withQuery from 'with-query';
import { baseApiUrl } from '../../utils/http';
import { showError } from '../../utils/helpers';

export const headers = {
    'Content-Type': 'application/json',
    'X-Suppress-HTTP-Code': true,
};

export default class Api {
    static find(resource, params) {
        const url = baseApiUrl + resource;
        return fetch(withQuery(url, params), {
            method: 'GET',
            credentials: 'include',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    showError(json.error);
                }
                return [json.data, json.extendedData ?? [], json.error || null];
            })
            .catch((err) => {
                showError(err);
                throw err;
            });
    }

    static view(resource, id) {
        const url = `${baseApiUrl + resource}/${id}`;
        return fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    showError(json.error);
                }
                return json;
            })
            .catch((err) => {
                showError(err);
                throw err;
            });
    }

    static update(resource, id, body) {
        let url = baseApiUrl + resource;
        if (id) {
            url = `${url}/${id}`;
        }

        return fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers,
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    showError(json.error);
                }
                return [json.data, json.extendedData ?? [], json.error || null];
            })
            .catch((error) => {
                showError(error);
            });
    }

    static delete(resource, id) {
        const url = baseApiUrl + resource + (id ? `/${id}` : '');
        return fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    showError(json.error);
                }
                return [json.data, json.extendedData ?? [], json.error || null];
            })
            .catch((error) => {
                showError(error);
            });
    }

    static create(resource, body) {
        const url = baseApiUrl + resource;
        return fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers,
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    showError(json.error);
                }
                return [json.data, json.extendedData ?? [], json.error || null];
            })
            .catch((error) => {
                showError(error);
            });
    }

    static extractExtendData(extendData, resourceName) {
        const items = extendData.find((item) => item.entity === resourceName);

        return items ? items.data : [];
    }

    static extractExtendDataFirstItem(extendData, resourceName) {
        const result = this.extractExtendData(extendData, resourceName);

        return result ? result[0] : null;
    }

    static extractCollectionField(items, id, fieldName) {
        const item = items.find((item) => item.id === id);

        return item ? item[fieldName] : '';
    }
}
