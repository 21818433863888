import React, { FC } from 'react';

interface IErrorMessage {
    error: {
        code: string;
    };
}

const ErrorMessage: FC<IErrorMessage> = ({ error }) => {
    if (!error) {
        return null;
    }
    return <b style={{ color: 'red' }}>Ошибка: {error.code}</b>;
};

export default ErrorMessage;
