import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    createCrudItemRequest,
    findCrudItemsRequest,
    removeCrudItemRequest,
    updateCrudItemRequest,
    updateCrudStatusItemRequest,
} from './http';
import { thunkHandler } from '../../utils/helpers';
import { enumResources } from '../../utils/constants';
import { AppDispatch } from '../index';
import { enumTypeImage } from '../File/types';
import { SHOP_FIELDS } from './types';

export interface ICrudState {
    items: any[];
    dictionary: any[];
    item: any;
    awaitLoadItem: boolean;
    awaitLoadList: boolean;
    awaitCreateItem: boolean;
    awaitDeleteItem: boolean;
    awaitUpdateItem: boolean;
    error: any;
}

const initialState = {
    items: [],
    dictionary: [],
    item: null,
    awaitLoadItem: false,
    awaitLoadList: false,
    awaitCreateItem: false,
    awaitDeleteItem: false,
    awaitUpdateItem: false,
    error: {},
} as ICrudState;

const processExtendData = (extendData: any, dispatch: AppDispatch, resourceName: enumResources | string) => {
    if (extendData) {
        extendData.forEach((extendedContainer: any) => {
            dispatch(setDictionary(resourceName)(extendedContainer));
        });
    }
};

export const remove = (resourceName: enumResources, id?: string) =>
    createAsyncThunk(`${resourceName}/remove`, async (args, thunkApi) => {
        if (id) {
            const data = await thunkHandler(removeCrudItemRequest(resourceName, id), thunkApi, true);
            if (data?.data === null) {
                processExtendData(data?.extendedData, thunkApi.dispatch as AppDispatch, resourceName);
                return id;
            }
        }
    });

export const get = (resourceName: enumResources, id?: string, query?: any) =>
    createAsyncThunk(`${resourceName}/get`, async (args, thunkApi) => {
        if (id) {
            const data = await thunkHandler(findCrudItemsRequest(resourceName, { id, ...query }), thunkApi, true);
            processExtendData(data?.extendedData, thunkApi.dispatch as AppDispatch, resourceName);
            return data;
        }
    });

export const find = (resourceName: enumResources | string, query?: any) =>
    createAsyncThunk(`${resourceName}/find`, async (args, thunkApi) => {
        const data = await thunkHandler(findCrudItemsRequest(resourceName, query), thunkApi, true);
        processExtendData(data?.extendedData, thunkApi.dispatch as AppDispatch, resourceName);
        return data;
    });

export const create = (resourceName: enumResources | string, payload?: any) =>
    createAsyncThunk(`${resourceName}/create`, async (args, thunkApi) => {
        const data = await thunkHandler(createCrudItemRequest(resourceName, payload), thunkApi, true);
        processExtendData(data?.extendedData, thunkApi.dispatch as AppDispatch, resourceName);
        return data;
    });

export const update = (resourceName: enumResources | string, id?: string, payload?: any, isEditStatus = false) =>
    createAsyncThunk(`${resourceName}/update`, async (args, thunkApi) => {

        if (id) {
            const data = isEditStatus
                ? await thunkHandler(updateCrudStatusItemRequest(resourceName, id, payload), thunkApi, true)
                : await thunkHandler(updateCrudItemRequest(resourceName, id, payload), thunkApi, true);
            processExtendData(data?.extendedData, thunkApi.dispatch as AppDispatch, resourceName);
            return data;
        }
    });

export const clear = (resourceName: enumResources) =>
    createAction(`${resourceName}/clear`, () => ({
        payload: null,
    }));

export const setDictionary = (resourceName: enumResources | string) =>
    createAction(`${resourceName}/setDictionary`, (items: any) => ({
        payload: items,
    }));

export const shopUpdateImage = (resourceName: enumResources) =>
    createAction(
        `${resourceName}/shopUpdateImage`,
        (imageId: number, path: string, type: enumTypeImage, shopId: string) => ({
            payload: {
                imageId,
                path,
                type,
                shopId,
            },
        }),
    );

export const setItem = (resourceName: enumResources) =>
    createAction(`${resourceName}/setItem`, (item: any) => ({
        payload: {
            item,
        },
    }));

const stateGenerator = (name: enumResources) => {
    const crud = createSlice({
        name,
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            // clear
            builder.addCase(clear(name), (state) => {
                state.items = [];
                state.item = null;
            });

            // setItem
            builder.addCase(setItem(name), (state, { payload }) => {
                state.item = payload;
            });

            // setDictionary
            builder.addCase(setDictionary(name), (state, { payload }) => {
                state.dictionary = payload;
            });

            // shopUpdateImage
            builder.addCase(shopUpdateImage(name), (state, { payload }) => {
                const isImageUpdate = payload.type === enumTypeImage.IMAGE;
                state.item = {
                    ...state.item,
                    [isImageUpdate ? SHOP_FIELDS.imageId : SHOP_FIELDS.ogImageId]: payload.imageId,
                    [isImageUpdate ? SHOP_FIELDS.imagePath : SHOP_FIELDS.ogImagePath]: payload.path,
                };
            });

            // get
            builder.addCase(get(name).pending, (state) => {
                state.awaitLoadItem = true;
            });
            builder.addCase(get(name).rejected, (state) => {
                state.awaitLoadItem = false;
            });
            builder.addCase(get(name).fulfilled, (state, { payload }) => {
                const [crudData] = payload.data;
                state.item = crudData;
                state.awaitLoadItem = false;
            });

            // remove
            builder.addCase(remove(name).pending, (state) => {
                state.awaitDeleteItem = true;
            });
            builder.addCase(remove(name).rejected, (state) => {
                state.awaitDeleteItem = false;
            });
            builder.addCase(remove(name).fulfilled, (state, { payload }) => {
                state.items = state.items.filter((item) => String(item.id) !== String(payload));
                if (String(state.item?.id) === String(payload)) {
                    state.item = null;
                }
                state.awaitDeleteItem = false;
            });

            // find
            builder.addCase(find(name).pending, (state) => {
                state.awaitLoadList = true;
            });
            builder.addCase(find(name).rejected, (state) => {
                state.awaitLoadList = false;
            });
            builder.addCase(find(name).fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.awaitLoadList = false;
            });

            // create
            builder.addCase(create(name).pending, (state) => {
                state.awaitCreateItem = true;
            });
            builder.addCase(create(name).rejected, (state, { payload }) => {
                state.awaitCreateItem = false;
                // @ts-ignore
                state.error = payload?.error;
            });
            builder.addCase(create(name).fulfilled, (state, { payload }) => {
                state.awaitCreateItem = false;
                state.item = payload.data;
                state.items.unshift(payload.data);
            });

            // update
            builder.addCase(update(name).pending, (state) => {
                state.awaitUpdateItem = true;
            });
            builder.addCase(update(name).rejected, (state, { payload }) => {
                state.awaitUpdateItem = false;
                // @ts-ignore
                state.error = payload?.error;
            });
            builder.addCase(update(name).fulfilled, (state, { payload }) => {
                state.awaitUpdateItem = false;
                state.item = payload.data;
                state.items = state.items.map((item) => (item.id === payload?.data?.id ? payload.data : item));
            });
        },
    });

    return crud.reducer;
};

export default stateGenerator;
