import React, { useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Button, Collapse, Divider, Form, List } from 'antd';
import GoodListSelector from './GoodListSelector';
import Property from '../../../components/Property';

const GoodShopPropertyEditor = (props) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (values) => {
        setIsSubmitting(true);
        await props.onSave(Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== '')));
        setIsSubmitting(false);
    };
    const groupName = '';

    const { goodModel } = props;
    const items = props.goodModelProperties.sort((a, b) => (a.groupName < b.groupName ? 1 : -1));

    return (
        <div>
            <h3>Модель: {goodModel.title}</h3>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                form={form}
                initialValues={props.good.manualProperties}
                onFinish={handleSubmit}
            >
                {items.map((record) => (
                    <Property
                        key={record?.id}
                        integrationProperties={props.good.integrationProperties}
                        manualProperties={props.good.manualProperties}
                        record={record}
                        groupName={groupName}
                    />
                ))}
                <Form.Item>
                    <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
            <GoodListSelector shopId={props.good.shopId} sourceGoodId={props.good.id} />
            <Divider />
            <Collapse>
                <Collapse.Panel header="Значения из выгрузки">
                    <List
                        size="small"
                        bordered
                        dataSource={props.good.importedProperties}
                        renderItem={(item) => (
                            <List.Item>
                                <b>{item.id}</b>: {item.value}
                            </List.Item>
                        )}
                    />
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default GoodShopPropertyEditor;
