import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { getCrudItem } from '../../../store/Crud/selectors';
import { ILegalPrice, LEGAL_PRICE_FIELDS } from '../../../store/Crud/types/legalPrice';
import { useAppDispatch } from '../../../store';
import { create, get, update } from '../../../store/Crud';

interface ILegalPriceEdit {
    shopId: number;
    onSave: (arg: ILegalPrice) => void;
    itemId: string;
}

const LegalPriceEdit: FC<ILegalPriceEdit> = ({ shopId, onSave, itemId }) => {
    const dispatch = useAppDispatch();
    const isNewLegalPrice = itemId === 'new';

    const legalPrice = useSelector(getCrudItem(resource.LEGAL_PRICE));

    const fields = {
        inn: '',
        title: '',
        priceIds: [],
        phones: [],
    };

    const requiredFields = ['inn', 'title', 'priceIds', 'phones'];

    const onSubmit = async (item: ILegalPrice) => {
        const validLegalPrice = { ...item, [LEGAL_PRICE_FIELDS.inn]: Number(item.inn) };
        if (item.id) {
            await dispatch(update(resource.LEGAL_PRICE, String(item.id), validLegalPrice)());
        } else {
            const { payload } = await dispatch(create(resource.LEGAL_PRICE, validLegalPrice)());
            if (payload?.data?.id) {
                onSave(payload?.data);
            }
        }
    };

    useEffect(() => {
        if (!isNewLegalPrice) {
            dispatch(get(resource.LEGAL_PRICE, itemId, { shopId })());
        }
    }, [dispatch, isNewLegalPrice, itemId, shopId]);

    const item = legalPrice && String(legalPrice?.id) === itemId ? legalPrice : {};

    if (shopId === 0 || (isNewLegalPrice ? false : String(legalPrice?.id) !== itemId)) {
        return <Loading />;
    }

    return (
        <div>
            <ItemEdit
                item={item}
                fields={fields}
                onSave={onSubmit}
                additionalFields={{ shopId }}
                requiredFields={requiredFields}
            />
        </div>
    );
};

export default LegalPriceEdit;
