import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CloseOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { find } from '../actions/crud';
import { resource } from '../../utils/constants';
import GoodShopPropertyEdit from './goodModel/GoodShopPropertyMapEdit';
import { t } from '../../utils/helpers/formatFieldsLabels';

class GoodShopPropertyList extends Component {
    state = { current: null };

    componentWillReceiveProps(props) {
        if (props.shopId !== this.props.shopId && props.shopId) {
            this.componentWillMount();
        }
    }

    componentWillMount() {
        if (this.props.shopId) {
            this.props.find(resource.GOOD_SHOP_PROPERTY, {
                shopId: this.props.shopId,
                _extend: resource.MODEL_PROPERTY,
                _order: { id: 'desc' },
            });
        }
    }

    changeCurrent(record) {
        this.setState({ current: record ? record.id : null });
    }

    columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            width: '50px',
        },
        {
            title: t('title'),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t('public'),
            dataIndex: 'public',
            key: 'public',
            width: '10%',
            render: (item) => (item ? 'Да' : 'Нет'),
        },
        {
            title: t('externalId'),
            dataIndex: 'externalId',
            key: 'externalId',
            width: '10%',
        },
        {
            title: t('modelId'),
            dataIndex: 'modelPropertyId',
            key: 'modelId',
            width: '10%',
            render: (modelPropertyId) => {
                const goodProperty = this.props.goodProperties.find((elem) => elem.id === modelPropertyId);
                if (!goodProperty) {
                    return '';
                }

                const goodModel = this.props.goodModels.find((elem) => elem.id === goodProperty.modelId);

                return goodModel ? `#${goodModel.id} ${goodModel.title}` : '';
            },
        },
        {
            title: t('modelPropertyId'),
            dataIndex: 'modelPropertyId',
            key: 'modelPropertyId',
            width: '10%',
            render: (modelPropertyId) => {
                const goodProperty = this.props.goodProperties.find((elem) => elem.id === modelPropertyId);
                return goodProperty ? `#${goodProperty.id} ${goodProperty.title}` : '';
            },
        },
    ];

    render() {
        return (
            <Table
                rowKey="id"
                dataSource={[{ id: '', shopId: this.props.shopId }, ...this.props.items]}
                columns={this.columns}
                expandedRowKeys={[this.state.current]}
                expandedRowRender={(record) => (
                    <GoodShopPropertyEdit item={record} onSave={(item) => this.changeCurrent(item)} />
                )}
                onExpand={(ex, record) => this.changeCurrent(ex ? record : null)}
                expandIcon={({ expanded, onExpand, record }) => {
                    let Button = CloseOutlined;
                    if (!expanded) {
                        Button = record.id === '' ? PlusOutlined : EditOutlined;
                    }

                    return <Button onClick={(e) => onExpand(record, e)} />;
                }}
            />
        );
    }
}

export default connect(
    (state) => ({
        items: state.goodShopProperty.items,
        goodProperties: state['model-property'].items,
        goodModels: state.model.items,
    }),
    {
        find,
    },
)(GoodShopPropertyList);
