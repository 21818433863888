import React, { useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Button, Form } from 'antd';
import _ from 'lodash';
import RenderFormItem from './components/RenderFormItem';
import { tailFormItemLayout } from '../../utils/constants';

const FormItem = Form.Item;

const ItemEdit = ({ disabledFields = {}, requiredFields = [], hiddenFields = [], ...props }: any) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (values: { [key: string]: string | number }) => {
        setIsSubmitting(true);
        await props.onSave(_.merge(values, props.additionalFields || {}));
        setIsSubmitting(false);
    };
    const item = { ...props.fields, ...props.item };

    useEffect(() => form.resetFields());

    return (
        <Form onFinish={handleSubmit} form={form}>
            {props.deletable && !_.isEmpty(props.item) && !item.deleted && (
                <FormItem {...tailFormItemLayout}>
                    <Button onClick={() => props?.onRemove(item)}>Удалить</Button>
                </FormItem>
            )}
            {Object.keys(item).map((key) => (
                <RenderFormItem
                    key={key}
                    fieldName={key}
                    value={item[key]}
                    subjectName={props.subjectName}
                    item={item}
                    fields={props.fields}
                    disabledFields={disabledFields}
                    currentItem={props.item}
                    requiredFields={requiredFields}
                    hiddenFields={hiddenFields}
                />
            ))}
            {props.saveable !== false && (_.isEmpty(props.item) || !item.deleted) && (
                <FormItem {...tailFormItemLayout}>
                    <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </FormItem>
            )}
        </Form>
    );
};

export default ItemEdit;
