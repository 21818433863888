export enum LEGAL_PRICE_FIELDS {
    id = 'id',
    inn = 'inn',
    title = 'title',
    shopId = 'shopId',
    priceIds = 'priceIds',
    phones = 'phones',
    createdAt = 'createdAt',
}

export interface ILegalPrice {
    [LEGAL_PRICE_FIELDS.id]: number;
    [LEGAL_PRICE_FIELDS.inn]: number;
    [LEGAL_PRICE_FIELDS.title]: string;
    [LEGAL_PRICE_FIELDS.shopId]: number;
    [LEGAL_PRICE_FIELDS.priceIds]: number[];
    [LEGAL_PRICE_FIELDS.phones]: string[];
    [LEGAL_PRICE_FIELDS.createdAt]: string;
}
