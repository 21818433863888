import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, Tabs } from 'antd';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { resource } from '../../../utils/constants';
import { get } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { getCrudItem } from '../../../store/Crud/selectors';
import { getShopId } from '../../../store/System/selectors';
import Loading from '../../../components/Loading';
import ShopSetting from './ShopSetting';
import ShopProperty from './ShopProperty';

const { TabPane } = Tabs;

interface IShopSettingPage {
    isAdmin?: boolean;
}

enum enumTabs {
    'first' = 'first',
    'second' = 'second',
}

const ShopSettingPage: FC<IShopSettingPage> = ({ isAdmin = false }) => {
    const dispatch = useAppDispatch();
    const { shopId: shopIdFromParams } = useParams<{ shopId: string }>();
    const isNewShop = shopIdFromParams === 'new';
    const shop = useSelector(getCrudItem(resource.SHOP));
    const shopId = useSelector(getShopId);
    const isNeedGetShop = useMemo(() => _.isEmpty(shop) || shop?.id !== shopId, [shop, shopId]);

    useEffect(() => {
        if (!isNewShop && isNeedGetShop) {
            dispatch(get(resource.SHOP, shopIdFromParams)());
        }
    }, [dispatch, isNeedGetShop, isNewShop, shopIdFromParams]);

    const getShopItem = useCallback(() => {
        if (shop?.id === Number(shopIdFromParams) && !isNewShop) {
            return shop;
        }
        return {};
    }, [isNewShop, shop, shopIdFromParams]);

    const item = getShopItem();

    if (isNewShop ? !_.isEmpty(item) : isNeedGetShop) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>{isNewShop ? 'Новый магазин' : 'Настройки'}</Breadcrumb.Item>
            </Breadcrumb>
            <Tabs className="content" defaultActiveKey={enumTabs.first}>
                <TabPane tab="Основные настройки" key={enumTabs.first}>
                    <ShopSetting isAdmin={isAdmin} isNewShop={isNewShop} item={item} />
                </TabPane>
                {!isNewShop && (
                    <TabPane tab="Свойства" key={enumTabs.second}>
                        <ShopProperty shop={item} />
                    </TabPane>
                )}
            </Tabs>
        </div>
    );
};

export default ShopSettingPage;
