import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { packingListId, powerOfAttorneyId, resource } from '../../../utils/constants';
import { dateFormat } from '../../../utils/formatters';
import { orderPaidLabel, orderStatusLabel } from '../../../store/Crud/types/payment';
import { createDocumentLink } from '../../../utils/helpers';
import { IOrder, ORDER_FIELDS } from '../../../store/Crud/types/order';
import { IShopPayment } from '../../../store/Crud/types/shopPayment';
import { find } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import ModalEditStatus from './ModalEditStatus';

interface IOrderDetail {
    order: IOrder;
    fetchOrder: () => void;
}

const OrderDetail: FC<IOrderDetail> = ({ order, fetchOrder }) => {
    const dispatch = useAppDispatch();

    const [shopPayments, setShopPayments] = useState<IShopPayment[]>([]);
    const [isShowModalEditStatus, setIsShowModalEditStatus] = useState(false);

    const fetchShopPayment = useCallback(async () => {
        const { payload } = await dispatch(find(resource.SHOP_PAYMENT, { shopId: order.shopId })());
        if (payload?.data.length > 0) {
            setShopPayments(payload?.data);
        }
    }, [dispatch, order.shopId]);

    useEffect(() => {
        fetchShopPayment();
    }, [fetchShopPayment]);

    const shopPayment: IShopPayment = shopPayments.find((item) => item.id === order.shopPaymentId) as any;

    return (
        <div className="order_info">
            {order.deliveryAddress && (
                <div>
                    <span className="label">Адрес доставки: </span>
                    <span className="value">{order.deliveryAddress.title}</span>
                </div>
            )}
            {order.shopAddress && (
                <div>
                    <span className="label">Самовывоз: </span>
                    <span className="value">{order.shopAddress.title}</span>
                </div>
            )}
            {order.deliveryZone && (
                <div>
                    <span className="label">Зона доставки: </span>
                    <span className="value">{order.deliveryZone.title}</span>
                </div>
            )}
            <div>
                <span className="label">Дата создания: </span>
                <span className="value">{dateFormat(order.createdAt)}</span>
            </div>
            {order.deliveryDate && (
                <div>
                    <span className="label">Дата доставки: </span>
                    <span className="value">{dateFormat(order.deliveryDate)}</span>
                </div>
            )}
            <div>
                <span className="label">Статус: </span>
                <span className="value">
                    {
                        // @ts-ignore
                        orderStatusLabel[String(order.status)] || 'новый'
                    }
                </span>
                <Button onClick={() => setIsShowModalEditStatus(true)} style={{ color: '#1890ff' }} type="text">
                    Изменить
                </Button>
            </div>
            <div>
                <span className="label">Статус оплаты: </span>
                <span className="value">{orderPaidLabel[order[ORDER_FIELDS.paidStatus]]}</span>
            </div>
            <div>
                <span className="label">Разрешена отсрочка: </span>
                <span className="value">{order[ORDER_FIELDS.defermentAllowed] ? 'Да' : 'Нет'}</span>
            </div>
            <div>
                <span className="label">Итого: </span>
                <span className="total_price">{order.amount} руб.</span>
            </div>
            <div>
                <span className="label">Комментарий: </span>
                <span className="value">{order.description}</span>
            </div>
            <div>
                <span className="label">Имя: </span>
                <span className="value">{order.contactFio}</span>
            </div>
            <div>
                <span className="label">Телефон: </span>
                <span className="value">{order.contactPhone}</span>
            </div>
            <div>
                <span className="label">Способ оплаты: </span>
                <span className="value">{shopPayment ? shopPayment.title : ''}</span>
            </div>
            {order.status === 1 && (
                <div>
                    <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={createDocumentLink(String(order.id), String(powerOfAttorneyId))}
                    >
                        Доверенность
                    </a>
                    <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={createDocumentLink(String(order.id), String(packingListId))}
                    >
                        Товарная накладная
                    </a>
                </div>
            )}
            <ModalEditStatus
                fetchOrder={fetchOrder}
                order={order}
                isShow={isShowModalEditStatus}
                hide={() => setIsShowModalEditStatus(false)}
            />
        </div>
    );
};

export default OrderDetail;
