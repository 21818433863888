import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'antd/dist/antd.css';
import './globalStyles/index.css';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { ConfigProvider } from 'antd';
import Pages from './pages';
import store from './store';

if (process.env.NODE_ENV === 'development') {
    store.subscribe(() => {
        // console.log("subscribe", store.getState())
    });
}

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={ruRU}>
            <Pages />
        </ConfigProvider>
    </Provider>,
    document.getElementById('app'),
);
