import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import { update, create } from '../../actions/crud';

class GoodShopPropertyMapEdit extends Component {
    fields = {
        title: '',
        public: false,
        externalId: '',
        modelPropertyId: null,
        mapping: [],
    };

    requiredFields = ['title', 'externalId', 'public', 'modelPropertyId'];

    onSave(item) {
        if (item.id) {
            this.props.update(resource.GOOD_SHOP_PROPERTY, item.id, item);
        } else {
            this.props.create(resource.GOOD_SHOP_PROPERTY, item);
        }
    }

    componentWillReceiveProps(props) {
        if (
            (!props.awaitUpdateItem && this.props.awaitUpdateItem) ||
            (!props.awaitCreateItem && this.props.awaitCreateItem)
        ) {
            if (this.props.onSave) {
                this.props.onSave(props.updatedItem);
            }
        }
    }

    render() {
        return (
            <ItemEdit
                subjectName="goodShopProperty"
                item={this.props.item}
                fields={this.fields}
                onSave={this.onSave.bind(this)}
                requiredFields={this.requiredFields}
            />
        );
    }
}

export default connect(
    (state) => ({
        awaitUpdateItem: state.goodShopProperty.awaitUpdateItem,
        awaitCreateItem: state.goodShopProperty.awaitCreateItem,
        updatedItem: state.goodShopProperty.item,
    }),
    {
        update,
        create,
    },
)(GoodShopPropertyMapEdit);
