export enum enumTypesLinks {
    shop = 'shop',
    shopList = 'shopList',
    deliveryType = 'deliveryType',
    deliveryIntegrationList = 'deliveryIntegrationList',
    paymentIntegrationList = 'paymentIntegrationList',
    goodModel = 'goodModel',
    goodModelList = 'goodModelList',
    goodShopProperty = 'goodShopProperty',
    goodShopPropertyList = 'goodShopPropertyList',
    shopDelivery = 'shopDelivery',
    shopDeliveryList = 'shopDeliveryList',
    shopPayment = 'shopPayment',
    shopPaymentList = 'shopPaymentList',
    shopNotificationList = 'shopNotificationList',
    shopClaimList = 'shopClaimList',
    good = 'good',
    goodList = 'goodList',
    category = 'category',
    categoryList = 'categoryList',
    dictionary = 'dictionary',
    dictionaryList = 'dictionaryList',
    dictionaryItem = 'dictionaryItem',
    dictionaryItemList = 'dictionaryItemList',
    manager = 'manager',
    managerList = 'managerList',
    price = 'price',
    priceList = 'priceList',
    legalPrice = 'legalPrice',
    legalPriceList = 'legalPriceList',
    shopClient = 'shopClient',
    shopClientList = 'shopClientList',
    order = 'order',
    orderList = 'orderList',
    profile = 'profile',
    profileList = 'profileList',
    shopAddress = 'shopAddress',
    shopAddressList = 'shopAddressList',
    apiUser = 'apiUser',
    apiUserList = 'apiUserList',
    file = 'file',
    fileList = 'fileList',
    logout = 'logout',
    login = 'login',
    createResetPassword = 'createResetPassword',
    notificationIntegrationList = 'notificationIntegrationList',
    claimIntegrationList = 'claimIntegrationList',
    shopNotification = 'shopNotification',
    shopNotificationConnection = 'shopNotificationConnection',
    shopClaim = 'shopClaim',
    shopModelList = 'shopModelList',
    shopModel = 'shopModel',
    shopMarketplaceSettingList = 'shopMarketplaceSettingList',
    shopMarketplaceSetting = 'shopMarketplaceSetting',
}
