import {
    SettingOutlined,
    ApartmentOutlined,
    ApiOutlined,
    BookOutlined,
    CarOutlined,
    CreditCardOutlined,
    DollarOutlined,
    EnvironmentOutlined,
    FileOutlined,
    OrderedListOutlined,
    ShareAltOutlined,
    SnippetsOutlined,
    TeamOutlined,
    SkinOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import { enumTypesLinks } from '../../components/Linker/typesLinks';

export const shopNavLinks = [
    {
        key: enumTypesLinks.shop,
        icon: SettingOutlined,
        canDisabled: false,
        title: 'Настройки',
    },
    {
        key: enumTypesLinks.orderList,
        icon: DollarOutlined,
        canDisabled: true,
        title: 'Заказы',
    },
    {
        key: enumTypesLinks.categoryList,
        icon: SnippetsOutlined,
        canDisabled: true,
        title: 'Категории',
    },
    {
        key: enumTypesLinks.goodList,
        icon: FileOutlined,
        canDisabled: true,
        title: 'Товары',
    },
    {
        key: enumTypesLinks.goodShopPropertyList,
        icon: ApiOutlined,
        canDisabled: true,
        title: 'Мэппинг свойств',
    },
    {
        key: enumTypesLinks.managerList,
        icon: TeamOutlined,
        canDisabled: true,
        title: 'Менеджеры',
    },
    {
        key: enumTypesLinks.shopAddressList,
        icon: EnvironmentOutlined,
        canDisabled: true,
        title: 'Торговые точки',
    },

    {
        key: enumTypesLinks.apiUserList,
        icon: ApartmentOutlined,
        canDisabled: true,
        title: 'API пользователи',
    },
    {
        key: enumTypesLinks.shopDeliveryList,
        icon: CarOutlined,
        canDisabled: true,
        title: 'Способы доставки',
    },
    {
        key: enumTypesLinks.shopPaymentList,
        icon: CreditCardOutlined,
        canDisabled: true,
        title: 'Способы оплаты',
    },
    {
        key: enumTypesLinks.shopNotificationList,
        icon: SettingOutlined,
        canDisabled: true,
        title: 'Настройки уведомлений',
    },
    {
        key: enumTypesLinks.shopClaimList,
        icon: SnippetsOutlined,
        canDisabled: true,
        title: 'Настройки заявок',
    },
    {
        key: enumTypesLinks.fileList,
        icon: FileOutlined,
        canDisabled: true,
        title: 'Файлы',
    },
    {
        key: enumTypesLinks.priceList,
        icon: OrderedListOutlined,
        canDisabled: true,
        title: 'Прайс-листы',
    },
    {
        key: enumTypesLinks.legalPriceList,
        icon: ShareAltOutlined,
        canDisabled: true,
        title: 'Персональные цены',
    },
    {
        key: enumTypesLinks.dictionaryList,
        icon: BookOutlined,
        canDisabled: true,
        title: 'Справочники',
    },
    {
        key: enumTypesLinks.shopModelList,
        icon: SkinOutlined,
        canDisabled: true,
        title: 'Модели',
    },
    {
        key: enumTypesLinks.shopMarketplaceSettingList,
        icon: ShopOutlined,
        canDisabled: true,
        title: 'Маркетплейс',
    },
];
