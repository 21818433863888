import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { find } from '../../../store/Crud';
import { resource } from '../../../utils/constants';
import { useAppDispatch } from '../../../store';
import { getShopId } from '../../../store/System/selectors';
import Linker from '../../../components/Linker';
import { enumTypesLinks } from '../../../components/Linker/typesLinks';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { IObject, ObjectView } from '../../../components/ObjectView';
import { getCrudItems } from '../../../store/Crud/selectors';
import { IShopNotificationConnection } from '../../../store/Crud/types/shopNotificationConnection';

interface IShopNotificationConnectionList {
    goToItem: (item: IShopNotificationConnection) => void;
}

const ShopNotificationConnectionList: FC<IShopNotificationConnectionList> = ({ goToItem }) => {
    const dispatch = useAppDispatch();
    const shopId: number = useSelector(getShopId) as any;
    const shopNotificationConnection = useSelector(getCrudItems(resource.SHOP_NOTIFICATION_CONNECTION));

    useEffect(() => {
        dispatch(find(resource.SHOP_NOTIFICATION_CONNECTION, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.setting),
            dataIndex: 'connectionSettings',
            key: 'connectionSettings',
            width: '30%',
            render: (setting: IObject) => <ObjectView object={setting} />,
        },
        {
            title: t(enumFieldName.notificationIntegrationId),
            dataIndex: 'notificationIntegrationId',
            key: 'notificationIntegrationId',
            width: '5%',
            render: (notificationIntegrationId: string) => `#${notificationIntegrationId}`,
        },
    ];

    return (
        <div className="content">
            <div style={{ margin: '16px 0' }}>
                <Linker type={enumTypesLinks.shopNotificationConnection} itemId="new">
                    Создать
                </Linker>
            </div>
            <Table
                rowKey="id"
                dataSource={shopNotificationConnection}
                columns={columns}
                onRow={(record) => ({
                    onClick: () => goToItem(record),
                })}
            />
        </div>
    );
};

export default ShopNotificationConnectionList;
