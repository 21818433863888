import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import { getShopId } from '../../../store/System/selectors';
import { getCrudItem, getCrudItems } from '../../../store/Crud/selectors';
import { formItemLayout, resource, tailFormItemLayout } from '../../../utils/constants';
import { create, find, get, update } from '../../../store/Crud';
import { createShopClaimLink } from '../../../components/Linker/PathCreators';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import Select from '../../../components/ItemEdit/components/Select';
import IntegrationFields from '../../../components/ItemEdit/components/IntegrationFields';
import ErrorMessage from '../../../components/ItemEdit/components/ErrorMessage';
import { SHOP_CLAIM_FIELDS } from '../../../store/Crud/types/notification';
import { IClaimIntegration } from '../../../store/Crud/types/integrations';

interface IShopClaimEdit {
    itemId: string;
}

const ShopClaimEdit: FC<IShopClaimEdit> = ({ itemId }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const shopId: string = useSelector(getShopId) as any;
    const claimIntegrations: IClaimIntegration[] = useSelector(getCrudItems(resource.CLAIM_INTEGRATION)) as any;
    const shopClaim = useSelector(getCrudItem(resource.SHOP_CLAIM));

    const [currentId, setCurrentId] = useState<string>();
    const [claimIntegrationId, setClaimIntegrationId] = useState<string>();
    const [error, setError] = useState<{ code: string }>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSave = async (item: any) => {
        const submitItem = { ...item, [SHOP_CLAIM_FIELDS.active]: Boolean(item?.[SHOP_CLAIM_FIELDS.active]) };
        setIsSubmitting(true);
        if (item.id) {
            const { payload } = await dispatch(update(resource.SHOP_CLAIM, item.id, submitItem)());
            if (payload?.error?.code) {
                setError(payload?.error);
            } else {
                setError(undefined);
            }
        } else {
            const { payload } = await dispatch(create(resource.SHOP_CLAIM, { ...submitItem, shopId })());
            if (payload.data) {
                history.push(createShopClaimLink(shopId, payload.data.id));
            }
            if (payload?.error?.code) {
                setError(payload?.error);
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (currentId !== 'new') {
            form.resetFields();
        }

        if (currentId !== itemId) {
            if (itemId !== 'new') {
                dispatch(get(resource.SHOP_CLAIM, itemId, { shopId })());
            }
            dispatch(find(resource.CLAIM_INTEGRATION)());
            setCurrentId(itemId);
        }
    }, [currentId, dispatch, form, itemId, shopId]);

    const currentShopClaim =
        shopClaim && String(shopClaim.id) === itemId
            ? shopClaim
            : {
                  shopId,
                  description: '',
              };

    const currentDeliveryIntegrationId = claimIntegrationId || currentShopClaim.claimIntegrationId;
    const claimIntegration = claimIntegrations.find(
        (itemClaimIntegration) => itemClaimIntegration.id === currentDeliveryIntegrationId,
    );

    if ((itemId !== 'new' && String(shopClaim?.id) === itemId) || itemId === 'new')
        return (
            <Form onFinish={onSave} form={form} initialValues={currentShopClaim}>
                <Form.Item name="id" label={t(enumFieldName.id)} {...formItemLayout}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name="title" label={t(enumFieldName.title)} rules={[{ required: true }]} {...formItemLayout}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t(enumFieldName.description)}
                    rules={[{ required: true }]}
                    {...formItemLayout}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" label={t(enumFieldName.isActive)} {...formItemLayout}>
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    rules={[{ required: true }]}
                    name="claimIntegrationId"
                    label="Тип заявки"
                >
                    <Select
                        onChange={(value: string) => {
                            setClaimIntegrationId(value);
                        }}
                        items={claimIntegrations}
                    />
                </Form.Item>
                <>
                    {claimIntegration?.settings?.type && (
                        // @ts-ignore
                        <IntegrationFields fieldName="setting" infoFields={claimIntegration.settings} />
                    )}
                </>
                <Form.Item name="shopId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Space>
                        <Button disabled={isSubmitting} loading={isSubmitting} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                        {error && <ErrorMessage error={error} />}
                    </Space>
                </Form.Item>
            </Form>
        );
    return null;
};

export default ShopClaimEdit;
