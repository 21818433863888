import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import ShopAddressEdit from './components/ShopAddressEdit';
import Loading from '../../components/Loading';
import Linker from '../../components/Linker';
import { enumTypesLinks } from '../../components/Linker/typesLinks';
import { getShopId } from '../../store/System/selectors';
import { getCrudItem } from '../../store/Crud/selectors';
import { enumResources } from '../../utils/constants';
import { useAppDispatch } from '../../store';
import { get } from '../../store/Crud';

const ShopAddressEditPage = () => {
    const { id: shopAddressId } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();

    const shopId: number = useSelector(getShopId) as any;
    const shopAddress = useSelector(getCrudItem(enumResources.SHOP_ADDRESS));

    useEffect(() => {
        if (shopAddressId !== 'new' ? String(shopAddress?.id) !== shopAddressId : false) {
            dispatch(get(enumResources.SHOP_ADDRESS, shopAddressId)());
        }
    }, [dispatch, shopAddress, shopAddressId, shopId]);

    const getTitle = () => {
        if (shopAddressId === 'new') {
            return 'Новый';
        }
        return shopAddress ? shopAddress.title : '';
    };

    if (shopId === 0 || (shopAddressId === 'new' ? false : String(shopAddress?.id) !== shopAddressId)) {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Linker type={enumTypesLinks.shopAddressList}>Торговые точки</Linker>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{getTitle()}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="content">
                <ShopAddressEdit shopId={shopId} itemId={shopAddressId} />
            </div>
        </div>
    );
};

export default ShopAddressEditPage;
