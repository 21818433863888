import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import { API_USER_FIELDS, IApiUser } from '../../../store/Crud/types/apiUser';
import { getCrudItem } from '../../../store/Crud/selectors';
import { create, get, remove, update } from '../../../store/Crud';
import { createApiUserLink } from '../../../components/Linker/PathCreators';
import { useAppDispatch } from '../../../store';
import Loading from '../../../components/Loading';

interface IApiUserEdit {
    shopId: number;
    itemId: string;
}

const ApiUserEdit: FC<IApiUserEdit> = ({ shopId, itemId }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const apiUser = useSelector(getCrudItem(resource.API_USER));

    const fields = {
        title: '',
    };

    const onSave = async (item: IApiUser) => {
        if (item.id) {
            await dispatch(update(resource.API_USER, String(item.id), item)());
        } else {
            const { payload } = await dispatch(create(resource.API_USER, item)());
            if (payload?.data?.id) {
                history.push(createApiUserLink(String(shopId), String(payload?.data?.id)));
            }
        }
    };

    useEffect(() => {
        if (itemId !== 'new' ? itemId !== String(apiUser?.id) : false) {
            dispatch(get(resource.API_USER, itemId, { shopId })());
        }
    }, [apiUser?.id, dispatch, itemId, shopId]);

    const onRemove = (item: IApiUser) => {
        if (item.id) {
            dispatch(remove(resource.API_USER, String(item.id))());
        }
    };

    const item = String(apiUser?.id) === itemId ? apiUser : {};

    if (shopId === 0 || (itemId !== 'new' ? String(apiUser?.id) !== itemId : false)) {
        return <Loading />;
    }

    return (
        <ItemEdit
            item={item}
            fields={fields}
            onSave={onSave}
            onRemove={onRemove}
            deletable
            additionalFields={{ shopId }}
            requiredFields={[API_USER_FIELDS.title]}
        />
    );
};

export default ApiUserEdit;
