import React, { useState } from 'react';
import { Checkbox, Select } from 'antd';
import { connect } from 'react-redux';
import { goodPropertyType } from '../../../utils/constants';
import TextArea from "antd/lib/input/TextArea";

const GoodPropertyValue = ({ onChange, value, type, fieldName, integrationValue, manualValue, ...props }) => {
    const [enabled, setDisabled] = useState(!!integrationValue && !manualValue);

    const resetFieldValue = () => {
        setDisabled(!enabled);
        if (!enabled) {
            onChange('');
        }
    };

    const goodProperty = props.goodProperties.find((elem) => elem.externalId === fieldName);
    if (!goodProperty) {
        return null;
    }

    const { multiple } = goodProperty;
    const getCurrentValue = () => {
        if (enabled) {
            return wrapValue(integrationValue);
        }

        if (value) {
            return wrapValue(value);
        }

        if (manualValue) {
            return wrapValue(manualValue);
        }

        return wrapValue(integrationValue);
    };

    const wrapValue = (value) => {
        if (value) {
            return value;
        }

        if (!value && multiple) {
            return [];
        }

        return '';
    };

    const renderCheckbox = () => {
        if (!integrationValue) {
            return null;
        }
        return (
            <>
                <Checkbox checked={enabled} onChange={resetFieldValue} />
                <span> из выгрузки</span>
            </>
        );
    };

    const sortFunc = (a, b) => {
        if (a.value > b.value) {
            return 1;
        }
        if (a.value < b.value) {
            return -1;
        }

        return 0;
    };

    const goodPropertyValuesSortArray = [...goodProperty?.values]?.sort(sortFunc);

    switch (type) {
        case goodPropertyType.NUMBER:
        case goodPropertyType.STRING:
            if (multiple) {
                return (
                    <>
                        <Select
                            disabled={enabled}
                            defaultValue={getCurrentValue()}
                            allowClear
                            mode="tags"
                            onChange={onChange}
                            value={getCurrentValue()}
                            style={{ width: '75%', margin: '0 8px' }}
                        >
                            {goodPropertyValuesSortArray?.map((elem) => (
                                <Select.Option key={elem.value} value={elem.value}>
                                    {elem.value}
                                </Select.Option>
                            ))}
                        </Select>
                        {renderCheckbox()}
                    </>
                );
            } else {
                return (
                    <>
                        <TextArea
                            disabled={enabled}
                            value={getCurrentValue()}
                            onChange={onChange}
                            style={{ width: '75%', margin: '0 8px' }}
                        />
                        {renderCheckbox()}
                    </>
                );
            }
        case goodPropertyType.DICTIONARY:
            const onMultipleChange = (values) => {
                if (values.includes('all')) {
                    onChange(goodProperty.values.map((e) => e.id));
                } else {
                    onChange(values);
                }
            };
            if (multiple) {
                return (
                    <>
                        <Select
                            disabled={enabled}
                            value={getCurrentValue()}
                            allowClear
                            mode="multiple"
                            onChange={onMultipleChange}
                            style={{ width: '75%', margin: '0 8px' }}
                        >
                            <Select.Option key="all" value="all">
                                <i>все значения</i>
                            </Select.Option>
                            {goodPropertyValuesSortArray?.map((elem) => (
                                <Select.Option key={elem.id} value={elem.id}>
                                    {elem.value}
                                </Select.Option>
                            ))}
                        </Select>
                        {renderCheckbox()}
                    </>
                );
            }

            return (
                <>
                    <Select
                        disabled={enabled}
                        value={getCurrentValue()}
                        allowClear
                        onChange={onChange}
                        style={{ width: '75%', margin: '0 8px' }}
                    >
                        {goodPropertyValuesSortArray?.map((elem) => (
                            <Select.Option key={elem.id} value={elem.id}>
                                {elem.value}
                            </Select.Option>
                        ))}
                    </Select>
                    {renderCheckbox()}
                </>
            );
    }

    return <div>{JSON.stringify(value)}</div>;
};

export default connect(
    (state) => ({
        goodProperties: state['model-property'].items,
    }),
    {},
)(GoodPropertyValue);
