import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { getCrudItems } from '../../../store/Crud/selectors';
import { resource } from '../../../utils/constants';
import { enumFieldName, t } from '../../../utils/helpers/formatFieldsLabels';
import { useAppDispatch } from '../../../store';
import { find } from '../../../store/Crud';
import { IObject, ObjectView } from '../../../components/ObjectView';

interface IShopNotificationList {
    onClick: (item: any) => void;
    shopId: string;
}

const ShopNotificationList: FC<IShopNotificationList> = ({ onClick, shopId }) => {
    const dispatch = useAppDispatch();

    const shopNotifications = useSelector(getCrudItems(resource.SHOP_NOTIFICATION));

    useEffect(() => {
        dispatch(find(resource.SHOP_NOTIFICATION, { shopId })());
    }, [dispatch, shopId]);

    const columns = [
        {
            title: t(enumFieldName.id),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
        },
        {
            title: t(enumFieldName.title),
            dataIndex: 'title',
            key: 'title1',
            width: '25%',
        },
        {
            title: t(enumFieldName.setting),
            dataIndex: 'notificationSettings',
            key: 'notificationSettings',
            width: '30%',
            render: (setting: IObject) => <ObjectView object={setting} />,
        },
        {
            title: t(enumFieldName.notificationIntegrationId),
            dataIndex: 'notificationIntegrationId',
            key: 'notificationIntegrationId',
            width: '5%',
            render: (notificationIntegrationId: string) => `#${notificationIntegrationId}`,
        },
    ];

    return (
        <Table
            rowKey="id"
            dataSource={shopNotifications}
            columns={columns}
            onRow={(record) => ({
                onClick: () => onClick(record),
            })}
        />
    );
};

export default ShopNotificationList;
