export enum enumTypeImage {
    IMAGE = 'image',
    OG_IMAGE = 'og-image',
}

export interface IRequestUploadImageShop {
    id: number;
    path: string;
    type: enumTypeImage;
    shopId: string;
}
