import withQuery from 'with-query';
import http from '../../utils/http';

export const findCrudItemsRequest = async (resourceName: string, query: any) =>
    http.get(withQuery(resourceName, query));

export const createCrudItemRequest = async (resourceName: string, query: any) => http.post(resourceName, query);

export const updateCrudItemRequest = async (resourceName: string, id: string, query: any) =>
    http.put(`${resourceName}/${id}`, query);

export const updateCrudStatusItemRequest = async (resourceName: string, id: string, query: any) =>
    http.put(`${resourceName}/${id}/status`, query);

export const removeCrudItemRequest = async (resourceName: string, id: string) => http.delete(`${resourceName}/${id}`);
