import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { Button, Input, InputRef, Tag } from 'antd';

type TProps = {
    onChange?: (value: Array<string>) => void;
    value?: Array<string>;
    disabled?: boolean;
};

const TagsFormInput: FC<TProps> = ({ onChange, value, disabled }) => {
    const inputRef = useRef<InputRef>(null);

    const [tagInputValue, setTagInputValue] = useState<string>('');

    const handleTagRemove = useCallback(
        (tag: string) => {
            if (!onChange || !value) return;

            onChange(value?.filter((tagItem) => tagItem !== tag));
        },
        [onChange, value],
    );

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagInputValue(event.target.value);
    };

    const handleTagAdd = useCallback(() => {
        if (!onChange || !tagInputValue) return;

        onChange(value ? [...value, tagInputValue] : [tagInputValue]);
        setTagInputValue('');
    }, [onChange, tagInputValue, value]);

    const handleInputWrapperFocus = () => {
        inputRef.current?.focus();
    };

    return (
        <div
            className="ant-input"
            style={{ display: 'flex', flexWrap: 'wrap' }}
            onClick={handleInputWrapperFocus}
            role="presentation"
        >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {value?.map((tag) => (
                    <Tag
                        style={{ pointerEvents: 'auto' }}
                        key={tag}
                        closable={!disabled}
                        onClose={() => handleTagRemove(tag)}
                    >
                        {tag}
                    </Tag>
                ))}
            </div>
            <Input
                disabled={disabled}
                bordered={false}
                style={{ padding: 0 }}
                value={tagInputValue}
                onChange={handleInputChange}
                ref={inputRef}
            />
            {tagInputValue && (
                <Button size="small" onClick={handleTagAdd}>
                    Добавить тег
                </Button>
            )}
        </div>
    );
};

export default TagsFormInput;
