import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import ItemEdit from '../../../components/ItemEdit';
import { resource } from '../../../utils/constants';
import Loading from '../../../components/Loading';
import { create, get, update } from '../../../store/Crud';
import { useAppDispatch } from '../../../store';
import { ICategory } from '../../../store/Crud/types';
import { getCrudItem } from '../../../store/Crud/selectors';
import { createCategoryLink } from '../../../components/Linker/PathCreators';
import { CATEGORY_FIELDS } from '../../../store/Crud/types/category';
import CategoryImg from './CategoryImg';

interface ICategoryEdit {
    shopId: string;
    itemId: string;
}

const CategoryEdit: FC<ICategoryEdit> = ({ shopId, itemId }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { id: categoryId } = useParams<{ id: string }>();

    const categoryItem = useSelector(getCrudItem(resource.CATEGORY));

    const fields = {
        title: '',
        description: '',
        parentId: '',
        externalId: '',
        modelId: '',
        position: '',
        public: true,
        aliasIds: [],
    };

    const getAliasIdsForSubmit = (aliasItem: string | string[]) => {
        if (_.isArray(aliasItem)) {
            return aliasItem;
        }
        if (aliasItem.length > 0) {
            return aliasItem.split(',');
        }
        return [];
    };

    const onSubmit = async (item: ICategory) => {
        const validCategory = {
            ...item,
            [CATEGORY_FIELDS.parentId]: item?.[CATEGORY_FIELDS.parentId] || null,
            [CATEGORY_FIELDS.aliasIds]: getAliasIdsForSubmit(item?.[CATEGORY_FIELDS.aliasIds]),
            [CATEGORY_FIELDS.position]: Number(item?.[CATEGORY_FIELDS.position]),
            [CATEGORY_FIELDS.modelId]: item?.[CATEGORY_FIELDS.modelId] || null,
        };

        if (validCategory?.id) {
            await dispatch(update(resource.CATEGORY, String(validCategory.id), validCategory)());
        } else {
            const { payload } = await dispatch(create(resource.CATEGORY, validCategory)());
            if (payload?.data && payload?.data?.id) {
                history.push(createCategoryLink(shopId, String(payload?.data?.id)));
            }
        }
    };

    const getCurrentCategory = useCallback(() => {
        dispatch(get(resource.CATEGORY, itemId, { shopId, _extend: resource.IMAGE })());
    }, [dispatch, itemId, shopId]);

    useEffect(() => {
        if (itemId !== 'new') {
            getCurrentCategory();
        }
    }, [getCurrentCategory, itemId]);

    if (Number(shopId) === 0 || (categoryId === 'new' ? false : String(categoryItem?.id) !== categoryId)) {
        return <Loading />;
    }
    const item = categoryItem && categoryItem.id === Number(itemId) ? categoryItem : {};

    return (
        <div>
            {categoryItem?.id && categoryId !== 'new' && (
                <CategoryImg getCurrentCategory={getCurrentCategory} item={item} />
            )}
            <ItemEdit item={item} fields={fields} onSave={onSubmit} additionalFields={{ shopId }} />
        </div>
    );
};

export default CategoryEdit;
